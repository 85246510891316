import React, { useEffect, useState } from 'react';
import { Stage, Layer, Transformer } from 'react-konva';
import { useSelector, useDispatch } from 'react-redux';
import { addLayer, addElement, updateElement, removeElement, clearLayers } from '../../store/konvaSlice';
import { useKonvaContext } from '../../context/useKonvaContext';
import DraggableText from './DraggableText';
import DraggableImage from './DraggableImage';
import DraggableRect from './DraggableRect';
import DraggableGroup from './DraggableGroup';
import { newGet, newPut } from '../../API/axios';
import lockOpen from "../../assets/newicons/lock_open.svg";
import lockClose from "../../assets/newicons/lock_close.svg";
import threeDots from "../../assets/newicons/three-dots.svg";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { Menu, MenuItem, styled } from '@mui/material';
import { alpha } from '@mui/material/styles';
import replace from "../../assets/newicons/replace.svg"
import download from "../../assets/newicons/download1.svg";
import remove from "../../assets/newicons/delete1.svg"
import verified from "../../assets/newicons/verified.svg";
import Draggable from 'react-draggable';
import UploadDesignFile from '../../views/Manager/component/UploadDesignFile';
import Close from '../../assets/newicons/close.svg';
import { Upload } from '@mui/icons-material';

const KonvaCanvas = ({ selectedImages, setSelectedImages, scale, templateData, setScale, canvasData, sheetId }) => {
    //    console.log("templateData", templateData)
    console.log("canvasData", canvasData)
    const layers = useSelector(state => state.konva.layers);
    // const layers = [];
    console.log("layers", layers)
    const dispatch = useDispatch();
    const [selectedElementId, setSelectedElementId] = useState(null);
    const [dimensions, setDimensions] = useState({ width: 300, height: 0, x: 50, y: 50 });
    const {
        setShowSideModal,
        setFontSize,
        setFontFamily,
        setColor,
        setMenuPosition,
        setSelectedTextElement,
        setContextMenuVisible,
        setEditingTextId,
        setNewTextValue,
        setInputPosition,
        setShowTextOptionsModal,
        selectedId,
        setSelectedId,
        fonts,
        setSelectedLayerId,
        setTextStyle,
        stageRef, transformerRef, containerRef,
        selectedSheetId, setSelectedSheetId,
        selectedSheetLayerId, setSelectedSheetLayerId,
    } = useKonvaContext();

    // console.log("canvasData", canvasData)
    const { sheet_name, sheet_id, sheet_is_locked, sheet_status_code, sheet_status_id } = canvasData


    const [isLoading, setIsLoading] = useState(false);
    const [template, setTemplate] = useState(null);
    const [isLock, setisLock] = useState(false);
    const [sheetName, setsheetName] = useState(sheet_name); // Initial text
    const [isEditing, setIsEditing] = useState(false);
    const [layerId, setLayerId] = useState(null);


    useEffect(() => {
        if (layers.length === 0) {
            dispatch(addLayer({ id: 'layer1' }));
        }
    }, [dispatch, layers.length]);

    useEffect(() => {
        if (templateData === null) {
            dispatch(clearLayers([]));
        }
    }, [templateData]);

    useEffect(() => {
        const getDimensions = () => {
            if (containerRef.current) {
                const { offsetWidth, offsetHeight, offsetLeft, offsetTop } = containerRef.current;
                console.log("containerRef", offsetWidth, offsetHeight, offsetLeft, offsetTop)
                setDimensions({
                    width: offsetWidth,
                    height: offsetHeight,
                    x: offsetLeft,
                    y: offsetTop
                });
            }
        };


        getDimensions();
        window.addEventListener('resize', getDimensions);
        return () => window.removeEventListener('resize', getDimensions);
    }, []);

    useEffect(() => {
        const stage = stageRef.current;
        const transformer = transformerRef.current;

        if (!transformer) {
            console.error('Transformer is not initialized');
            return;
        }

        if (selectedElementId) {
            const selectedNode = stage.findOne(`#${selectedElementId}`);
            if (selectedNode) {
                transformer.nodes([selectedNode]);
                transformer.getLayer().batchDraw();
            }
        } else {
            transformer.nodes([]);
            stage.draw();
        }
    }, [selectedElementId]);

    useEffect(() => {
        // if (selectedImages.length > 0) {
        //     const newElement = {
        //         id: `image-${Date.now()}`,
        //         type: 'Image',
        //         props: {
        //             id: `image-${Date.now()}`,
        //             x: 200,
        //             y: 200,
        //             src: selectedImages[0].url,
        //             width: 200,
        //             height: 200,
        //         },
        //     };
        //     dispatch(addElement({ layerId: 'layer1', element: newElement }));
        //     setSelectedElementId(newElement.id);
        //     setSelectedLayerId('layer1');
        //     setSelectedId(newElement.id);
        //     setShowSideModal(true);
        //     setSelectedImages([])
        // }
    }, [selectedImages]);

    useEffect(() => {
        // Load the template JSON
        try {
            if (templateData) {
                setTemplate(templateData);
                setTimeout(() => {
                    initializeTemplate(templateData);
                }, 500);
            }
            // if (canvasData) {
            //     setTemplate(canvasData);
            //     setTimeout(() => {
            //         initializeTemplate(canvasData);
            //     }, 500);
            // }
        } catch (error) {
            console.error('Error loading template:', error);
        }
    }, [stageRef]);

    useEffect(() => {
        // getDesignDetails();
        // getSheetDetails()
    }, [])

    const getSheetDetails = async () => {
        try {
            const sheetData = await newGet(`sheet/getSheetDetail?sheet_id=${sheet_id}`);
            console.log("sheetData", sheetData);
            setLayerId(sheetData?.result?.layers[0].id)

        }
        catch (error) {
            console.log(error)
        }
    }



    const initializeTemplate = (templateData) => {
        console.log("templateData", templateData)
        if (templateData) {
            setIsLoading(true)
            const stage = stageRef.current;
            const stageWidth = stage.width();
            const stageHeight = stage.height();

            const maxWidth = stageWidth;
            const maxHeight = stageHeight;
            let width = templateData?.size[0] || 0;
            let height = templateData?.size[1] || 0;
            let minRatio = 0;
            if (width > maxWidth || height > maxHeight) {
                const widthRatio = maxWidth / width;
                const heightRatio = maxHeight / height;
                minRatio = Math.min(widthRatio, heightRatio);
                width *= minRatio;
                height *= minRatio;
            }

            dispatch(addLayer({ id: 'layer1', size: [templateData?.size[0], templateData?.size[1]] }));

            const getDimensions = () => {
                setDimensions({
                    width: width,
                    height: height,
                    x: 0, y: 0
                });

                // if (containerRef.current) {
                //     containerRef.current.style.width = `${width}px`;
                //     containerRef.current.style.height = `${height}px`;
                // }
            }
            // getDimensions();
            // window.addEventListener('resize', getDimensions);
            // window.removeEventListener('resize', getDimensions);

            // Replace existing layers with new template layers
            templateData.layers.forEach((layer, index) => {
                let element;
                // eslint-disable-next-line default-case
                switch (layer.kind) {
                    // case 'artboard':
                    //     element = createArtboardElement(layer, templateData?.size[0], templateData?.size[1], width, height);
                    //     break;
                    // case 'group':
                    //     element = createGroupElement(layer, templateData?.size[0], templateData?.size[1], width, height);
                    //     break;
                    // case 'type':
                    //     if (layer?.image_data) {
                    //         const image = createImageElement(layer, templateData?.size[0], templateData?.size[1], width, height);
                    //         if (image) {
                    //             dispatch(addElement({ layerId: 'layer1', element: image }));
                    //         }
                    //     }
                    //     if (layer?.text) {
                    //         element = createTextElement(layer, templateData?.size[0], templateData?.size[1], width, height, minRatio);
                    //     }

                    //     if (!(layer?.image_data && layer?.text)) {
                    //         element = createGroupElement(layer, templateData?.size[0], templateData?.size[1], width, height);
                    //     }
                    //     break;
                    // case 'shape':
                    case 'shape':
                        element = createImageElement(layer, templateData?.size[0], templateData?.size[1], width, height);
                        break;
                }
                if (element) {
                    dispatch(addElement({ layerId: 'layer1', element }));
                    // dispatch(addElement({ layerId: layer?.id, element }));
                }
            });

            setIsLoading(false)
        }
    };

    const createArtboardElement = (layer, stageWidth, stageHeight, width, height) => {
        return {
            id: `artboard-${layer['z-index']}-${Date.now()}`,
            type: 'Rect',
            "z-index": layer['z-index'],
            "blend_mode": layer?.blend_mode,
            "kind": layer?.kind,
            props: {
                x: (layer.bbox[0] / stageWidth) * width,
                y: (layer.bbox[1] / stageHeight) * height,
                width: ((layer.bbox[2] - layer.bbox[0]) / stageWidth) * width,
                height: ((layer.bbox[3] - layer.bbox[1]) / stageHeight) * height,
                fill: 'white',
                name: layer.name,
                visible: layer.visible,
                opacity: layer.opacity,
            }
        };
    };

    const createGroupElement = (layer, stageWidth, stageHeight, width, height) => {
        return {
            id: `group-${layer['z-index']}-${Date.now()}`,
            type: 'Group',
            "z-index": layer['z-index'],
            "blend_mode": layer?.blend_mode,
            "kind": layer?.kind,
            props: {
                x: (layer.bbox[0] / stageWidth) * width,
                y: (layer.bbox[1] / stageHeight) * height,
                width: ((layer.bbox[2] - layer.bbox[0]) / stageWidth) * width,
                height: ((layer.bbox[3] - layer.bbox[1]) / stageHeight) * height,
                name: layer.name,
                visible: layer.visible,
                opacity: layer.opacity,
            }
        };
    };

    const rgbaToHex = (rgbaString) => {
        const rgba = JSON.parse(rgbaString);
        const [r, g, b] = rgba.slice(0, 3).map((val) => {
            const hex = Math.round(val * 255).toString(16);
            return hex.padStart(2, '0');
        });

        return `#${r}${g}${b}`;
    };

    const createTextElement = (layer, stageWidth, stageHeight, width, height, minRatio) => {
        console.log('layer?.text?.content: ', layer?.text?.content)
        return {
            id: `text-${layer['z-index']}-${Date.now()}`,
            type: 'Text',
            "z-index": layer['z-index'],
            "blend_mode": layer?.blend_mode,
            "kind": layer?.kind,
            props: {
                x: (layer?.bbox[0] / stageWidth) * width,
                y: (layer?.bbox[1] / stageHeight) * height,
                width: ((layer.bbox[2] - layer.bbox[0]) / stageWidth) * width,
                height: ((layer.bbox[3] - layer.bbox[1]) / stageHeight) * height,
                text: layer?.text?.content || 'Sample Text',
                fontSize: layer?.text?.size ? parseFloat(layer?.text?.size).toFixed(2) : 14,
                fontFamily: layer?.text?.font || 'Arial',
                fill: layer?.text?.color ? rgbaToHex(layer?.text?.color) : '#000000',
                name: layer?.name,
                visible: layer?.visible,
                opacity: layer?.opacity,
            }
        };
    };

    const createImageElement = (layer, stageWidth, stageHeight, width, height) => {
        let xl = {
            x: (layer.bbox[0] / stageWidth) * width,
            y: (layer.bbox[1] / stageHeight) * height,
            width: (((Math.abs(layer.bbox[2]) - 1) - layer.bbox[0]) / stageWidth) * width,
            height: (((Math.abs(layer.bbox[3]) - 1) - layer.bbox[1]) / stageHeight) * height,
        }
        console.log("layer Dimension", xl)
        const { offsetWidth, offsetHeight, offsetLeft, offsetTop } = containerRef.current;
        return {
            // id: `image-${layer['z-index']}-${Date.now()}`,
            id: layer?.id,
            type: 'Image',
            "z-index": layer['z-index'],
            "blend_mode": layer?.blend_mode,
            "kind": layer?.kind,
            props: {
                // width: offsetWidth,
                // height: offsetHeight,
                // x: (layer.bbox[0] / stageWidth) * width,
                // y: (layer.bbox[1] / stageHeight) * height,
                x: (layer.bbox[0] / stageWidth) * width,
                y: (layer.bbox[1] / stageHeight) * height,
                // // width: ((485 - layer.bbox[0]) / stageWidth) * width,
                // // height: ((547 - layer.bbox[1]) / stageHeight) * height,
                width: (((Math.abs(layer.bbox[2]) - 1) - layer.bbox[0])),
                height: (((Math.abs(layer.bbox[3]) - 1) - layer.bbox[1])),
                src: layer?.image_data ? `data:${layer.image_data.format.toLowerCase()};base64,${layer.image_data.data}` : null,
                name: layer?.name,
                format: layer?.image_data?.format,
                visible: layer.visible,
                opacity: layer.opacity,
            }
        };
    };

    const showContextMenu = (x, y, textElement) => {
        setMenuPosition({ x, y });
        setSelectedTextElement(textElement);
        setContextMenuVisible(true);
    };

    const handleDragEnd = (e, layerId, elementId) => {
        // const newProps = { x: e.target.x(), y: e.target.y() };
        // dispatch(updateElement({ layerId, elementId, newProps }));
    };

    const getLayerData = (layerId, elementId) => {
        const layer = layers.find(layer => layer.id === layerId);
        if (layer) {
            const element = layer.elements.find(el => el.id === elementId);
            if (element) {
                return { ...element.props };
            }
        }
    }

    const handleTransformEnd = (e, layerId, elementId) => {
        if (selectedId == elementId) {
            const node = e.target;
            const newProps = {
                x: node.x(),
                y: node.y(),
                rotation: node.rotation(),
                width: node.width(),
                height: node.height(),
            };
            dispatch(updateElement({ layerId, elementId, newProps }));
        }
    };

    const handleGroupClick = (layerId, elementId) => {
        const element = layers.find(layer => layer.id === layerId).elements.find(el => el.id === elementId);
        if (element && element.id.includes('text')) {
            setShowSideModal(true);
            const layer = getLayerData(layerId, elementId)
            setNewTextValue(layer?.text)
            setFontSize(layer?.fontSize || 14);
            setFontFamily(layer?.fontFamily);
            setColor(layer?.fill || '000000');
            setTextStyle({
                bold: layer?.fontWeight || 'normal',
                italic: layer?.fontStyle || 'normal',
                underline: layer?.textDecoration || 'none',
            });
        } else if (element && element.id.includes('image')) {
            setShowSideModal(true);
            setShowTextOptionsModal(false);
            const layer = getLayerData(layerId, elementId)
            // setCornerRadiusValue(layer.cornerRadius.find((val) => val > 0) || 0)
        } else if (element && element.id.includes('draw')) {
            setShowSideModal(true);
            setShowTextOptionsModal(false);
        } else {
            setSelectedElementId(null);
            setSelectedLayerId(null);
            setSelectedId(null);
            setContextMenuVisible(false);
            setShowTextOptionsModal(false);
            return setShowSideModal(false);
        }
        setSelectedElementId(elementId);
        setSelectedLayerId('layer1');
        setSelectedId(elementId);
    };

    const handleRightClick = (e, element) => {
        e.evt.preventDefault();
        showContextMenu(e.evt.clientX, e.evt.clientY, element);
        // dispatch(removeElement({ layerId: 'layer1', elementId: element.id }));
    };

    const handleDoubleClick = (textElement, textNode) => {
        setEditingTextId(textElement.id);
        setSelectedId(textElement?.id);
        const textRect = textNode.getClientRect();
        const stageRect = stageRef.current.container().getBoundingClientRect();
        setInputPosition({
            x: stageRect.x + textRect.x,
            y: stageRect.y + textRect.y + textRect.height,
        });
        if (selectedId && selectedId.includes('text')) {
            setShowTextOptionsModal(true);
            setNewTextValue(textElement?.props?.text);
        }
    };

    const handleStageClick = (e) => {
        if (e.target === e.target.getStage()) {
            setSelectedElementId(null);
            if (selectedId && selectedId.includes('text')) {
                setShowTextOptionsModal(false);
                setShowSideModal(false);
                setContextMenuVisible(false);
                setFontSize(14);
                setNewTextValue('')
                setFontFamily(fonts[0].family);
            } else if (selectedId && selectedId.includes('image')) {
                setShowSideModal(false);
                setShowTextOptionsModal(false);
            } else if (selectedId && selectedId.includes('draw')) {
                setShowSideModal(false);
                setShowTextOptionsModal(false);
            }
            setSelectedId(null);
            setSelectedLayerId(null);
            setEditingTextId(null);
            setShowTextOptionsModal(false);
            setContextMenuVisible(false);
            setShowSideModal(false);
        }
    };

    const handleDoubleClickonFileName = () => {
        setIsEditing(true); // Enable editing mode
    };

    const hideContextMenu = () => {
        setContextMenuVisible(false);
    };

    const handleDrop = async (e) => {
        e.preventDefault();
        const fontFamily = e.dataTransfer.getData('text/plain');
        const imageSrc = e.dataTransfer.getData('image');

        const stage = stageRef.current;
        const pointerPosition = stage.getPointerPosition();

        if (imageSrc) {
            const url = `${window.location.origin}${imageSrc}`;

            const img = new Image();
            img.src = url;
            img.onload = () => {
                const maxWidth = stage.width() * 0.5;
                const maxHeight = stage.height() * 0.5;
                let width = img.width;
                let height = img.height;

                if (width > maxWidth || height > maxHeight) {
                    const widthRatio = maxWidth / width;
                    const heightRatio = maxHeight / height;
                    const minRatio = Math.min(widthRatio, heightRatio);
                    width *= minRatio;
                    height *= minRatio;
                }

                const newElement = {
                    id: `image-${Date.now()}`,
                    type: 'Image',
                    "z-index": 1,
                    "blend_mode": '',
                    "kind": 'smartobject',
                    props: {
                        id: `image-${Date.now()}`,
                        x: pointerPosition?.x || 200,
                        y: pointerPosition?.y || 200,
                        src: url,
                        width: width,
                        height: height,
                        cornerRadius: [0, 0, 0, 0]
                    },
                };
                dispatch(addElement({ layerId: 'layer1', element: newElement }));
                setSelectedElementId(newElement.id);
                setSelectedLayerId('layer1');
                setSelectedId(newElement.id);
                setShowSideModal(true);
            };
        } else if (fontFamily) {
            const newElement = {
                id: `text-${Date.now()}`,
                type: 'Text',
                "z-index": 1,
                "blend_mode": '',
                "kind": 'type',
                props: {
                    id: `text-${Date.now()}`,
                    x: pointerPosition?.x || 50,
                    y: pointerPosition?.y || 50,
                    rotation: 0,
                    fontWeight: 'normal',
                    fontSize: 14,
                    fill: '#000000',
                    fontStyle: 'normal',
                    textDecoration: 'none',
                    padding: 10,
                    width: 100,
                    height: 50,
                    text: 'Double-click to edit',
                    fontFamily,
                },
            };
            dispatch(addElement({ layerId: 'layer1', element: newElement }));
            setSelectedElementId(newElement.id);
            setSelectedLayerId('layer1');
            setSelectedId(newElement.id);
            setShowSideModal(true);
        }
    };

    const dragBoundFunc = (pos) => {
        const stage = stageRef.current;
        const box = stage.container().getBoundingClientRect();

        const elementWidth = box.width;
        const elementHeight = box.height;

        // Calculate the maximum x and y positions
        const maxX = dimensions.width - elementWidth;
        const maxY = dimensions.height - elementHeight;

        // Constrain x and y
        let x = pos.x;
        let y = pos.y;

        if (x < 0) x = 0;
        if (x > dimensions.width - elementWidth) x = dimensions.width - elementWidth;
        if (y < 0) y = 0;
        if (y > dimensions.height - elementHeight) y = dimensions.height - elementHeight;

        return { x, y };
    };

    document.addEventListener("keydown", (e) => {
        if (e.key === "Delete") {
            if (selectedId) {
                const selectedNode = stageRef.current.findOne(`#${selectedId}`);
                if (selectedNode?.attrs?.id === selectedId) {
                    selectedNode.destroy();
                    dispatch(removeElement({ layerId: 'layer1', elementId: selectedNode?.attrs?.id }));
                }
                setShowSideModal(false);
                setShowTextOptionsModal(false);
                setContextMenuVisible(false);
                setSelectedId(null)
                setSelectedElementId(null)
                setSelectedLayerId(null)
                return
            }
        }
    });

    const handleDragStart = (e, layerId, elementId) => {
        setSelectedId(elementId)
        setSelectedElementId(elementId)
        setSelectedLayerId(layerId)
    };

    useEffect(() => {
        // const handleWheel = (e) => {
        //     if (e.ctrlKey) {
        //         e.preventDefault();
        //         if (e.deltaY < 0) {
        //             setScale((prevScale) => prevScale + 0.1);
        //         } else {
        //             setScale((prevScale) => Math.max(prevScale - 0.1, 0.1));
        //         }
        //     }
        // };

        // const container = containerRef.current;
        // if (container) {
        //     container.addEventListener('wheel', handleWheel);
        // }
        // return () => {
        //     if (container) {
        //         container.removeEventListener('wheel', handleWheel);
        //     }
        // };
    }, []);


    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const replaceSheet = () => {
        console.log("replaceSheet")
    }

    // const getDesignDetails = async() => {
    //     try{
    //       const designData = await newGet('design/getDesignDetail?design_id=75');
    //       console.log("designData", designData);
    //     //   if(designData?.status_code ==200){
    //     //    setCanvases(designData?.result?.sheets);
    //     //   }
    //     }
    //     catch(error){
    //            console.log(error)
    //     }
    // }

    const removeSheet = async () => {
        try {
            const response = await newPut('sheet/delete', {
                sheet_id: sheet_id,
            });
            console.log("Sheet Deleted", response);

        }
        catch (error) {
            console.log("error", error)
        }
        console.log("removeSheet")
    }

    const downloadSheet = () => {
        console.log("downloadSheet")
    }

    const lockNRequestApproval = () => {
        try {
            const response = newPut(`approval/sentForApproval`, {
                "sheet_ids": [selectedSheetId]
            })

            console.log("response", response)
        }
        catch (error) {

        }
        // setisLock(true)
    }

    const cancelRequestApproval = () => {
        try {
            const response = newPut(`approval/revoke`, {
                "sheet_ids": [selectedSheetId]
            })
            console.log("response", response)
        }
        catch (error) {

        }
    }

    const approveSheet = () => {
        try {
            const response = newPut(`approval/approve`, {
                "sheet_ids": [selectedSheetId]
            })
            console.log("response", response)
        }
        catch (error) {

        }
    }

    const returnSheet = () => {
        try {
            const response = newPut(`approval/return`, {
                "sheet_ids": [selectedSheetId]
            })
            console.log("response", response)
        }
        catch (error) {
        }
    }



    const handleChange = (event) => {
        setsheetName(event.target.value); // Update text as user types
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            renameSheet(); // Save and exit editing mode on Enter
        }
    };

    const handleBlur = () => {
        renameSheet(); // Save and exit editing mode on blur
        // setIsEditing(false); // Save and exit editing mode on blur
    };

    const renameSheet = async () => {
        setIsEditing(false);
        try {
            const response = await newPut('sheet/rename', {
                sheet_id: sheet_id,
                new_sheet_name: sheetName,
            });
            console.log("Sheet Name Saved", response);
        }
        catch (error) {
            console.log("error", error)
        }
    }

    const StyledMenu = styled((props) => (
        <Menu
            elevation={0}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            {...props}
        />
    ))(({ theme }) => ({
        '& .MuiPaper-root': {
            borderRadius: 6,
            marginTop: theme.spacing(1),
            minWidth: 250,
            color: 'rgb(55, 65, 81)',
            boxShadow:
                'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            '& .MuiMenu-list': {
                padding: '4px 0',
            },
            '& .MuiMenuItem-root': {
                fontWeight: 500,
                fontSize: 14,
                '& .MuiSvgIcon-root': {

                    color: theme.palette.text.secondary,
                    marginRight: theme.spacing(0.5),
                },
                '&:active': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        theme.palette.action.selectedOpacity,
                    ),
                },
            },
            ...theme.applyStyles('dark', {
                color: theme.palette.grey[300],
            }),
        },
    }));




    return (
        <>
            <Draggable>
                <div
                    ref={containerRef}
                    className="d-flex align-items-center justify-content-center editor-image position-relative "
                    // onDrop={handleDrop}
                    onDragOver={(e) => e.preventDefault()}
                    style={{
                        flexDirection: 'column',
                        transform: `scale(${scale})`,
                        transformOrigin: 'center',
                        transition: 'transform 0.2s',
                        margin: '1vh'
                    }}
                >

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '1vh', position: 'relative', top: "-16px", width: '100%', height: '100%' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {isEditing ? (
                                <input
                                    type="text"
                                    value={sheetName}
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                    onBlur={handleBlur}
                                    autoFocus
                                    style={{ width: "150px", height: "20px" }}
                                />
                            ) : (
                                <p onDoubleClick={handleDoubleClickonFileName} className='font-noto-sans fs-14 fw-400 mx-2 mb-0'>{sheetName}</p>
                            )}
                            {sheet_status_id === 1 &&
                                <p className='font-noto-sans fs-12 fw-600 px-2 mb-0' style={{ borderRadius: "13px", backgroundColor: "#E6E6E6", padding: "2px", }}>Draft</p>
                            }
                            {sheet_status_id === 2 &&
                                <p className='font-noto-sans fs-12 fw-600 px-2 mb-0' style={{ borderRadius: "13px", backgroundColor: "#FEDD9B", padding: "2px", }}>Awaiting Approval</p>
                            }
                            {sheet_status_id === 3 &&
                                <p className='font-noto-sans fs-12 fw-600 px-2 mb-0' style={{ borderRadius: "13px", backgroundColor: "#B2DEC8", padding: "2px", }}>Approved</p>
                            }
                            {sheet_status_id === 4 &&
                                <p className='font-noto-sans fs-12 fw-600 px-2 mb-0' style={{ borderRadius: "13px", backgroundColor: "#F6B8B0", padding: "2px", }}>Returned</p>
                            }
                            {sheet_status_id === 4 &&
                                <p className='font-noto-sans fs-12 fw-600 px-2 mb-0' style={{ borderRadius: "13px", backgroundColor: "#F6B8B0", padding: "2px", }}>Deleted</p>
                            }
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {sheet_is_locked ? (
                                <img src={lockClose} alt='lockclose' width={19} height={21} />
                            ) : (
                                <img src={lockOpen} alt='lockOpen' width={19} height={21} />
                            )
                            }
                            <div className="header_line mx-2"></div>
                            <IconButton
                                aria-label="more"
                                id="demo-customized-button"
                                aria-controls={open ? 'demo-customized-menu' : undefined}
                                aria-expanded={open ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={handleClick}
                                style={{ width: "19px", height: "21px" }}
                            >
                                <MoreHorizIcon />
                            </IconButton>

                            <StyledMenu
                                id="demo-customized-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'demo-customized-button',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                            >
                                {/* {templateData ? (
                                <MenuItem onClick={() => { handleClose(); replaceSheet() }} disableRipple>
                                    <img className="mx-2" src={replace} alt='lockOpen' width={19} height={21} />

                                    Replace
                                </MenuItem>
                            ) :
                                ( */}
                                <MenuItem onClick={() => { handleClose(); setSelectedSheetLayerId(canvasData?.layers[0]?.id) }} disableRipple data-bs-toggle="modal" data-bs-target="#add_design">
                                    <img className="mx-2" src={replace} alt='lockOpen' width={19} height={21} />

                                    {canvasData?.layers[0]?.image_data?.data === "" ? "Upload" : "Replace"}
                                </MenuItem>
                                {/* )
                            } */}

                                <MenuItem onClick={() => { handleClose(); removeSheet() }} disableRipple>
                                    <img className="mx-2" src={remove} alt='lockOpen' width={19} height={21} />

                                    Remove
                                </MenuItem>
                                <MenuItem onClick={() => { handleClose(); downloadSheet() }} disableRipple>
                                    {/* <MoreHorizIcon /> */}
                                    <img className="mx-2" src={download} alt='lockOpen' width={19} height={21} />
                                    Download
                                </MenuItem>
                                <Divider sx={{ my: 0.5 }} />
                                {sheet_status_id === 1 ? (
                                    <MenuItem onClick={() => { handleClose(); setSelectedSheetId(sheet_id) }} disableRipple data-bs-toggle="modal" data-bs-target="#approvalModal">
                                        {/* <MoreHorizIcon /> */}
                                        <img className="mx-2" src={verified} alt='lockOpen' width={19} height={21} />
                                        Lock and request approval
                                    </MenuItem>
                                ) : (
                                    <MenuItem onClick={() => { handleClose(); setSelectedSheetId(sheet_id) }} disableRipple data-bs-toggle="modal" data-bs-target="#cancelModal">
                                        {/* <MoreHorizIcon /> */}
                                        <img className="mx-2" src={verified} alt='lockOpen' width={19} height={21} />
                                        Cancel request approval
                                    </MenuItem>
                                )
                                }
                            </StyledMenu>

                        </div>
                    </div>


                    {/* <div style={{ display: !isLoading ? 'none' : 'block', zIndex: 9999 }} className='top-0 w-100 position-absolute'>
                    <Skeleton
                        variant="rounded"
                        animation="wave"
                        height={400}
                        // width={300}
                        className="w-100"
                    />
                    <Skeleton
                        animation="wave"
                        height={50}
                        variant="rounded"
                        className={`rounded-pill border-0  p-2 my-3 btn`}
                    />
                    <Skeleton
                        animation="wave"
                        height={50}
                        variant="rounded"
                        className={`rounded-pill border-0  p-2 my-3 btn`}
                    />
                </div> */}
                    {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', position: 'relative', width: '100%', height: '100%' }}> */}



                    <Stage
                        ref={stageRef}
                        width={dimensions.width}
                        height={dimensions.height}
                    // onClick={handleStageClick}


                    >
                        {layers.map((layer) => (
                            <Layer key={layer.id}>
                                {layer.elements.map((element) => {
                                    switch (element.type) {
                                        case 'Image':
                                            return (
                                                <DraggableImage
                                                    key={element.id}
                                                    element={element}
                                                    layerId={layer.id}
                                                    handleDragEnd={handleDragEnd}
                                                    // handleGroupClick={handleGroupClick}
                                                    handleTransformEnd={handleTransformEnd}
                                                    stageDimensions={dimensions}
                                                    handleDragStart={handleDragStart}
                                                />
                                            );
                                        case 'Text':
                                            return (
                                                <DraggableText
                                                    key={element.id}
                                                    element={element}
                                                    layerId={layer.id}
                                                    handleDragEnd={handleDragEnd}
                                                    handleGroupClick={handleGroupClick}
                                                    handleDoubleClick={handleDoubleClick}
                                                    handleRightClick={handleRightClick}
                                                    handleTransformEnd={handleTransformEnd}
                                                    stageDimensions={dimensions}
                                                    handleDragStart={handleDragStart}
                                                />
                                            );
                                        case 'Rect':
                                            return (
                                                <DraggableRect
                                                    key={element.id}
                                                    element={element}
                                                    layerId={layer.id}
                                                    handleDragEnd={handleDragEnd}
                                                    handleGroupClick={handleGroupClick}
                                                    handleTransformEnd={handleTransformEnd}
                                                    stageDimensions={dimensions}
                                                />
                                            );
                                        case 'Group':
                                            return (
                                                <DraggableGroup
                                                    key={element.id}
                                                    element={element}
                                                    layerId={layer.id}
                                                    handleDragEnd={handleDragEnd}
                                                    handleGroupClick={handleGroupClick}
                                                    handleTransformEnd={handleTransformEnd}
                                                    stageDimensions={dimensions}
                                                />
                                            );
                                        default:
                                            return null;
                                    }
                                })}
                                {(layer.id === 'layer1' && selectedId) && <Transformer ref={transformerRef} />}
                            </Layer>
                        ))}
                    </Stage>


                </div>
            </Draggable>

            {/* Modal for Uploading Design */}
            <div className="modal fade" id="add_design" tabIndex="-1" aria-labelledby="add_designLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content px-3">
                        <div className="modal-header border-0">
                            <div className='upload_design'>
                                Upload design
                            </div>
                            <div className='cursor_pointer' data-bs-dismiss="modal">
                                <img src={Close} alt='Close' />

                            </div>
                        </div>
                        <div className="modal-body">
                            <UploadDesignFile screen="visionStudio" layerId={layerId} />
                        </div>
                    </div>
                </div>
            </div>


            {/* Request Approval */}
            <div className="modal fade" id="approvalModal" tabIndex="-1" aria-labelledby="approvalModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content px-3">
                        <div className="modal-header border-0 font-noto-sans">
                            <div className='upload_design fw-bolder fs-20'>
                                Request Approval ?
                            </div>
                            {/* <div className='cursor_pointer' data-bs-dismiss="modal" >
                                            <img src={Close} alt='Close' />
                                        </div> */}
                        </div>
                        <div className="modal-body font-noto-sans">
                            <div className="d-flex  gap-3">
                                <p className="fw-400 fs-16 " > Are you sure you want to request approval ? Once you select <span className='fw-bolder'> Request Approval, </span>
                                    the approver will be notified to review your submission. </p>
                            </div>
                            <div className="modal-footer border-0">
                                <p type="button" className="cancel_modal_footer mx-5" data-bs-dismiss="modal" >No, keep editing</p>
                                <button style={{ paddingLeft: "6px" }} type="button" className="send_modal_footer" data-bs-dismiss="modal" onClick={() => lockNRequestApproval()}> Yes, request</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Cancel Approval */}
            <div className="modal fade" id="cancelModal" tabIndex="-1" aria-labelledby="cancelModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content px-3">
                        <div className="modal-header border-0 font-noto-sans">
                            <div className='upload_design fw-bolder fs-20'>
                                Cancel approval request?
                            </div>
                            {/* <div className='cursor_pointer' data-bs-dismiss="modal" >
                                            <img src={Close} alt='Close' />
                                        </div> */}
                        </div>
                        <div className="modal-body font-noto-sans">
                            <div className="d-flex  gap-3">
                                <p className="fw-400 fs-16 " > Are you sure you want to cancel this approval request?
                                    The approver will be notified that the request has been withdrawn. </p>
                            </div>
                            <div className="modal-footer border-0">
                                <p type="button" className="cancel_modal_footer mx-5" data-bs-dismiss="modal" >No, keep request</p>
                                <button style={{ paddingLeft: "6px" }} type="button" className="send_modal_footer" data-bs-dismiss="modal" onClick={() => cancelRequestApproval()}> Yes, cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default KonvaCanvas;