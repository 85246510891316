import React from 'react';
import { Group } from 'react-konva';

const DraggableGroup = ({ element, layerId, handleDragEnd, handleGroupClick, handleTransformEnd }) => {
  return (
    <Group
      {...element.props}
      draggable
      onDragEnd={(e) => handleDragEnd(e, layerId, element.id)}
      onClick={() => handleGroupClick(layerId, element.id)}
      onTransformEnd={(e) => handleTransformEnd(e, layerId, element.id)}
    />
  );
};

export default DraggableGroup;