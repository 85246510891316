import React, { useEffect, useState } from "react";
import CommentAndTask from "./component/CommentAndTask";
import closeIcon from "../../assets/newicons/close.svg";
import frameImage from "../../assets/newicons/Frame_image.png";
import addanotation from "../../assets/newicons/add-anotion-icon.svg";
import plus from "../../assets/newicons/variant-plus.svg";
import firstPage from "../../assets/newicons/first_page.svg";
import "./Annotation.scss";
import { useNavigate } from "react-router-dom";
import {
    FormControl,
    MenuItem,
    Pagination,
    Select,
    Stack,
} from "@mui/material";
import { useLocation } from "react-router-dom";
// import CanvasComponent from "./component/CanvasComponent";
import AnnotatingImage from "./component/AnnotatingImage";
import { Paginator } from "primereact/paginator";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Ripple } from "primereact/ripple";
import { classNames } from "primereact/utils";
import { Tooltip } from "primereact/tooltip";
import { Slider } from "primereact/slider";
import { Dropdown } from "primereact/dropdown";
import ReferenceGrid from "./component/ReferenceGrid";
import DesignGrid from "./component/DesignGrid";
import gridView from '../../assets/newicons/grid_view.svg';
import viewArray from '../../assets/newicons/view_array.svg';
import ReferenceComp from "./component/ReferenceComp";
import { Mention, MentionsInput } from "react-mentions";
import { useTranslation } from "react-i18next";
import { newGet, newPost, newPut } from "../../API/axios";
import { showAlert } from "./component/AlertService";
import DesignGridForManager from "./component/DesignGridForManager";

const DesignComment = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const { createdProjectId, channelId } = location.state || {};
    const [ProjectId, setCreatedProjectId] = useState(createdProjectId);
    const { variant } = location.state || false;
    const [selectedVersion, setSelectedVersion] = useState(123);

    const [first, setFirst] = useState(0);
    const [activeImag, setActiveImage,] = useState([])
    const [isGridView, setIsGridView] = useState(true);
    const [zoomLevel, setZoomLevel] = useState("10%")
    const [reasonMsg, setReasonMsg] = useState('');

    const [designStageUpadte, setDesignStageUpadte] = useState(
        [
            {
                design_id: "",
                stage_id: "",
                project_id: ProjectId,
                notification_id: "",
                message: ""
            }

        ]
    )

    const [designDetails, setdesignDetails] = useState();
    const [designListLoading, setDesignListLoading] = useState();

    const handleVersionChange = (event) => {
        setSelectedVersion(event.target.value);
    };
    const backToReference = () => {
        navigate('/reference?project_id=' + ProjectId, {
            state: {
                siteId: channelId,
                createdProjectId: ProjectId
            }
        });
    };

    const getDesigns = async () => {
        try {
            setDesignListLoading(true);
            const data = await newGet(`design/getDesign?project_id=${19}`);
            console.log("Design data ", data?.result)
            setdesignDetails(data?.result);
            setDesignListLoading(false);
        }
        catch (error) {
            console.log(error);
            setDesignListLoading(true);
        }
    }

    const handleReturnDesign = async (stageId, designId) => {

        const PayloadForUpdateDesign = {
            design_id: designId,
            stage_id: stageId,
            project_id: ProjectId,
            notification_users: ["1", "2", "3"],
            message: stageId === 1 ? "" : reasonMsg
        }

        console.log('PayloadForUpdateDesign: ', PayloadForUpdateDesign)
        try {
            const data = await newPut(`design/updateDesignStage`, PayloadForUpdateDesign);
            console.log('data: ', data)
        }
        catch (error) {
            console.log(error);
        }
    }

    const handleReturnAllDesign = async (stageId) => {
        // let updateData = JSON.parse(JSON.stringify(designStageUpadte));
        // let uploads = designDetails.map((a) => {
        //     return { ...updateData[0], design_id: a.design_id, stage_id: stageId, notification_id: a.created_by, message: reasonMsg };
        // })
        // const PayloadForUpdateDesign = {
        //     designs: uploads
        // }
        const designIds = designDetails?.map((design) => design.design_id).join(', ');
        const PayloadForUpdateDesign = {
            design_id: designIds,
            stage_id: stageId,
            project_id: ProjectId,
            notification_users: ["1", "2", "3"],
            message: "Submit for approval"
        }
        console.log('PayloadForUpdateDesign: ', PayloadForUpdateDesign)
        try {
            const data = await newPut(`design/updateDesignStage`, PayloadForUpdateDesign);
            console.log('data: ', data)
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getDesigns()
    }, [])


    useEffect(() => {

    }, [])

    const user = "'1', '2', '3"

    const onPageChange = (event) => {
        if (event.first === 0) {
            const indices = [1, 0]
            const filterImagesForActive = indices.map(index => designDetails[index]);
            setFirst(event.first);
            setActiveImage(filterImagesForActive)
        } else if (event.first === designDetails?.length - 1) {
            const indices = [0, designDetails?.length - 1]
            const filterImagesForActive = indices.map(index => designDetails[index]);
            setFirst(event.first);
            setActiveImage(filterImagesForActive)
        } else {
            const indices = [event.first - 1, event.first]
            const filterImagesForActive = indices.map(index => designDetails[index]);
            setFirst(event.first);
            setActiveImage(filterImagesForActive)
        }
    };

    const template1 = {
        layout:
            "FirstPageLink PrevPageLink CurrentPageReport  NextPageLink LastPageLink",
        FirstPageLink: (options) => {
            return (
                <button
                    type="button"
                    className={classNames(options.className, "border-round")}
                    onClick={options.onClick}
                    disabled={options.disabled}
                >
                    <img src={firstPage} alt="viewArrat" className="p-1" />
                </button>
            );
        },
        LastPageLink: (options) => {
            return (
                <button
                    type="button"
                    className={classNames(options.className, "border-round")}
                    onClick={options.onClick}
                    disabled={options.disabled}
                >
                    <img
                        src={firstPage}
                        alt="viewArrat"
                        className="p-1 "
                        style={{ transform: "rotate(180deg)" }}
                    />
                </button>
            );
        },
        CurrentPageReport: (options) => {
            return (
                <span
                    style={{
                        color: "var(--text-color)",
                        userSelect: "none",
                        width: "50px",
                        textAlign: "center",
                    }}
                >
                    {options.first} of {options.totalRecords}
                </span>
            );
        },
    };

    const handleZoomChange = (e) => {
        setZoomLevel(e.target.value)
    }

    return (
        <div>
            <div className={`${isGridView ? "d-none" : "d-block"}`}>
                <div className="p-3 border-bottom header-border-radius d-flex align-items-center justify-content-between px-4 flex-wrap">
                    <div className="d-flex gap-4 align-items-center fw-bold font-noto-sans">
                        <span className="font-noto-sans fs-14 text-black fw-500">
                            {activeImag[0]?.image_url?.split('/').pop()}
                        </span>
                        {/* <div className='rounded-pill py-1 px-3 fs-12 bg-color-gray'>Active</div> */}
                    </div>
                    <div className="pagination-style-top">
                        <div className="">
                            <Paginator
                                template={template1}
                                first={first}
                                rows={1}
                                totalRecords={designDetails?.length || 0}
                                onPageChange={onPageChange}
                            />
                        </div>
                    </div>
                    <div className="d-flex align-items-center gap-3">
                        <button
                            className="rounded-pill px-4 bg-transparent h-36 border-black text-black font-noto-sans fw-bold fs-14 text-nowrap"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModalReturn"
                        >
                            Return
                        </button>
                        <button
                            className="rounded-pill px-4 bg-color-black h-36 border-black text-white font-noto-sans fw-bold fs-14"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                        >
                            Approve
                        </button>
                        {/* approve pop-up */}
                        <div
                            className="modal fade"
                            id="exampleModal"
                            tabIndex="-1"
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true"
                        >
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header border-0">
                                        <h5
                                            className="font-noto-sans fw-bold fs-20 mb-0 text-black"
                                            id="exampleModalLabel"
                                        >
                                            Approve this design?
                                        </h5>
                                    </div>
                                    <div className="modal-body font-noto-sans fw-500 pt-0 ">
                                        Are you sure you want to approve this design?
                                    </div>
                                    <div className="modal-footer mt-0 border-0">
                                        <button
                                            className="rounded-pill px-4 bg-transparent h-36 border-0 text-black font-noto-sans fw-bold fs-14 text-nowrap"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className="rounded-pill px-4 bg-color-black h-36 border-black text-white font-noto-sans fw-500 fs-14"
                                            data-bs-toggle="modal"
                                            data-bs-target="#exampleModal"
                                            onClick={() => handleReturnDesign(1)}
                                        >
                                            Yes, approve
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* return pop-up */}
                        <div
                            className="modal fade"
                            id="exampleModalReturn"
                            tabIndex="-1"
                            aria-labelledby="exampleModalReturnLabel"
                            aria-hidden="true"
                        >
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header border-0">
                                        <h5
                                            className="font-noto-sans fw-bold fs-20 mb-0 text-black"
                                            id="exampleModalReturnLabel"
                                        >
                                            Return this design?
                                        </h5>
                                    </div>
                                    <div className="modal-body font-noto-sans fw-500 pt-0 mb-2">
                                        Are you sure you want to return this design?
                                    </div>
                                    <div className="modal-body font-noto-sans fw-500 pt-0 ">
                                        Please enter your reason for returning the design into the box below, indicating whether the submitter may fix a problem and resubmit.
                                    </div>
                                    <textarea
                                        value={reasonMsg}
                                        placeholder="Describe the reason"
                                        rows={4}
                                        className="font-noto-sans mx-3 p-2 rounded-3"
                                        onChange={(e) => setReasonMsg(e.target.value)}
                                    >
                                    </textarea>
                                    <div className="modal-footer mt-0 border-0">
                                        <button
                                            className="rounded-pill px-4 bg-transparent h-36 border-0 text-black font-noto-sans fw-bold fs-14 text-nowrap"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className="rounded-pill px-4 bg-color-black h-36 border-black text-white font-noto-sans fw-500 fs-14"
                                            data-bs-toggle="modal"
                                            data-bs-target="#exampleModalReturn"
                                            onClick={() => handleReturnDesign(2)}

                                        >
                                            Yes, return
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <img
                            src={closeIcon}
                            alt="no_image"
                            width={24}
                            onClick={backToReference}
                        />
                    </div>
                </div>

                <div className="d-flex justify-content-between flex-column flex-md-row ">
                    <div className={`heigth-calc-56 w-100 annotation-big-image`}>
                        {/* <div className={`w-100 ms-3 pe-5 d-flex align-items-center gap-3 h-100 ${designDetails?.length > 0 ? "justify-content-end" : "justify-content-center pe-0"}`}>
                            {designDetails?.length > 0 && designDetails?.map((image, i) => (
                                <div key={i}>
                                    <AnnotatingImage
                                        src={image?.design_image_url || frameImage}
                                        setIsGridView={setIsGridView} />
                                </div>
                            ))
                            }
                        </div> */}
                    </div>
                    <div className="max-w-420px w-100 bg-color-gray border-start heigth-calc-56 overflow-auto">
                        {!isGridView &&
                            (
                                <CommentAndTask
                                    key={'comments'}
                                    activeImag={activeImag}
                                    designDetails={designDetails}
                                    ProjectId={ProjectId}
                                />
                            )}
                    </div>
                </div>
            </div>
            <div className={`${isGridView ? "d-block" : "d-none"}`}>
                {!designListLoading &&
                    <DesignGridForManager
                        designDetails={designDetails}
                        handleReturnAllDesign={handleReturnAllDesign}
                        reasonMsg={reasonMsg}
                        setReasonMsg={setReasonMsg}
                    />
                }
                {/* <DesignGrid /> */}
            </div>
            <div className='border bg-white width-fit-content d-flex align-items-center gap-3 annotations-row'>
                <div className='d-flex align-items-center justify-content-center border-2 annotate-border px-1 py-1 '>
                    <button className={`${isGridView ? "bg-btn-zoom" : ""} border-0 bg-white px-2 rounded-3 me-1 pb-2`} style={{ height: "24px" }} onClick={() => setIsGridView(true)}>
                        <img src={gridView} alt='gridView' className='align-baseline' width={14} height={14} />
                    </button>
                    <button className={`${isGridView ? "" : "bg-btn-zoom"} border-0 bg-white px-1 rounded-3  pb-3`} style={{ height: "24px" }} onClick={() => setIsGridView(false)}>
                        <img src={viewArray} alt='viewArrat' width={24} height={24} className='align-baseline' />
                    </button>
                </div>
                <div className="header_line border-top-0 border-start-0 border-bottom-0 border-2"></div>
                <div className='d-flex align-content-center gap-4 ps-2'>
                    <button className='font-noto-sans fw-bolder zoom-btn'><span>+</span></button>
                    <button className='font-noto-sans zoom-btn fw-bolder pb-1'><span>-</span></button>
                </div>
                <div>
                    <FormControl >
                        <div className='annotate-border border-2' style={{ minWidth: "90px" }}>
                            <Select
                                value={zoomLevel}
                                name="channel"
                                onChange={handleZoomChange}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                className="w-100  text-black font-noto-sans fs-14 fw-500"
                            >
                                <MenuItem value="10%" selected>10%</MenuItem>
                                <MenuItem value="20%" >20%</MenuItem>
                                <MenuItem value="30%">30%</MenuItem>
                                <MenuItem value="40%">40%</MenuItem>
                                <MenuItem value="50%">50%</MenuItem>

                            </Select>
                        </div>
                    </FormControl>
                </div>
            </div>
        </div>
    );
};

export default DesignComment;
