import DatePicker from "react-datepicker";
import CreateProjectFormLoader from "../skeletonLoaders/CreateProjectFormLoader";
import calendar_month from "../../../assets/newicons/calendar_month.svg";
import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { Button, CircularProgress } from "@mui/material";
import { red } from "@mui/material/colors";

const Timeline = ({
  timeLineformValue,
  setTimeLineFormValue,
  errors,
  setErrors,
  updateTimeline,
  loading,
  //   handleDateChange, validateInput
}) => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState("");
  const [designSubmissionDue, setDesignSubmissionDue] = useState("");
  const [clientFeedbackDue, setClientFeedbackDue] = useState("");
  const [implementationDate, setImplementationDate] = useState(
    timeLineformValue?.implementation_date || null
  );
  const [finalDeliveryDate, setFinalDeliveryDate] = useState(
    timeLineformValue?.final_delivery_date || null
  );

  const [dates, setDates] = useState({
    start_date: timeLineformValue?.start_date || null,
    design_submission_due: timeLineformValue?.design_submission_due || null,
    client_feedback_due: timeLineformValue?.client_feedback_due || null,
    implementation_date: timeLineformValue?.implementation_date || null,
    final_delivery_date: timeLineformValue?.final_delivery_date || null,
  });

  const [error, setError] = useState("");

  useEffect(() => {
    if (timeLineformValue) {
      setDates({
        start_date: timeLineformValue?.start_date
          ? new Date(timeLineformValue.start_date)
          : null,
        design_submission_due: timeLineformValue?.design_submission_due
          ? new Date(timeLineformValue.design_submission_due)
          : null,
        client_feedback_due: timeLineformValue?.client_feedback_due
          ? new Date(timeLineformValue.client_feedback_due)
          : null,
        implementation_date: timeLineformValue?.implementation_date
          ? new Date(timeLineformValue.implementation_date)
          : null,
        final_delivery_date: timeLineformValue?.final_delivery_date
          ? new Date(timeLineformValue.final_delivery_date)
          : null,
      });
      setError(""); // Reset error when timeline data changes
    }
  }, [timeLineformValue]);

  const skeletonCount = [
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
    {
      id: 4,
    },
    {
      id: 5,
    },
  ];

  const validateDates = (updatedDates) => {
    const {
      start_date,
      design_submission_due,
      client_feedback_due,
      implementation_date,
      final_delivery_date,
    } = updatedDates;

    // Chronological order validation
    if (
      start_date &&
      design_submission_due &&
      start_date > design_submission_due
    ) {
      return "Start date must be on or before the design submission due date.";
    }
    if (
      design_submission_due &&
      client_feedback_due &&
      design_submission_due > client_feedback_due
    ) {
      return "Design submission due date must be on or before client feedback due date.";
    }
    if (
      client_feedback_due &&
      implementation_date &&
      client_feedback_due > implementation_date
    ) {
      return "Client feedback due date must be on or before implementation date.";
    }
    if (
      implementation_date &&
      final_delivery_date &&
      implementation_date > final_delivery_date
    ) {
      return "Implementation date must be on or before the final delivery date.";
    }

    // // Past date validation
    // const today = new Date().setHours(0, 0, 0, 0);
    // for (const dateKey in updatedDates) {
    //   if (updatedDates[dateKey] && updatedDates[dateKey] < today) {
    //     return `${t(dateKey)} cannot be in the past.`;
    //   }
    // }

    return "";
  };

  const handleDateChangeLocal = (date, key) => {
    const updatedDates = { ...dates, [key]: date };
    const validationError = validateDates(updatedDates);

    if (validationError) {
      setError(validationError);
    } else {
      setDates(updatedDates);
      handleDateChange(date, key); // Call parent update function
      setError(""); // Clear any existing error
    }
  };

  const addYears = (date, years) => {
    const result = new Date(date);
    result.setFullYear(result.getFullYear() + years);
    return result;
  };

  //   const handleImplementationDateChange = (date) => {
  //     setImplementationDate(date);
  //     handleDateChange(date, "implementation_date");
  //     if (finalDeliveryDate && date > finalDeliveryDate) {
  //       setFinalDeliveryDate(null);
  //       handleDateChange(null, "final_delivery_date");
  //     }
  //   };

  const handleImplementationDateChange = (date) => {
    setImplementationDate(date);
    handleDateChange(date, "implementation_date");
    if (finalDeliveryDate && date > finalDeliveryDate) {
      setFinalDeliveryDate(null);
      handleDateChange(null, "final_delivery_date");
    }
  };

  const handleFinalDeliveryDateChange = (date) => {
    setFinalDeliveryDate(date);
    handleDateChange(date, "final_delivery_date");
  };

  const handleDateChange = (date, name) => {
    // if (isValidYear(date)) {
    // if (validateDates) {
    const formattedDate = date ? format(date, "MM-dd-yyyy") : null;
    setTimeLineFormValue({ ...timeLineformValue, [name]: formattedDate });
    setErrors({ ...errors, [name]: "" });

  };

  // Validating the wrong Input in Date Picker
  const validateInput = (e) => {
    // const datePattern = /^\d{2}\/\d{2}\/\d{4}$/; // MM/DD/YYYY format
    const datePattern = /^\d{2}-\d{2}-\d{4}$/; // MM-DD-YYYY format
    if (!datePattern.test(e.target.value)) {
      e.preventDefault(); // Prevent invalid input
    }
  };

  //   const validateDates = () => {
  //     if (
  //       new Date(timeLineformValue?.start_date) <=
  //         new Date(timeLineformValue.design_submission_due) &&
  //       new Date(timeLineformValue?.design_submission_due) <=
  //         new Date(timeLineformValue?.client_feedback_due) &&
  //       new Date(timeLineformValue?.client_feedback_due) <=
  //         new Date(timeLineformValue?.implementation_date) &&
  //       new Date(timeLineformValue?.implementation_date) <=
  //         new Date(timeLineformValue?.final_delivery_date)
  //     ) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   };

  console.log("timeLineformValue", timeLineformValue);

  

  return (
    <div>
       {/* <div className=''>
                {skeletonCount.map((count, i) => (
                    <div key={count.id}>
                        <div className='w-50 h-25'>
                            <CreateProjectFormLoader height={30} />
                        </div>
                        <CreateProjectFormLoader />
                    </div>
                ))}
            </div>  */}

      {/* Start date */}
      <div className="py-2">
        <div className="w-100 mb-2">
          <div className="mb-1 w-100">
            <label htmlFor="start_date" className="col-form-label">
              Start Date
              <span style={{color:"red"}}>*</span>
            </label>
            {/* <div className={`${IsFormForUpadate && "bg-gray-input rounded-3"}`}> */}
            <div className="w-100 position-relative" id="start_date">
            {error && <div className="error-message">{error}</div>}
              <DatePicker
                selected={dates.start_date}
                onChange={(date) => handleDateChangeLocal(date, "start_date")}
                placeholderText={t("mm-dd-yyyy")}
                className="input-border-gray w-100 h-50 bg-transparent rounded border-secondary p-2 from-input"
                dateFormat="MM-dd-yyyy"
                minDate={new Date()}
                type="date"
                maxDate={dates.design_submission_due || addYears(new Date(), 2)}
                onChangeRaw={validateInput}
              />
              <img
                src={calendar_month}
                alt=" calendar_month"
                className="input-date-icon"
              />
            </div>
            {/* </div> */}
          </div>
          {/* <p className='project-form-error'>{errors.start_date}</p> */}
        </div>

        {/* Design Submission date */}
        <div className="w-100  mb-2">
          <div className="mb-1 w-100">
            <label htmlFor="due_date" className="col-form-label">
              Design Submission Due{" "}
              <span style={{color:"red"}}>*</span>
            </label>
            <div className="">
              <div className="position-relative" id="due_date">
                <DatePicker
                  selected={dates.design_submission_due}
                  onChange={(date) =>
                    handleDateChangeLocal(date, "design_submission_due")
                  }
                  placeholderText={t("mm-dd-yyyy")}
                  className="input-border-gray w-100 h-50 bg-transparent rounded border-secondary p-2 from-input"
                  dateFormat="MM-dd-yyyy"
                  type="date"
                  minDate={dates.start_date || new Date()}
                  maxDate={dates.client_feedback_due || addYears(new Date(), 2)}
                  onChangeRaw={validateInput}
                  
                />
                <img
                  src={calendar_month}
                  alt="calendar_month"
                  className="input-date-icon"
                />
              </div>
            </div>
          </div>
          {/* <p className='project-form-error'>{errors.due_date}</p> */}
        </div>

        {/* Feedback due Date */}
        <div className="w-100  mb-2">
          <div className="mb-1 w-100">
            <label htmlFor="feedback_date" className="col-form-label">
              Feedback Due
              <span style={{color:"red"}}>*</span>
            </label>
            <div className="">
              <div className="position-relative" id="feedback_date">
                <DatePicker
                  selected={dates.client_feedback_due}
                  onChange={(date) =>
                    handleDateChangeLocal(date, "client_feedback_due")
                  }
                  placeholderText={t("mm-dd-yyyy")}
                  className="input-border-gray w-100 h-50 bg-transparent rounded border-secondary p-2 from-input"
                  dateFormat="MM-dd-yyyy"
                  type="date"
                  minDate={dates.design_submission_due || new Date()}
                  maxDate={dates.implementation_date || addYears(new Date(), 2)}
                  onChangeRaw={validateInput}
                />
                <img
                  src={calendar_month}
                  alt="calendar_month"
                  className="input-date-icon"
                />
              </div>
            </div>
          </div>
          {/* <p className='project-form-error'>{errors.due_date}</p> */}
        </div>

        {/* Implementation date */}
        <div className="w-100  mb-2">
          <div className="mb-1 w-100">
            <label htmlFor="implement_date" className="col-form-label">
              Implementation Date{" "}
              <span style={{color:"red"}}>*</span>
            </label>
            <div className="">
              <div className="position-relative" id="implement_date">
                <DatePicker
                  selected={dates.implementation_date}
                  onChange={(date) =>
                    handleDateChangeLocal(date, "implementation_date")
                  }
                  placeholderText={t("mm-dd-yyyy")}
                  className="input-border-gray w-100 h-50 bg-transparent rounded border-secondary p-2 from-input"
                  dateFormat="MM-dd-yyyy"
                  type="date"
                  minDate={dates.client_feedback_due || new Date()}
                  maxDate={dates.final_delivery_date || addYears(new Date(), 2)}
                  onChangeRaw={validateInput}
                />
                <img
                  src={calendar_month}
                  alt="calendar_month"
                  className="input-date-icon"
                />
              </div>
            </div>
          </div>
          {/* <p className='project-form-error'>{errors.due_date}</p> */}
        </div>

        {/* Final delvery date */}
        <div className="w-100">
          <div className="mb-1 w-100">
            <label htmlFor="delivery_date" className="col-form-label">
              Final Delivery Date{" "}
              <span style={{color:"red"}}>*</span>  
            </label>
            <div className="">
              <div className="position-relative" id="delivery_date">
                <DatePicker
                  selected={dates.final_delivery_date}
                  onChange={(date) =>
                    handleDateChangeLocal(date, "final_delivery_date")
                  }
                  placeholderText={t("mm-dd-yyyy")}
                  className="input-border-gray w-100 h-50 bg-transparent rounded border-secondary p-2 from-input"
                  dateFormat="MM-dd-yyyy"
                  type="date"
                  minDate={dates.implementation_date || new Date()}
                  maxDate={addYears(new Date(), 2)}
                  onChangeRaw={validateInput}
                />
                <img
                  src={calendar_month}
                  alt="calendar_month"
                  className="input-date-icon"
                />
              </div>
            </div>
          </div>
          {/* <p className='project-form-error'>{errors.due_date}</p> */}
        </div>

        <div className="modal-footer border-0">
          <p
            type="button"
            className="cancel_modal_footer"
            data-bs-dismiss="modal"
          >
            {t("cancel")}
          </p>
          <button
            type="button"
            className="send_modal_footer"
            data-bs-dismiss="modal"
            
            disabled={
              timeLineformValue?.start_date === null || loading ? true : false
            }
            onClick={() => updateTimeline()}
          >
            {!loading ? `${t("save")}` : ""}
            <React.Fragment>
              {loading ? <CircularProgress color="inherit" size={20} /> : null}
            </React.Fragment>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Timeline;
