import { useNavigate } from "react-router-dom";
import unauthorized from "../../../assets/images/unauthorized.png"
const UnAuthorizedPage = () => {

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1); 
      };
    

    return(
        <div>
            <div>
                <h1 className="d-md-flex align-items-center justify-content-center mt-5 text-primary">404</h1>
                {/* <h3 className="d-md-flex align-items-center justify-content-center mt-2 text-primary"> Unauthorized Access</h3> */}
                <h3 className="d-md-flex align-items-center justify-content-center mt-2 text-primary"> Page Not Found</h3>
                <p className="d-md-flex align-items-center justify-content-center mt-4">Sorry, the page you are looking for does not exist.</p>
                {/* <p className="d-md-flex align-items-center justify-content-center mt-4">Sorry, you don't have permission to access this page.</p> */}
                <p className="d-md-flex align-items-center justify-content-center  -mt-3"> You can go back to <span className="fw-bold mx-1 cursor-pointer" onClick={handleGoBack}> previous page</span></p>
                 <div className="d-md-flex align-items-center justify-content-center w-sm-50">
                <img src={unauthorized} alt="unauthorized"/>
                 </div>
            </div>
        </div>
    )
}

export default UnAuthorizedPage;