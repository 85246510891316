import React, { useRef } from 'react';
import { Group, Text } from 'react-konva';

const DraggableText = ({
  element,
  layerId,
  handleDragEnd,
  handleGroupClick,
  handleDoubleClick,
  handleRightClick,
  handleTransformEnd,
  stageDimensions,
  handleDragStart
}) => {
  const groupRef = useRef();
  const dragBoundFunc = (pos) => {
    const group = groupRef.current;
    if (!group) return pos;

    const stage = group.getStage();

    // Define your boundary here
    const boundary = {
      x: 0, // Starting x position of the boundary
      y: 0, // Starting y position of the boundary
      width: stageDimensions.width, // Width of the boundary
      height: stageDimensions.height, // Height of the boundary
    };

    // Get the group's transformed bounding box relative to the stage
    const box = group.getClientRect({ relativeTo: stage });

    const elementWidth = box.width;
    const elementHeight = box.height;

    // Calculate the offset between the group's position and its bounding box position
    const offsetX = box.x - group.x();
    const offsetY = box.y - group.y();

    // Calculate the new bounding box position based on the proposed pos
    const newBoxX = pos.x + offsetX;
    const newBoxY = pos.y + offsetY;

    // Calculate the maximum allowed positions
    const maxX = boundary.x + boundary.width - elementWidth;
    const maxY = boundary.y + boundary.height - elementHeight;

    // Constrain the new bounding box position within the boundary
    const constrainedBoxX = Math.max(boundary.x, Math.min(newBoxX, maxX));
    const constrainedBoxY = Math.max(boundary.y, Math.min(newBoxY, maxY));

    // Adjust back to the group's position
    const finalX = constrainedBoxX - offsetX;
    const finalY = constrainedBoxY - offsetY;

    return {
      x: finalX,
      y: finalY,
    };
  };

  return (
    <Group
      id={element.id}
      ref={groupRef}
      draggable
      x={element.props.x}
      y={element.props.y}
      onDragEnd={(e) => handleDragEnd(e, layerId, element.id)}
      onClick={() => handleGroupClick(layerId, element.id)}
      onDblClick={(e) => handleDoubleClick(element, e.target)}
      onContextMenu={(e) => handleRightClick(e, element)}
      dragBoundFunc={dragBoundFunc}
      onTouchStart={() => handleGroupClick(layerId, element.id)}
      onDblTap={(e) => handleDoubleClick(element, e.target)}
      onDragStart={(e) => handleDragStart(e, layerId, element.id)}
    >
      <Text
        {...element.props}
        onTransformEnd={(e) => handleTransformEnd(e, layerId, element.id)}
      />
    </Group>
  );
};

export default DraggableText;
