import React, { useEffect, useRef, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useEditContext } from "../../../context/useEditContext";
import { useFilterContext } from "../../../context/useFilterContext";
import { useSearchContext } from "../../../context/useSearchContext";
import { useAuthContext } from "../../../context/useAuthContext";
import { ScrollTop } from "primereact/scrolltop";
import { InputSwitch } from "primereact/inputswitch";
import { toast } from "react-toastify";
import debounce from "debounce";
import { post } from "../../../API/axios";
// icons
import close from "../../../assets/icons/black-close.svg";
import CardLoader from "../../../components/Loaders/CardLoader";
import BackIcon from "../../../assets/icons/arrow-circle-left.svg";
import filter from "../../../assets/icons/filter.svg";
//components
import MicoBackground from "../../../components/MicoBackground";
import SearchInput from "../../../components/SearchInput";
// import InitialSearch from "../../../main";
import SearchLoader from "../../../components/Loaders/SearchLoader";
import CategoryOptions from "../../../helpers/CategoryOptions";
import ModelOptions from "../../../helpers/ModelOptions";
// css
import "./BrowseSearch.scss";
import "../../../assets/i18n/en.json";
import Card from "../../../components/Card";
import BrowseImageDetails from "./BrowseImageDetails";
const BrowseSearch = ({ onSetImageUrl, showImageDetails, setShowImageDetails }) => {
    console.log("onSetImageUrl", onSetImageUrl)
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const { filterTags } = useFilterContext();

    const {
        selectedSite,
        initialLoading,
        permissions,
        saveSelectedSite,
        areAPIsLoading,
        isDebug,
        setIsDebug
    } = useAuthContext();

    const {
        clearEditData,
        searchQuery,
        setSearchQuery,
        refreshSearch,
        setRefreshSearch,
    } = useEditContext();
    const scrollRef = useRef(null);
    const [isSiteSearchOpen, setIsSiteSearchOpen] = useState(false);
    const [offSetId, setOffSetId] = useState(null);
    // const [showImageDetails, setShowImageDetails] = useState(false);


    const {
        searchResults,
        setSearchResults,
        similarResults,
        setSimilarResults,
        results,
        similarityGroup,
        setSimilarityGroup,
        setResults,
        numberOfResults,
        setNumberOfResults,
        formValue,
        setFormValue,
        showResults,
        setShowResults,
        // prevSearch,
        setPrevSearch,
    } = useSearchContext();
    const [checked, setChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [tagLoader, setTagLoader] = useState(false);
    const [filteredSites, setFilteredSites] = useState(permissions?.access_sites);
    const [tags, setTags] = useState([]);
    const [scrollLoader, setScrollLoader] = useState(false);
    const [resetData, setResetData] = useState({});
    const [isBackGroundShow, setIsBackGroundShow] = useState(false);
    const [globalGroupedImageIds, setGlobalGroupedImageIds] = useState(new Set());
    const globalGroupedImageIdsRef = useRef(globalGroupedImageIds);
    const [imageData, setImageData] = useState();
    // const [searchResults, setSearchResults] = useState([]);

    // console.log("searchResults", searchResults)

    const en_tags = localStorage.getItem("en_tags");
    const ja_tags = localStorage.getItem("ja_tags");
    const currentLanguage = i18n.language;
    // filter
    const [isFilterOpen, setIsFilterOpen] = useState(
        window.innerWidth >= 992 ? true : false
    );

    const ToggleSidebar = () => {
        setIsFilterOpen(!isFilterOpen);
    };

    const checkCategoryLengths = (payload) => {
        const updatedPayload = { ...payload };
        for (let tag of filterTags?.categories) {
            const categoryKey = tag?.category_key;
            const payloadKey = payload[categoryKey];

            if (payloadKey) {
                const filterTagLength = Object.keys(tag.category_options).length;
                const payloadLength = payloadKey.length;
                if (filterTagLength === payloadLength) {
                    updatedPayload[categoryKey] = [];
                    // delete updatedPayload[categoryKey];
                }
            }
        }
        for (let tag of filterTags?.models) {
            const modelKey = tag?.model_key_name;
            const payloadKey = payload?.model_filters?.find(
                (model) => model?.model_key_name === modelKey
            );

            if (payloadKey) {
                const filterTagLength = Object.keys(tag?.model_options).length;
                const payloadLength = payloadKey?.model_options?.length;
                if (filterTagLength === payloadLength) {
                    updatedPayload.model_filters = updatedPayload.model_filters.map(
                        (item) => {
                            if (item.model_key_name === modelKey) {
                                return { ...item, model_options: [] };
                            }
                            return item;
                        }
                    );
                }
            }
        }
        return updatedPayload;
    };

    useEffect(() => {
        globalGroupedImageIdsRef.current = globalGroupedImageIds;
    }, [globalGroupedImageIds]);

    const resetSearch = () => {
        setNumberOfResults({
            primary: 0,
            similar: 0,
        });
        setFormValue({ ...formValue, 'page': 1 });
        setGlobalGroupedImageIds(new Set());
        setSimilarityGroup([]);
        setSimilarResults([]);
    }

    const getSearchText = async (isNextPage = false) => {
        setIsLoading(true);
        // if (prevSearch) {
        //     toast.info("Refreshing Search", { position: "top-center" });
        // }
        let payload;
        if (!isNextPage) {
            resetSearch();
        }
        setRefreshSearch(false);

        if (searchQuery?.input_text) {
            payload = {
                input_text: searchQuery.input_text,
                lang: currentLanguage, //en or ja
                page: isNextPage ? searchQuery.page + 1 : 1,
                ctr: Number(searchQuery.ctr),
            };
            if (searchQuery.tags.length > 0) {
                payload.filter_tags = searchQuery.tags;
            }
            if (
                formValue?.input_text ||
                formValue?.ctr ||
                formValue.tags.length !== 0
            ) {
                payload = {
                    input_text: formValue.input_text,
                    lang: currentLanguage, //en or ja
                    page: isNextPage ? formValue.page + 1 : 1,
                    ctr: Number(formValue.ctr),
                    is_edited: formValue.is_edited,
                };
                if (formValue.tags.length > 0) {
                    payload.filter_tags = formValue.tags;
                }
            }
        } else {
            payload = {
                input_text: formValue.input_text,
                lang: currentLanguage, //en or ja
                page: isNextPage ? formValue.page + 1 : 1,
                ctr: Number(formValue.ctr),
                is_edited: formValue.is_edited,
            };
            if (formValue?.tags?.length > 0) {
                payload.filter_tags = formValue.tags;
            }
        }

        if (payload?.ctr > 100) {
            toast.info("CTR cannot be greater than 100");
            setIsLoading(false);
            return;
        }

        if (isNextPage) {
            setScrollLoader(true);
            setFormValue({ ...formValue, page: formValue.page + 1 });
        }
        payload.site_id =
            typeof selectedSite?.site_id == "object"
                ? selectedSite?.site_id
                : [selectedSite?.site_id];
        const { tags, ctr, input_text, lang, page, site_id, is_edited, ...rest } =
            formValue;

        payload = {
            ...payload,
            ...rest,
        };

        if (
            !payload?.input_text &&
            payload?.ctr <= 0 &&
            !payload?.filter_tags?.length &&
            !payload?.delivery_content?.length &&
            !payload?.delivery_type?.length &&
            !payload?.image_type?.length &&
            !payload?.industry?.length &&
            !payload?.model_filters?.length &&
            !payload?.is_edited
        ) {
            // toast.info("Apply Atleast One Filter!");
            setIsLoading(false);
            return;
        }
        if (payload.input_text.includes("in:")) {
            payload.input_text = payload.input_text.replace(/in:.*/, "").trim();
        }

        setShowResults(true);
        payload = await checkCategoryLengths(payload);

        setResetData(formValue);

        await post(`text_search_image/`, ((offSetId && isNextPage) ? { offset_id: offSetId, ...payload } : payload))
            .then((response) => {

                setNumberOfResults({
                    total_images: response?.pagination?.total_images_in_page,
                    primary: response?.pagination?.total_images,
                    similar: response?.pagination?.total_images_in_current_page,
                });
                setOffSetId(response?.pagination?.offset_id)
                if (!isNextPage) {


                    setSearchResults(response?.data);
                    manageSimilarGroup(response, isNextPage)
                    setResults(response?.data);

                } else {
                    if (response?.data?.length > 0) {
                        setSearchResults((prevResults) => [
                            ...prevResults,
                            ...response?.data,
                        ]);
                        manageSimilarGroup(response, isNextPage);
                        setResults((prevResults) => [...prevResults, ...response?.data]);
                    }
                }
                setTimeout(() => {
                    setIsLoading(false);
                    setScrollLoader(false);
                }, 1000);

            })
            .catch((error) => {
                setIsLoading(false);
                setShowResults(true);
                console.error("error", error);
            })
            .finally(() => {
                setPrevSearch(false);
            });
    };

    // const [sentryRef] = useInfiniteScroll({
    //     loading: scrollLoader,
    //     hasNextPage: numberOfResults.total_images == numberOfResults.primary ? false :
    //         true || searchResults?.length === 0 ? true : false,
    //     onLoadMore: () => getSearchText(true),
    //     disabled: false,
    //     delayInMs: 2000,
    //     rootMargin: "0px 0px 400px 0px",
    // });



    const manageSimilarGroup = (response, isNextPage = false) => {
        const newGroupedImageIds = new Set(globalGroupedImageIdsRef.current);

        const groupedImages = response?.data.reduce((acc, image) => {
            const imageId = image.image_id;

            if (!newGroupedImageIds.has(imageId)) {
                const similarGroup = {
                    similar_group_id: imageId,
                    similar_group_images: [image],
                };
                newGroupedImageIds.add(imageId);

                const similarImageIds = response?.similar_image_map[imageId] || [];

                similarImageIds.forEach((similarId) => {
                    if (!newGroupedImageIds.has(similarId)) {
                        const similarImageInfo = response?.data.find((img) => img.image_id === similarId);
                        if (similarImageInfo) {
                            similarGroup.similar_group_images.push(similarImageInfo);
                            newGroupedImageIds.add(similarId);
                        }
                    }
                });

                response?.data.forEach((otherImage) => {
                    if (!newGroupedImageIds.has(otherImage.image_id)) {
                        const otherSimilarIds = response?.similar_image_map[otherImage.image_id] || [];
                        if (otherSimilarIds.includes(imageId)) {
                            similarGroup.similar_group_images.push(otherImage);
                            newGroupedImageIds.add(otherImage.image_id);
                        }
                    }
                });
                acc.push(similarGroup);
            }

            return acc;
        }, []);

        const groupData = groupedImages.map(group => ({
            ...group,
            ...group.similar_group_images[0]
        }));
        if (!isNextPage) {
            setSimilarityGroup(groupedImages);
            setSimilarResults(groupData);
        } else {
            setSimilarityGroup((prevResults) => [...prevResults, ...groupedImages]);
            setSimilarResults((prevResults) => [...prevResults, ...groupData]);
        }
        setGlobalGroupedImageIds(newGroupedImageIds);
    }

    // Get All Unique Tags
    const getSearchTags = async () => {
        try {
            setTagLoader(true);
            const { data } = await post(`get_all_unique_tags/`, {
                lang: "all",
            });

            localStorage.setItem("en_tags", JSON.stringify(data.en));
            localStorage.setItem("ja_tags", JSON.stringify(data.ja));
            setTags([...(data?.en || []), ...(data?.ja || [])]);
            // setTags(
            //   (currentLanguage === "ja" ? data.ja : data.en).filter((t) => t !== "")
            // );
            setTagLoader(false);
        } catch (error) {
            console.log("error", error);
        }
    };



    const handleChange = (e) => {
        const { name, value } = e.target;
        // if (name === "input_text") {
        //   if (value.includes("in:")) {
        //     setIsSiteSearchOpen(true);
        //     const searchValue = value.split("in:")[1].trim();
        //     const filtered = permissions?.access_sites.filter((site) =>
        //       site.site_name.toLowerCase().includes(searchValue.toLowerCase())
        //     );
        //     setFilteredSites(filtered);
        //   } else {
        //     setIsSiteSearchOpen(false);
        //     setFilteredSites(permissions?.access_sites);
        //   }
        // }

        if (name === 'ctr') {
            const sanitizedValue = value.replace(/\D/g, '');
            if (sanitizedValue === "" || (parseFloat(sanitizedValue) >= 0 && parseFloat(sanitizedValue) <= 100)) {
                setFormValue({ ...formValue, [name]: sanitizedValue });
            }
        } else {
            setFormValue({ ...formValue, [name]: value });
        }
    };

    const handleHashtagChange = (value) => {
        setFormValue({ ...formValue, tags: value });
    };

    const searchTags = debounce(() => {
        getSearchTags();
    }, 1000);

    useEffect(() => {
        clearEditData();
        setupLanguage();
    }, [currentLanguage]);

    const setupLanguage = () => {
        if (en_tags || ja_tags) {
            setFormValue({ ...formValue, tags: [] });
            setTags([...JSON.parse(en_tags), ...JSON.parse(ja_tags)]);
            // setTags(
            //   currentLanguage === "ja" ? JSON.parse(ja_tags) : JSON.parse(en_tags)
            // );
        } else {
            setFormValue({ ...formValue, tags: [] });
            searchTags();
        }
    };

    const handleImageEdit = (data) => {
        console.log("Edit data", data);
        onSetImageUrl(data.image_url);
        setShowImageDetails(true);
        // if (!data.image_similarity_id) {
        //   setSearchQuery(formValue);
        //   navigate(`/image-details?id=${data.image_id}`, {
        //     state: {
        //       data: data,
        //     },
        //   });
        //   return;
        // }
        const SimilarImages = similarityGroup?.find(
            (a) => a.similar_group_id === data.image_id
        );
        if (SimilarImages && SimilarImages?.similar_group_images?.length > 0) {

            let highestScoreImage = "";
            if (checked && SimilarImages.similar_group_images.length > 0 && SimilarImages.similar_group_images[0].score) {
                highestScoreImage = SimilarImages.similar_group_images.reduce((prev, current) =>
                    prev.score > current.score ? prev : current
                );
            }

            const imageData = highestScoreImage ? highestScoreImage : data;
            setSearchQuery(formValue);
            setImageData(imageData);
            // navigate(`/image-details?id=${imageData?.image_id}`, {
            //     state: {
            //         swiperImages: SimilarImages.similar_group_images.length > 1 ? SimilarImages : [],
            //         data: imageData,
            //     },
            // });
        } else {
            setSearchQuery(formValue);
            setImageData(imageData);
            // navigate(`/image-details?id=${data.image_id}`, {
            //     state: {
            //         data: data,
            //     },
            // });
        }
    };

    const handleSwitch = async (e) => {
        setChecked(!checked);
        setSearchResults(e.value === false ? results : similarResults);
    };

    useEffect(() => {
        setSearchResults(checked === false ? results : similarResults);
    }, [handleSwitch]);

    const onSiteChange = (index, site = "") => {
        saveSelectedSite(index, site);
        let currentInputValue = formValue.input_text || "";
        const regex = new RegExp(`in:\\s*${site.site_name}`, "g");
        if (formValue.input_text.includes("in:")) {
            currentInputValue = formValue.input_text.replace(/in:.*/, "").trim();
        }
        const newInputValue = currentInputValue.replace(regex, "").trim();
        setFormValue({ ...formValue, input_text: newInputValue });
        setFilteredSites(permissions?.access_sites);
        setIsSiteSearchOpen(false);
    };

    const removeFilters = async () => {
        setFormValue(resetData);
    };

    const setupPreviusSearch = () => {
        if (searchQuery?.input_text) {
            setFormValue(searchQuery);
            getPreviousSearch();
        } else {
            setFormValue({ input_text: "", page: 1, ctr: 0, tags: [] });
        }
    };
    useEffect(() => {
        if (refreshSearch) {
            setupPreviusSearch();
            clearEditData();
        }
    }, []);

    const getPreviousSearch = async () => {
        if (searchQuery?.input_text) {
            setShowResults(true);
            await getSearchText(false);
        }
    };

    const backToInitialSearch = () => {
        if (showResults) {
            console.log("first")
            setShowResults(false);
            setFormValue({
                input_text: "",
                page: 1,
                ctr: 0,
                tags: [],
                model_filters: [],
                search_by: "both",
                is_edited: "all"
            });
        } else {
            console.log("second")
            navigate("/dashboard");
        }
    };

    useEffect(() => {
        if (window.innerWidth >= 992) {
            setIsFilterOpen(true);
        }
    }, [window.innerWidth]);

    // useEffect(() => {
    //     if (prevSearch) {
    //         getSearchText();
    //     }
    // }, [prevSearch]);

    useEffect(() => {
        const handleScroll = () => {
            if (scrollRef.current) {
                const { scrollTop } = scrollRef.current;
                // if (scrollTop >= 1215) {
                setIsBackGroundShow(true);
                // } 
                // else {
                //     setIsBackGroundShow(false);
                // }
            }
        };
        const scrollElement = scrollRef.current;
        if (scrollElement) {
            scrollElement.addEventListener("scroll", handleScroll);
        }
        return () => {
            if (scrollElement) {
                scrollElement.removeEventListener("scroll", handleScroll);
            }
        };
    }, []);

    useEffect(() => {
        searchTags();
    }, []);

    return (
        <div>
            {!showImageDetails &&
                <section
                    className={`${!showResults ? "flex-column" : ""
                        } d-flex gap-2 search-hero-section  position-relative`}
                >
                    {/* filter sidebar */}
                    {showResults && (
                        <>
                            <div className={`sidebar ${isFilterOpen ? "active" : "d-none"} `}>
                                <div className="sd-header-container sticky-top">
                                    <div className="sd-header">
                                        <div className="d-flex" style={{ flexDirection: "row" }}>
                                            {/* <img
                                                src={filter}
                                                className="p-1"
                                                alt="filter"
                                                onClick={ToggleSidebar}
                                                style={{
                                                    width: "25px",
                                                    height: "25px",
                                                    cursor: "pointer",
                                                }}
                                            /> */}
                                            <h4 className="mb-0">{t("filter")}</h4>
                                        </div>

                                        <div
                                            className="border-blue rounded"
                                            onClick={ToggleSidebar}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <img src={close} alt="close" />
                                        </div>
                                    </div>
                                    <div className="d-flex m-1 justify-content-around w-100">
                                        <button
                                            className="border-blue rounded px-3"
                                            onClick={removeFilters}
                                        >
                                            {t("reset")}
                                        </button>
                                        <button
                                            className="border-blue rounded px-3 btn"
                                            onClick={() => getSearchText()}
                                        >
                                            {t("apply")}
                                        </button>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-start ps-3 align-items-center w-100">
                                    <p className="mb-0 text-gray ctr-label me-3">{t("ctr")}</p>
                                    <input
                                        className="ctrwidth w-100 text-gray rounded-2 border border-dark bg-white"
                                        type="text"
                                        pattern="\d*"
                                        inputMode="numeric"
                                        placeholder={0}
                                        name="ctr"
                                        max={100}
                                        min={0}
                                        onChange={handleChange}
                                        value={formValue.ctr}

                                    />
                                    <p className="mb-0 ms-1 text-gray">%</p>
                                </div>

                                <div className="d-flex justify-content-start p-3 my-2 pb-0 align-items-center w-100">
                                    <div>
                                        {" "}
                                        <p className="mb-1 text-gray ctr-label me-3">
                                            {t("imageStatus")}
                                        </p>
                                        <div className="d-flex gap-2">
                                            <div>
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    id="all"
                                                    value="all"
                                                    name="is_edited"
                                                    checked={formValue.is_edited === "all"}
                                                    onChange={handleChange}
                                                />
                                                <label className="text-gray" htmlFor="all">
                                                    {t("All")}
                                                </label>
                                            </div>
                                            <div>
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    value="edited"
                                                    id="edited"
                                                    name="is_edited"
                                                    checked={formValue.is_edited === "edited"}
                                                    onChange={handleChange}
                                                />
                                                <label className="text-gray" htmlFor="edited">
                                                    {t("edited")}
                                                </label>
                                            </div>
                                            <div>
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    id="unedited"
                                                    value="unedited"
                                                    checked={formValue.is_edited === "unedited"}
                                                    onChange={handleChange}
                                                    name="is_edited"
                                                />
                                                <label className="text-gray ml-1" htmlFor="unedited">
                                                    {t("unEdited")}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="sd-body">
                                    {filterTags?.categories?.map((tag, index) => (
                                        <React.Fragment key={index}>
                                            <ul>
                                                <li className="sd-link">
                                                    <p>{t(tag.category_display_code)}</p>
                                                </li>
                                                <CategoryOptions
                                                    tag={tag}
                                                    formValue={formValue}
                                                    setFormValue={setFormValue}
                                                />
                                            </ul>
                                            <hr className="line" />
                                        </React.Fragment>
                                    ))}
                                    {filterTags?.models?.map((tag, index) => (
                                        <React.Fragment key={index}>
                                            <ul>
                                                <li className="sd-link">
                                                    <img
                                                        src={`data:image/jpeg;base64,${tag.model_icon}`}
                                                        className="p-1 border-0"
                                                        alt="filter"
                                                        style={{ width: "30px", height: "30px" }}
                                                    />
                                                    <p>{t(tag.model_display_code)}</p>
                                                </li>

                                                <ModelOptions
                                                    tag={tag}
                                                    formValue={formValue}
                                                    setFormValue={setFormValue}
                                                />
                                            </ul>
                                            <hr className="line" />
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                        </>
                    )}
                    {/* filter sidebar end */}

                    <div className="w-100 overflow-auto position-relative" ref={scrollRef}>
                        {/* <MicoBackground /> */}
                        <div

                        >
                            {/* {!showResults && (initialLoading || areAPIsLoading) ? (
                            <SearchLoader />
                        ) : !showResults && !initialLoading && !areAPIsLoading ? (
                            <InitialSearch
                                getSearchText={getSearchText}
                                onSiteChange={onSiteChange}
                                getSearchTags={getSearchTags}
                                tags={tags}
                                handleChange={handleChange}
                                handleHashtagChange={handleHashtagChange}
                                filteredSites={filteredSites}
                                isSiteSearchOpen={isSiteSearchOpen}
                                tagLoader={tagLoader}
                                scrollRef={scrollRef}
                            />
                        ) : (
                            ""
                        )} */}
                            {showResults && (
                                <div className="filter-container">
                                    <div
                                        className={`search-bar2 search-bar ${isBackGroundShow ? "bg-white shadow search-bar-width" : ""} `}
                                    >
                                        <div className="d-flex justify-content-center align-items-center w-100 flex-column">
                                            <div
                                                className={`w-100  ${isBackGroundShow ? "max-w-70 mt-2" : "header-max-w-100"}`}
                                            >
                                                <div className="search-bar-inner flex-column">
                                                    <div>
                                                        <span className="filter-label fs-16 mb-3">
                                                        </span>
                                                        <div className="d-flex align-items-center justify-content-center gap-3">
                                                            <div className="text-dynamic">
                                                                {t("searchBy")} :
                                                            </div>
                                                            <div>
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    id="text"
                                                                    value="text"
                                                                    name="search_by"
                                                                    checked={formValue.search_by === "text"}
                                                                    onChange={(e) => handleChange(e)}
                                                                />
                                                                <label className="text-dynamic" htmlFor="text">
                                                                    Text
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    value="desc"
                                                                    id="desc"
                                                                    name="search_by"
                                                                    checked={formValue.search_by === "desc"}
                                                                    onChange={(e) => handleChange(e)}
                                                                />
                                                                <label className="text-dynamic" htmlFor="desc">
                                                                    Description
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    id="both"
                                                                    value="both"
                                                                    checked={formValue.search_by === "both"}
                                                                    onChange={(e) => handleChange(e)}
                                                                    name="search_by"
                                                                />
                                                                <label className="text-dynamic" htmlFor="both">
                                                                    Both
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center w-100">
                                                        <div className="position-fixed rounded-2 bg-white sidebar-toggle p-1">
                                                            <img
                                                                onClick={ToggleSidebar}
                                                                src={filter}
                                                                className="p-1 border-0"
                                                                alt="filter"
                                                                style={{
                                                                    width: "30px",
                                                                    height: "30px",
                                                                    cursor: "pointer",
                                                                }}
                                                            />
                                                        </div>

                                                        {/* <div
                                                        className={`back-btn rounded-circle p-0 backbtn ${isBackGroundShow ? "back-btn-position" : ""
                                                            }`}
                                                    >
                                                        <img
                                                            src={BackIcon}
                                                            alt="backicon"
                                                            onClick={backToInitialSearch}
                                                            // className="w-100"
                                                            style={{
                                                                width: "42px",
                                                                height: "42px",
                                                                cursor: "pointer",
                                                            }}
                                                        />
                                                    </div> */}
                                                        <SearchInput screen="browseSearch" getSearchText={getSearchText} scrollRef={scrollRef} />
                                                    </div>


                                                </div>
                                                {numberOfResults.primary !== 0 && (
                                                    <div className="d-flex justify-content-start  mt-1 gap-3 p-2 flex-wrap">
                                                        {isDebug ? (
                                                            <div
                                                                className="d-flex gap-1"
                                                                style={{ fontSize: "0.9rem" }}
                                                            >
                                                                <span className="text-gray">
                                                                    {checked ? t("numberofresultsHidden") : t("numberofresults")}
                                                                </span>{" "}
                                                                :{" "}
                                                                <span className="text-gray">
                                                                    {checked
                                                                        ? (results.length - similarResults.length)
                                                                        : numberOfResults?.primary}
                                                                </span>
                                                            </div>
                                                        ) : <></>}

                                                        {results.length !== 0 &&
                                                            <div
                                                                className="d-flex gap-1"
                                                                style={{ fontSize: "0.9rem" }}
                                                            >
                                                                <span>{t("switchTitle")}</span>
                                                                <InputSwitch
                                                                    checked={checked}
                                                                    onChange={handleSwitch}
                                                                />
                                                            </div>
                                                        }
                                                        {/* {permissions?.debug_mode ? (
                                  <div
                                    className="d-flex gap-1"
                                    style={{ fontSize: "0.9rem" }}
                                  >
                                    <span>{t("allowDebug")}</span>
                                    <InputSwitch
                                      checked={isDebug}
                                      onChange={(e) => {
                                        setIsDebug(e.value)
                                      }}
                                    />
                                  </div>
                                ) : <></>} */}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <>
                                        <div className="d-flex overflow-hidden" style={{ marginTop: "100px" }}>
                                            <div className="d-flex justify-content-center font-sora">
                                                <div className="d-flex justify-content-center flex-column align-items-center gap-2">
                                                    <ScrollTop
                                                        target="window"
                                                        className="rounded-pill purple-gradiant"
                                                    />
                                                    <div className="searchImagesScroll">
                                                        <div className="container">
                                                            <div className="d-flex gap-3 justify-content-center flex-wrap mt-4 pb-5">
                                                                {(isLoading ||
                                                                    initialLoading ||
                                                                    areAPIsLoading) &&
                                                                    !scrollLoader ? (
                                                                    <>
                                                                        {[...Array(8)].map((_, i) => (
                                                                            <CardLoader key={i} />
                                                                        ))}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {
                                                                            // searchResults?.length === 0 && !isLoading ? (
                                                                            //     <p>No Records Found.</p>
                                                                            // ) : (
                                                                            searchResults?.map((data, index) => (
                                                                                <div key={index}>
                                                                                    <Card
                                                                                        data={data}
                                                                                        isDebug={isDebug}
                                                                                        handleViewDetails={handleImageEdit}
                                                                                        setShowImageDetails={setShowImageDetails}
                                                                                    />
                                                                                </div>
                                                                            ))
                                                                            // )
                                                                        }
                                                                        {/* <div ref={sentryRef} /> */}
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                        {scrollLoader && (
                                                            <>
                                                                <div className="searchImagesScroll">
                                                                    <div className="container">
                                                                        {/* <div className="d-flex gap-3 justify-content-center flex-wrap mt-4 pb-5">
                                                                        {[...Array(8)].map((_, i) => (
                                                                            <CardLoader key={i} />
                                                                        ))}
                                                                    </div> */}
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                </div>
                            )}
                        </div>
                    </div>
                </section>
            }

            {
                showImageDetails &&
                <div>
                    <BrowseImageDetails data={imageData} showImageDetails ={showImageDetails} setShowImageDetails= {setShowImageDetails}/>
                </div>
            }
        </div>
    );

}

export default BrowseSearch;