import React, { useState } from "react";
import CommentAndTask from "../component/CommentAndTask";
import closeIcon from "../../../assets/newicons/close.svg";
import frameImage from "../../../assets/newicons/Frame_image.png";
import addanotation from "../../../assets/newicons/add-anotion-icon.svg";
import plus from "../../../assets/newicons/variant-plus.svg";
import Add from "../../../assets/newicons/add.svg";
import chatBubble from "../../../assets/newicons/chat_bubble.svg";

import firstPage from "../../../assets/newicons/first_page.svg";
import "../Annotation.scss";
import { useNavigate } from "react-router-dom";
import {
    FormControl,
    MenuItem,
    Pagination,
    Select,
    Stack,
} from "@mui/material";
import { useLocation } from "react-router-dom";
// import CanvasComponent from "./component/CanvasComponent";
import { Paginator } from "primereact/paginator";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Ripple } from "primereact/ripple";
import { classNames } from "primereact/utils";
import { Tooltip } from "primereact/tooltip";
import { Slider } from "primereact/slider";
import { Dropdown } from "primereact/dropdown";

const DesignGridForManager = ({ designDetails, handleReturnAllDesign, reasonMsg, setReasonMsg }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { selectedImage } = location.state || {};
    const { variant } = location.state || false;
    const [selectedVersion, setSelectedVersion] = useState(123);
    const [showCommentSection, setShowCommentSection] = useState(true);

    const [first, setFirst] = useState(0);

    const handleVersionChange = (event) => {
        setSelectedVersion(event.target.value);
    };
    const backToReference = () => {
        navigate("/reference");
    };

    const onPageChange = (event) => {
        setFirst(event.first);
    };

    const template1 = {
        layout:
            "FirstPageLink PrevPageLink CurrentPageReport  NextPageLink LastPageLink",
        FirstPageLink: (options) => {
            return (
                <button
                    type="button"
                    className={classNames(options.className, "border-round")}
                    onClick={options.onClick}
                    disabled={options.disabled}
                >
                    <img src={firstPage} alt="viewArrat" className="p-1" />
                </button>
            );
        },
        LastPageLink: (options) => {
            return (
                <button
                    type="button"
                    className={classNames(options.className, "border-round")}
                    onClick={options.onClick}
                    disabled={options.disabled}
                >
                    <img
                        src={firstPage}
                        alt="viewArrat"
                        className="p-1 "
                        style={{ transform: "rotate(180deg)" }}
                    />
                </button>
            );
        },
        CurrentPageReport: (options) => {
            return (
                <span
                    style={{
                        color: "var(--text-color)",
                        userSelect: "none",
                        width: "50px",
                        textAlign: "center",
                    }}
                >
                    {options.first} of {options.totalRecords}
                </span>
            );
        },
    };

    const getBgcolor = (stage) => {
        console.log('stage: ', stage)
        switch (stage) {
            case "1":
                return "#E6F4ED";
            case "2":
                return "#FCE8E6";
            case "3":
                return "#FCE8E6 ";
            case "4":
                return "#E6F4ED ";
            case "5":
                return "#E6F4ED ";
            case "6":
                return "#E6F4ED ";
            default:
                return "#E6E6E6";
        }
    };

    const getColor = (stage) => {
        switch (stage) {
            case "1":
                return "#046938";
            case "2":
                return "#A01200";
            case "3":
                return "#A01200";
            case "4":
                return "#046938";
            case "5":
                return "#046938";
            case "6":
                return "#046938";
            default:
                return "black";
        }
    };

    return (
        <div>
            <div className="">
                <div className="p-3 border-bottom header-border-radius d-flex align-items-center justify-content-between px-4 flex-wrap">
                    <div className="d-flex gap-4 align-items-center fw-bold font-noto-sans">
                        <span className="font-noto-sans fs-14 text-black fw-500">
                            Design
                        </span>
                        {/* <div className='rounded-pill py-1 px-3 fs-12 bg-color-gray'>Active</div> */}
                    </div>
                    {/* <div className="pagination-style-top">
            <div className="">
              <Paginator
                template={template1}
                first={first}
                rows={1}
                totalRecords={5}
                onPageChange={onPageChange}
              />
            </div>
          </div> */}
                    <p className="font-noto-sans fw-bold fs-14 text-nowrap mb-0">
                        Total: {designDetails?.length}
                    </p>

                    <div className="d-flex align-items-center gap-3">
                        <button
                            className="rounded-pill px-4 bg-transparent h-36 border-black text-black font-noto-sans fw-bold fs-14 text-nowrap"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModalAllReturn"
                        >
                            Return all
                        </button>
                        <button
                            className="rounded-pill px-4 bg-color-black h-36 border-black text-white font-noto-sans fw-bold fs-14"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModalAll"
                        >
                            Approve All
                        </button>

                        {/* return all */}

                        <div
                            className="modal fade"
                            id="exampleModalAllReturn"
                            tabIndex="-1"
                            aria-labelledby="exampleModalAllReturnLabel"
                            aria-hidden="true"
                        >
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header border-0">
                                        <h5
                                            className="font-noto-sans fw-bold fs-20 mb-0 text-black"
                                            id="exampleModalReturnLabel"
                                        >
                                            Return this designs?
                                        </h5>
                                    </div>
                                    <div className="modal-body font-noto-sans fw-500 pt-0 mb-2">
                                        Are you sure you want to return all designs?
                                    </div>
                                    <div className="modal-body font-noto-sans fw-500 pt-0 ">
                                        Please enter your reason for returning the design into the box below, indicating whether the submitter may fix a problem and resubmit.
                                    </div>
                                    <textarea
                                        value={reasonMsg}
                                        placeholder="Describe the reason"
                                        rows={4}
                                        className="font-noto-sans mx-3 p-2 rounded-3"
                                        onChange={(e) => setReasonMsg(e.target.value)}
                                    >
                                    </textarea>
                                    <div className="modal-footer mt-0 border-0">
                                        <button
                                            className="rounded-pill px-4 bg-transparent h-36 border-0 text-black font-noto-sans fw-bold fs-14 text-nowrap"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className="rounded-pill px-4 bg-color-black h-36 border-black text-white font-noto-sans fw-500 fs-14"
                                            data-bs-toggle="modal"
                                            data-bs-target="#exampleModalAllReturn"
                                            onClick={() => handleReturnAllDesign(2)}
                                        >
                                            Yes, return all
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* approve all */}

                        <div
                            className="modal fade"
                            id="exampleModalAll"
                            tabIndex="-1"
                            aria-labelledby="exampleModalAllLabel"
                            aria-hidden="true"
                        >
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header border-0">
                                        <h5
                                            className="font-noto-sans fw-bold fs-20 mb-0 text-black"
                                            id="exampleModalLabel"
                                        >
                                            Approve all designs?
                                        </h5>
                                    </div>
                                    <div className="modal-body font-noto-sans fw-500 pt-0 ">
                                        Are you sure you want to approve all designs?
                                    </div>
                                    <div className="modal-footer mt-0 border-0">
                                        <button
                                            className="rounded-pill px-4 bg-transparent h-36 border-0 text-black font-noto-sans fw-bold fs-14 text-nowrap"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className="rounded-pill px-4 bg-color-black h-36 border-black text-white font-noto-sans fw-500 fs-14"
                                            data-bs-toggle="modal"
                                            data-bs-target="#exampleModalAll"
                                            onClick={() => handleReturnAllDesign(1)}
                                        >
                                            Yes, approve all
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <img
                            src={closeIcon}
                            alt="no_image"
                            width={24}
                            onClick={backToReference}
                        />
                    </div>
                </div>
                <div className="d-flex justify-content-between flex-column flex-md-row">
                    <div className=" heigth-calc-56 overflow-auto w-100">
                        {/* <div className="w-100 ms-3 d-flex align-items-center h-100 justify-content-center">
              <CanvasComponent src={selectedImage?.imageUrl || frameImage} />
            </div> */}
                        <div className="d-flex flex-wrap gap-3 reference-grid">
                            {designDetails?.length > 0 && designDetails?.map((design, i) => {
                                const stageValue = Object.values(design?.stage)[0]
                                const stageKey = Object.keys(design?.stage)[0]
                                return (
                                    <div key={i} className="position-relative">
                                        <p
                                            className={`rounded-pill px-2 py-1 w-fit-content font-noto-sans fw-bold fs-14 position-absolute`}
                                            style={{ top: "8px", left: "8px", backgroundColor: getBgcolor(stageKey), color: getColor(stageKey) }}
                                        >
                                            {stageValue}
                                        </p>
                                        <img
                                            src={frameImage}
                                            alt="frameImage"
                                            className="reference-grid-image"
                                        />
                                        <div className='chat_bottom px-2 rounded' style={{ backgroundColor: "gray" }}>
                                            <div className='d-flex align-items-center gap-2'>
                                                <div className='d-flex align-items-center gap-1'>
                                                    <div>
                                                        <img src={chatBubble} alt="chatBubble" />
                                                    </div>
                                                    <div className='chat_bottom_text font-noto-sans'>0</div>
                                                </div>

                                                {/* <div className='d-flex align-items-center gap-1'>
                                      <div>
                                        <img src={assignment} alt="assignment" />
                                      </div>
                                      <div className='chat_bottom_text font-noto-sans'>3</div>
                                    </div> */}
                                            </div>
                                        </div>
                                    </div>

                                )
                            })}

                        </div>
                    </div>
                    <button className="sidebar-open-btn" onClick={() => setShowCommentSection(true)}>
                        <span></span>
                    </button>
                    <div className={`position-relative max-w-420px w-100 ${showCommentSection ? "d-block" : "d-none"}`}>
                        <div className=" w-100 bg-color-gray border-start heigth-calc-56 overflow-auto">
                            <button className="sidebar-close-btn" onClick={() => setShowCommentSection(false)}>
                                <span></span>
                            </button>
                            <div className={`${showCommentSection ? "d-block" : "d-none"}`}>
                                <CommentAndTask />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DesignGridForManager;
