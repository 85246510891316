import { Box, Skeleton } from '@mui/material';
import React from 'react';

const ProjectLoader = ({ height }) => {
    return (
        <div className='w-100'>
            <Box>
                <Skeleton
                    animation="wave"
                    height={height ? height : 36}
                />
            </Box>
        </div>
    );
}

export default ProjectLoader;
