import { configureStore } from '@reduxjs/toolkit';
import konvaReducer from './konvaSlice';

const store = configureStore({
  reducer: {
    konva: konvaReducer,
  },
});

export default store;
