import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { CircularProgress } from "@mui/material";
import DetailsLoader from "../../components/Loaders/DetailsLoader";
import BackIcon from "../../assets/icons/arrow-circle-left.svg";
import back from "../../assets/icons/chevron-right-solid.svg";
import prev from "../../assets/icons/chevron-left-solid.svg";
import { useAuthContext } from "../../context/useAuthContext";
import { useFilterContext } from "../../context/useFilterContext";
import { getCategoryLabel, getModelLabel } from "../../helpers/labels";

const DownloadContent = ({
  formValue,
  slidesPerView,
  isLoading,
  isSaving,
  handleDownload,
  navigateToEdit,
  similarImages,
  onSlideClick,
  isPopUp = "false",
  screen,
  showImageDetails,
  setShowImageDetails
}) => {
  const { permissions, initialLoading, areAPIsLoading, isDebug, } = useAuthContext();
  const { t } = useTranslation();
  const { filterTags } = useFilterContext();
  const navigate = useNavigate();
  const navigateToSearch = () => {
    navigate("/search");
  };
  const [isShowMore, setIsShowMore] = useState(false)
  const swiperRef = useRef(null);

  const goToNextSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      const swiper = swiperRef.current.swiper;
      if (!swiper.isEnd) { // Check if we are not at the last slide
        swiper.slideNext();
      }
    }
  };

  const goToPrevSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      const swiper = swiperRef.current.swiper;
      if (!swiper.isBeginning) { // Check if we are not at the first slide
        swiper.slidePrev();
      }
    }
  };



  const renderCategoryDetails = (label, items, field) => (
    <div className="my-2 d-flex align-items-start flex-wrap">
      <span
        style={{
          fontSize: "16px",
          color: "#707070",
          fontWeight: "bold",
          whiteSpace: "nowrap",
          width: "150px",
          flexShrink: 0,
        }}
      >
        {t(label)} :&nbsp;
      </span>
      <div style={{ display: "flex", flexWrap: "wrap", gap: "8px", flex: 1 }}>
        {items && items.length > 0 ? (
          items.map((item, index) => (
            <span
              key={index}
              className="checked tag-button d-flex justify-content-center align-items-center fs-16 text-overflow position-relative filter-tags"
              style={{
                width: "calc(25% - 8px)",
                margin: "4px 0",
              }}
            >
              <span className="text-truncate" style={{ pointerEvents: "none" }}>
                {t(getCategoryLabel(field, item, filterTags))}
              </span>
            </span>
          ))
        ) : (
          <span className="checked tag-button d-flex justify-content-center align-items-center fs-16 text-overflow position-relative text-truncate filter-tags">
            {t("None")}
          </span>
        )}
      </div>
    </div>
  );
  const renderModelDetails = (label, items, field) => (
    <div className="my-2 d-flex align-items-start flex-wrap">
      <span
        style={{
          fontSize: "16px",
          color: "#707070",
          fontWeight: "bold",
          whiteSpace: "nowrap",
          width: "150px",
          flexShrink: 0,
        }}
      >
        {t(label)} :&nbsp;
      </span>
      <div style={{ display: "flex", flexWrap: "wrap", gap: "8px", flex: 1 }}>
        {items && items.length > 0 ? (
          items.map((item, index) => (
            <span
              key={index}
              className="checked tag-button d-flex justify-content-center align-items-center fs-16 text-overflow position-relative text-truncate filter-tags"
              style={{
                width: "calc(25% - 8px)", // Adjusts width to fit four items per row with a gap
                margin: "4px 0",
              }}
            >
              {t(getModelLabel(field, item, filterTags))}
            </span>
          ))
        ) : (
          <span className="checked tag-button d-flex justify-content-center align-items-center fs-16 text-overflow position-relative text-truncate filter-tags">
            {t("None")}
          </span>
        )}
      </div>
    </div>
  );

  const renderModelFilters = () => {
    return formValue?.model_filters?.map((filter, index) => (
      <div key={index}>
        {renderModelDetails(
          filter.model_key_name,
          filter.model_options,
          filter.model_key_name
        )}
      </div>
    ));
  };

  const renderCTR = () => {
    if (
      !formValue.ctr?.length ||
      formValue.ctr[0] === null ||
      formValue.ctr[1] === null
    ) {
      return <span className="px-2 mt-1 text-white ctrspan">CTR: N/A</span>;
    }
    const [ctr0, ctr1] = formValue.ctr;
    const ctrText =
      ctr0.toFixed(3) === ctr1.toFixed(3)
        ? `${(ctr0 * 100).toFixed(2)}%`
        : ctr0.toFixed(2) === ctr1.toFixed(2)
          ? `${(ctr0 * 100).toFixed(2)}%`
          : `${(ctr0 * 100).toFixed(2)}% ~ ${(ctr1 * 100).toFixed(2)}%`;
    return <span>CTR: {ctrText}</span>;
  };

  return (
    <div>
      {isLoading || initialLoading || areAPIsLoading ? (
        <DetailsLoader />
      ) : (
        <>
          {showImageDetails && (
            <div className="back-btn">
              <img
                src={BackIcon}
                alt="backicon"
                onClick={() => setShowImageDetails(false)}
              ></img>
            </div>
          )}

          {!isPopUp && (
            <div className="back-btn">
              <img
                src={BackIcon}
                alt="backicon"
                onClick={navigateToSearch}
              ></img>
            </div>
          )}
          <div
            className="fs-12 bg-light-blue text-center text-white py-1 rounded"
            style={{ padding: "10px", width: "fit-content" }}
          >
            {renderCTR()}
          </div>
          <div className="d-flex mt-2 gap-4 flex-column flex-md-row">
            <div className="max-w-312 max-h-498 h-100 w-100">
              <img src={formValue?.image_url} alt="" className="w-100" title={formValue?.text_extraction} />
            </div>
            <div className="flex-wrapper w-100">
              <div
                className="d-flex gap-4 color-light-gray"
                style={{ fontSize: "14px" }}
              >
                <div className="d-flex">
                  <span>{t("size")}</span>
                  :&nbsp;
                  <span>{formValue?.image_size || "None"}</span>
                </div>
                <div className="d-flex">
                  <span>{t("file")}</span>:&nbsp;
                  <span>{formValue?.image_extension || "None"}</span>
                </div>
              </div>
              {renderCategoryDetails(
                "category_industry",
                formValue?.industry,
                "industry"
              )}
              {renderCategoryDetails(
                "category_delivery_content",
                formValue?.delivery_content,
                "delivery_content"
              )}
              {renderCategoryDetails(
                "category_delivery_type",
                formValue?.delivery_type,
                "delivery_type"
              )}
              {renderCategoryDetails(
                "category_image_type",
                formValue?.image_type,
                "image_type"
              )}
              {renderModelFilters()}
              <div style={{ display: isDebug ? "block" : "none" }}>
                <span className="filter-label" style={{ fontSize: "16px" }}>
                  {t("imageDescription")}
                </span>
                <p
                  className="mb-0 font-s-24"
                  style={{ wordWrap: "break-word" }}
                >
                  {formValue?.edit_description}
                </p>
              </div>
              <div >
                <span className="filter-label" style={{ fontSize: "16px" }}>
                  {t("extrectedText")}
                </span>
                <p
                  className="mb-0 font-s-24"
                  style={{ wordWrap: "break-word" }}
                >
                  {formValue?.text_extraction || t('no_text')}
                </p>
              </div>
              <div>
                <div className="d-flex flex-wrap gap-2 align-items-center border-dotted-bottom py-3">
                  {formValue?.edit_tags
                    ?.filter((tag) => tag.length > 1)
                    ?.sort((a, b) => a.localeCompare(b))
                    ?.map((tag, index) => (
                      <button
                        key={index}
                        className="rounded border-gray-solid bg-white text-truncate fs-16 w-100 max-w-120 color-light-gray"
                        title={tag}
                      >
                        {tag}
                      </button>
                    ))}
                </div>
              </div>
              {
                screen === "browseImageDetails" ?
                  (
                    <div>

                    </div>
                  )
                  :
                  (
                    <div className="d-flex justify-content-between w-100 align-items-center gap-2 flex-wrap mt-3">
                      <button
                        onClick={() => handleDownload(formValue.image_url)}
                        className="rounded-pill border-0 p-2 px-3 px-sm-5 pe-5 detailsbtn position-relative"
                        disabled={isSaving}
                        style={{ width: "217px" }}
                      >
                        {!isSaving ? t("downloadImage") : ""}
                        <React.Fragment>
                          {isSaving ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                        </React.Fragment>
                        <span
                          style={{
                            position: "absolute",
                            right: "18px",
                            top: "6px",
                          }}
                        >
                          {">"}
                        </span>
                      </button>

                      {permissions?.can_edit ? (
                        <button
                          className="rounded-pill border-0 p-2 max-w-170 w-100 position-relative text-dark bg-sky-blue"
                          onClick={navigateToEdit}
                          disabled={isSaving}
                        >
                          {t("edit")}
                          <span
                            style={{
                              position: "absolute",
                              right: "18px",
                              top: "6px",
                            }}
                          >
                            {">"}
                          </span>
                        </button>
                      ) : <></>}
                    </div>
                  )
              }

            </div>
          </div>
          {similarImages && similarImages.length > 1 && (
            // checked === false&&
            <div className="mt-4 position-relative">
              <div
                className="border-top mt-4"
                style={{ borderTop: "1px solid #E1E1E1" }}
              >
                <div className="my-3 d-flex justify-content-between">
                  <h6 style={{ color: "#707070", fontWeight: "500" }}>
                    {t("similarImages")}
                  </h6>
                  {/* {similarImages.length > 4 && (
                    <h6 style={{ color: "#707070", fontWeight: "500", cursor: "pointer" }} onClick={() => { isShowMore ? setIsShowMore(false) : setIsShowMore(true) }}>
                      {isShowMore ? t("showLess") : t("seeMore")}
                      {isShowMore ? (
                        <svg width="12" height="12" className="ms-2">
                          <path d="M6 9l-5-5h10z" />
                        </svg>
                      ) : (
                        <svg width="12" height="12" className="ms-2">
                          <path d="M6 3l5 5H1z" />
                        </svg>
                      )}
                    </h6>
                  )} */}
                </div>
              </div>
              {/* {!isShowMore ? ( */}
              <div>
                <Swiper
                  ref={swiperRef}
                  // modules={[Navigation]}
                  spaceBetween={20}
                  slidesPerView={slidesPerView}
                  navigation={{
                    nextEl: '.custom-next',
                    prevEl: '.custom-prev',
                  }}

                // pagination
                >
                  {similarImages?.map((template, index) => (
                    <SwiperSlide
                      key={index}
                      className="d-flex justify-content-center align-items-center align-self-center"
                      style={{ cursor: "pointer" }}
                      onClick={() => onSlideClick(template)}
                    // onClick={() => getImageDetails(template?.similar_image_id)}
                    >
                      <div
                        className={`p-1 rounded-8 d-flex flex-column align-items-center justify-content-center image-container ${formValue.image_id === template?.similar_image_id
                          ? "active_image"
                          : ""
                          }`}
                      >
                        <img
                          src={template?.image_url}
                          className="editImg"
                          alt="search-img"
                          loading="lazy"
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
                {similarImages && similarImages.length > slidesPerView && (
                  <div className="d-flex">
                    <button
                      className="custom-prev position-absolute top-50 start-0 rounded-circle d-flex align-items-center justify-content-center"
                      onClick={goToPrevSlide}
                    >
                      <img height={20} width={20} src={prev}></img>
                    </button>
                    <button
                      className="custom-next position-absolute top-50 end-0 rounded-circle d-flex align-items-center justify-content-center"
                      onClick={goToNextSlide}
                    >
                      <img style={{ color: 'white' }} height={20} width={20} src={back}></img>
                    </button>
                  </div>
                )}
              </div>
              {/* ) :
                (<div className="d-flex flex-wrap">
                  {similarImages?.map((template, index) => (
                    <div
                      key={index}
                      style={{
                        flex: "1", // Flexible width starting at 200px
                        cursor: "pointer",
                      }}
                      onClick={() => onSlideClick(template)}
                    >
                      <div
                        className={`p-1 rounded-8 d-flex flex-column align-items-center justify-content-center image-container ${formValue.image_id === template?.similar_image_id
                          ? "active_image"
                          : ""
                          }`}
                      >
                        <img
                          src={template?.image_url}
                          className="editImg img-fluid"
                          alt="search-img"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  ))}
                </div>)} */}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default DownloadContent;