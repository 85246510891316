import { createContext, useContext, useEffect, useState } from "react";

// Create the context
const ReferenceContext = createContext(undefined);

// Create a custom hook to use the context
export function useReferenceContext() {
    const context = useContext(ReferenceContext);
    if (context === undefined) {
        throw new Error("useReferenceContext must be used within a ReferenceProvider");
    }
    return context;
}

// Create the provider component
export function ReferenceProvider({ children }) {
    const [referencesList, setReferencesList] = useState([]);
    // const [golbalProjectId, setGolbalProjectId] = useState();

    const [miroValue, setMiroValue] = useState("");

    const [miroLink, setMiroLink] = useState("");
    const [boardId, setBoardId] = useState(0);
    // const [sheetId, setSheetId] = useState(0);
    const [sheetId, setSheetId] = useState(() => {
        const savedSheetId = localStorage.getItem('sheetId');
        try {
            return savedSheetId !== null ? JSON.parse(savedSheetId) : 0;
        } catch (e) {
            console.error("Error parsing sheetId from localStorage", e);
            return 0;
        }

    });

    useEffect(() => {
        // Save sheetId to local storage whenever it changes
        localStorage.setItem('sheetId', JSON.stringify(sheetId));
    }, [sheetId]);

    const [excalidrawLoading, setExcalidrawLoading] = useState(true);
    return (
        <ReferenceContext.Provider value={{
            referencesList,
            setReferencesList,
            miroValue,
            setMiroValue,
            miroLink,
            setMiroLink,
            excalidrawLoading,
            setExcalidrawLoading,
            boardId,
            setBoardId,
            sheetId,
            setSheetId
        }}>
            {children}
        </ReferenceContext.Provider>
    );
}
