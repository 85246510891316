import React, { createContext, useContext, useEffect, useState, useRef } from "react";
import { SOCKET_URL } from "../constants/envs";

const WebSocketContext = createContext();

export const useSocketContext = () => {
  return useContext(WebSocketContext);
};

export const WebSocketProvider = ({ children }) => {
  const [connection, setConnection] = useState(null);
  const [onMessage, setOnMessage] = useState(null);
  const [onError, setError] = useState(null);
  const reconnectInterval = useRef(null);

  const connectWebSocket = () => {
    const newConnection = new WebSocket(SOCKET_URL);

    newConnection.onopen = () => {
      console.log("WebSocket connection established");
      setConnection(newConnection);
      if (reconnectInterval.current) {
        clearInterval(reconnectInterval.current);
        reconnectInterval.current = null;
      }
    };

    newConnection.onclose = (error) => {
      console.error("Connection Closed!", error);
      setConnection(null);
      if (!reconnectInterval.current) {
        reconnectInterval.current = setInterval(connectWebSocket, 5000); // Try to reconnect every 5 seconds
      }
    };

    newConnection.onmessage = (message) => {
      if (message.data.indexOf("{") >= 0) {
        setOnMessage(JSON.parse(message.data));
      } else {
        setOnMessage(message.data);
      }
    };

    newConnection.onerror = (error) => {
      setError(error);
      console.error("Connection Error!", error);
    };
  };

  useEffect(() => {
    connectWebSocket();

    return () => {
      if (connection) {
        connection.close();
      }
      if (reconnectInterval.current) {
        clearInterval(reconnectInterval.current);
      }
    };
  }, []);

  return (
    <WebSocketContext.Provider value={{ connection, onMessage, onError }}>
      {children}
    </WebSocketContext.Provider>
  );
};
