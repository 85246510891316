import React, { useEffect, useState } from "react";
import checkCircle from "../../../assets/newicons/check_circle_new.svg";
import grayRing from "../../../assets/newicons/Ellipse 632.svg";
import redInfo from "../../../assets/newicons/info.svg";
import warning from "../../../assets/newicons/warning.svg";
import normalBlack from "../../../assets/newicons/Incomplete--normal.svg";
import { useTranslation } from "react-i18next";
import { Button, Tooltip } from "@mui/material";
import Timeline from "./Timeline";
import Close from '../../../assets/newicons/close.svg';
import { newGet, newPut } from "../../../API/axios";
import { set } from "react-hook-form";
import { format } from 'date-fns';
import { showAlert } from "./AlertService";

const ProjectStages = ({ createdProjectId, designDetails }) => {

  const { t, i18n } = useTranslation();
  const [timeline, setTimeLine] = useState(null);

  const [timeLineformValue, setTimeLineFormValue] = useState({
    start_date: null,
    design_submission_due: null,
    client_feedback_due: null,
    implementation_date: null,
    final_delivery_date: null

  })

  const [errors, setErrors] = useState({
    start_date: null,
    design_submission_due: null,
    client_feedback_due: null,
    implementation_date: null,
    final_delivery_date: null
  })

  const getProjectTimeline = async () => {
    try {
      await newGet(`timeline/getTimeline?project_id=${createdProjectId}`).then((res) => {
        if(res?.status_code==1050){
          console.log("res get", res)
          setTimeLine(res?.result[0])
          if (res?.result[0] !== null) {
            setTimeLineFormValue({
              start_date: res?.result[0]?.start_date ? format(res?.result[0]?.start_date, 'MM-dd-yyyy') : null,
              design_submission_due: res?.result[0]?.design_submission_due ? format(res?.result[0]?.design_submission_due, 'MM-dd-yyyy') : null,
              client_feedback_due: res?.result[0]?.client_feedback_due ? format(res?.result[0]?.client_feedback_due, 'MM-dd-yyyy') : null,
              implementation_date: res?.result[0]?.implementation_date ? format(res?.result[0]?.implementation_date, 'MM-dd-yyyy') : null,
              final_delivery_date: res?.result[0]?.final_delivery_date ? format(res?.result[0]?.final_delivery_date, 'MM-dd-yyyy') : null
            })
          }
        }
      })
    }
    catch (error) {
      console.log(error)
    }

  }

  const updateProjectTimeline = async () => {
    const formData = {
      project_id: createdProjectId,
      start_date: timeLineformValue?.start_date,
      design_submission_due: timeLineformValue?.design_submission_due,
      client_feedback_due: timeLineformValue?.client_feedback_due,
      implementation_date: timeLineformValue?.implementation_date,
      final_delivery_date: timeLineformValue?.final_delivery_date,
    }
    // const formattedDate = date ? format(date, 'MM-dd-yyyy') : null;

    console.log("formData", formData)
    try {
      const res = await newPut(`timeline/updateTimeline`, formData)

      // .then((res) => {
        console.log("res update", res)
        if (res?.status_code === 1043) {
          showAlert('TimeLine Updated Succesfully', 'success');
          getProjectTimeline();
        }
        getProjectTimeline();

      //}
    //)
    }
    catch (error) {
      console.log(error)
    }
  }

  const handleDateChange = (date) => {
    const formattedDate = date ? format(date, 'MM-dd-yyyy') : null;
    return formattedDate;
  }

  useEffect(() => {
    getProjectTimeline();
  }, [])


  const calculateStepperPercentages = () => {

    const start_date = new Date(timeline?.start_date);
    const design_submission_due = new Date(timeline?.design_submission_due);
    const client_feedback_due = new Date(timeline?.client_feedback_due);
    const implementation_date = new Date(timeline?.implementation_date);
    const final_delivery_date = new Date(timeline?.final_delivery_date);
    let stoppingPoints = [start_date, design_submission_due, client_feedback_due ,implementation_date, final_delivery_date];

    let segmentLengths = [];
    for (let i = 0; i < stoppingPoints.length - 1; i++) {
        segmentLengths.push(stoppingPoints[i + 1] - stoppingPoints[i]);
    }

    const totalLength = stoppingPoints[stoppingPoints.length - 1] - stoppingPoints[0];
    let percentages = segmentLengths.map(length => Math.round((length / totalLength) * 100 * 100) / 100)
    const projectStepper = {
      gridTemplateColumns: `${percentages[0]}% ${percentages[1]}% ${percentages[2]}% ${percentages[3]}%`
    }
    return projectStepper;
  }

  const findTimeDifference = () => {
    // if (timeline !== null) {
    //   const start_date = new Date();
    //   let due_date = new Date();
    //   if (timeline?.final_delivery_date !== null) {
    //     due_date = new Date(timeLineformValue?.final_delivery_date);
    //   }
    //   else if (timeline?.implementation_date !== null && timeline?.final_delivery_date === null) {
    //     due_date = new Date(timeLineformValue?.implementation_date);
    //   }
    //   else if (timeline?.client_feedback_due !== null && timeline?.implementation_date === null && timeline?.final_delivery_date === null) {
    //     due_date = new Date(timeLineformValue?.client_feedback_due);
    //   }
    //   else if (timeline?.design_submission_due !== null && timeline?.client_feedback_due === null && timeline?.implementation_date === null && timeline?.final_delivery_date === null) {
    //     due_date = new Date(timeLineformValue?.design_submission_due);
    //   }
    //   const timeDifference = due_date - start_date;
    //   const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
    //   return Math.round(daysDifference);
    // }
    if (timeline !== null) {
      const start_date = new Date();
      let due_date = new Date();
      if (timeline?.final_delivery_date !== null || timeline?.implementation_date !== null || timeline?.client_feedback_due !== null || timeline?.design_submission_due !== null) {
        due_date = new Date(timeLineformValue?.design_submission_due);
      }
      // else if (timeline?.implementation_date !== null && timeline?.final_delivery_date === null) {
      //   due_date = new Date(timeLineformValue?.implementation_date);
      // }
      // else if (timeline?.client_feedback_due !== null && timeline?.implementation_date === null && timeline?.final_delivery_date === null) {
      //   due_date = new Date(timeLineformValue?.client_feedback_due);
      // }
      // else if (timeline?.design_submission_due !== null && timeline?.client_feedback_due === null && timeline?.implementation_date === null && timeline?.final_delivery_date === null) {
      //   due_date = new Date(timeLineformValue?.design_submission_due);
      // }
      const timeDifference = due_date - start_date;
      const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
      return Math.round(daysDifference);
    }
  }

  const handleTimeLine = (timeline) => {
    const currentDate = new Date();
  
    const calculateDaysDifference = (date) => {
      const targetDate = new Date(date);
      const differenceInTime = targetDate - currentDate;
      const differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));
      return differenceInDays;
    };
  
    if (timeline !== null) {
      if (timeline?.design_submission_due) {
        const days = calculateDaysDifference(timeline.design_submission_due);
  
        if (days > 0) {
          return (
            <>
              Design submission is due in{' '}
              <span className="fw-bold">{days} day
              {days > 1 ? 's' : ''}</span>
            </>
          );
        } else if (days === 0) {
          return (
            <>
              Design submission is due <span className="fw-bold">today</span>
            </>
          );
        } else {
          return (
            <>
              Design submission is{' '}
              <span className="fw-bold">{Math.abs(days)} day
              {Math.abs(days) > 1 ? 's' : ''} past due date </span>
            </>
          );
        }
      }
    }
  
    return "No timeline data available";
  };
  

  // const handleTimeLine = () => {
  //   // if (timeline !== null) {
  //   //   if (timeline?.final_delivery_date !== null) {
  //   //     return "Final Delivery Date"
  //   //   }
  //   //   else if (timeline?.implementation_date !== null && timeline?.final_delivery_date === null) {
  //   //     return "Implementation Date"
  //   //   }
  //   //   else if (timeline?.client_feedback_due !== null && timeline?.implementation_date === null && timeline?.final_delivery_date === null) {
  //   //     return "Client Feedback Due"
  //   //   }
  //   //   else if (timeline?.design_submission_due !== null && timeline?.client_feedback_due === null && timeline?.implementation_date === null && timeline?.final_delivery_date === null) {
  //   //     return "Design Submission Due"
  //   //   }
  //   // }

  //   if (timeline !== null) {
  //     if (timeline?.final_delivery_date !== null || timeline?.implementation_date !== null || timeline?.client_feedback_due !== null || timeline?.design_submission_due !== null) {
  //       return "Design Submission Due"
  //     }
  //     // else if (timeline?.implementation_date !== null && timeline?.final_delivery_date === null) {
  //     //   return "Implementation Date"
  //     // }
  //     // else if (timeline?.client_feedback_due !== null && timeline?.implementation_date === null && timeline?.final_delivery_date === null) {
  //     //   return "Client Feedback Due"
  //     // }
  //     // else if (timeline?.design_submission_due !== null && timeline?.client_feedback_due === null && timeline?.implementation_date === null && timeline?.final_delivery_date === null) {
  //     //   return "Design Submission Due"
  //     // }
  //   }
  // }

  console.log("timeline", timeline)

  console.log("timeLineformValue",timeLineformValue)
  return (
    <div className="p-lg-4 mt-3 p-md-3 p-3 bg-white bora_24 bg_white_padding">
      <div className="d-flex justify-content-between align-items-center">
        <h6 className="mb-0 text_formate pb-lg-4 pb-md-3 pb-2">{t("projectStages")}</h6>
        {( timeline !== null|| timeline !== undefined)  &&
          <p className="font-noto-sans" style={{ border: "1px solid gray", fontWeight: "600", borderRadius: "20px", padding: "8px", cursor: "pointer",display: timeline !== null && timeline?.start_date !== null ? "inline-block" : "none", fontSize: "14px", transition: "background-color 0.3s", }}
            data-bs-toggle="modal" data-bs-target="#timeline">
            Update Timeline</p>}

      </div>
      {timeline !== null && timeline != undefined &&
        <>
          {timeline?.start_date !== null &&
          
            <p className="font-noto-sans fs-20">{handleTimeLine(timeline)}
            
            
            
            </p>
            

          }

          {timeline?.start_date !== null &&
          
            <div className="project-stepper" style={calculateStepperPercentages()}>
              <div style={{ position: 'relative', height: "4px", backgroundColor: "#cccccc" }}>
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow="70"
                    aria-valuemin="0"
                    // aria-valuemax="100"
                  // style={{ width: `${(timeline?.start_date ? "100" : "0")}%`, backgroundColor: "black" }}
                  >
                    {timeline?.design_submission_due !== null &&
          
                      <div style={{ position: "absolute", top: "50%", right: "0", transform: "translate(50%, -50%)", width: "10px", height: "10px", backgroundColor: "#cccccc", borderRadius: "50%" }}>
                        <Tooltip  title={<div>
                      <span>Design Submission Due <br></br> {handleDateChange(timeline?.design_submission_due)}</span>
                    </div>} slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [0, -12] } }] } }} placement="bottom" arrow>
                      <p style={{ top:"10px" , marginTop:"-20px", position:"relative"}}  className="approver-icon mb-0">
                        <span>{"Design Submission Due".slice(0, 2)?.toUpperCase()}</span>
                      </p>
                    </Tooltip> 
                        
                       </div>
                    }
                  </div>
                </div>
              </div>

              {/* <div style={{width:"10px", height:"10px", backgroundColor:"grey", borderRadius:"50%"}}></div> */}
              <div style={{ position: 'relative', height: "4px ", backgroundColor: "#cccccc " }} >
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow="70"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  // style={{ width: "20%", backgroundColor: "black" }}
                  >
                    {timeline?.client_feedback_due !== null &&
                      <div style={{ position: "absolute", top: "50%", right: "0", transform: "translate(50%, -50%)", width: "10px", height: "10px", backgroundColor: "#cccccc", borderRadius: "50%" }}>

                      <Tooltip title={<div>
                      <span>Client Feedback Due <br></br> {handleDateChange(timeline?.client_feedback_due)}</span>
                    </div>} slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [0, -12] } }] } }} placement="bottom" arrow>
                      <p style={{ top:"10px" , marginTop:"-20px", position:"relative"}}  className="approver-icon mb-0">
                        <span>{"Client Feedback Due".slice(0, 2)?.toUpperCase()}</span>
                      </p>
                    </Tooltip>

                        
                      </div>
                    }
                  </div>
                </div>
              </div>
              <div style={{ position: 'relative', height: "4px ", backgroundColor: "#cccccc " }}>
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow="70"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  // style={{ width: "20%", backgroundColor: "black" }}
                  >
                    {timeline?.implementation_date !== null &&
                      <div style={{ position: "absolute", top: "50%", right: "0", transform: "translate(50%, -50%)", width: "10px", height: "10px", backgroundColor: "#cccccc", borderRadius: "50%" }}>
                       <Tooltip title={<div>
                      <span>Implementation Date <br></br> {handleDateChange(timeline?.implementation_date)}</span>
                    </div>} slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [0, -12] } }] } }} placement="bottom" arrow>
                      <p style={{ top:"10px" , marginTop:"-20px", position:"relative"}}  className="approver-icon mb-0">
                        <span>{"Implementation Date".slice(0, 2)?.toUpperCase()}</span>
                      </p>
                    </Tooltip> 
                      </div>
                    }
                  </div>
                </div>
              </div>


              <div style={{ position: 'relative', height: "4px ", backgroundColor: "#cccccc " }}>
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow="70"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  // style={{ width: "20%", backgroundColor: "black" }}
                  >
                    {timeline?.final_delivery_date !== null &&
                      <div style={{ position: "absolute", top: "50%", right: "0", transform: "translate(50%, -50%)", width: "10px", height: "10px", backgroundColor: "#cccccc", borderRadius: "50%" }}>
                       <Tooltip title={<div>
                      <span>Final Delivery Date <br></br> {handleDateChange(timeline?.final_delivery_date)}</span>
                    </div>} slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [0, -12] } }] } }} placement="bottom" arrow>
                      <p style={{ top:"10px" , marginTop:"-20px", position:"relative"}}  className="approver-icon mb-0">
                        <span>{"Final Delivery Date".slice(0, 2)?.toUpperCase()}</span>
                      </p>
                    </Tooltip> 
                      </div>
                    }
                  </div>
                </div>
              </div>

              {/* {timeline?.design_submission_due !== null &&} */}
              {/* <div style={{ position: 'relative', height: "4px ", backgroundColor: "#cccccc " }}>
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow="70"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  // style={{ width: "20%", backgroundColor: "black" }}
                  >
                    <div style={{ position: "absolute", top: "0%", right: "0", transform: "translate(50%, -50%)", width: "10px", height: "10px", backgroundColor: "#cccccc", borderRadius: "50%" }}></div>
                  </div>
                </div>
              </div> */}

            </div>
          }
          {timeline !== null &&
            <div className="d-flex justify-content-between align-content-center mt-3">

              {timeline?.start_date !== null &&
              <div style={{ marginTop:"-18px",marginLeft : "-4px",position: "relative", top: "50%", right: "0", transform: "translate(50%, -50%)", width: "10px", height: "10px", backgroundColor: "#cccccc", borderRadius: "50%" }}>
                {timeline?.start_date===timeline?.client_feedback_due||timeline?.start_date===timeline?.design_submission_due||timeline?.start_date===timeline?.implementation_date||timeline?.start_date===timeline?.final_delivery_date?
                <Tooltip title={<div>
                  <span>Start Date <br></br> {handleDateChange(timeline?.start_date)}</span>
                </div>} slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [0, -12] } }] } }} placement="bottom" arrow>
                  <p  style={{  marginTop:"-8px",marginLeft : "-24px", position:"relative"}} className="approver-icon mb-0">
                    <span>{"Start Date".slice(0, 2)?.toUpperCase()}</span>
                  </p>
                </Tooltip>
                :   <Tooltip title={<div>
                  <span>Start Date <br></br> {handleDateChange(timeline?.start_date)}</span>
                </div>} slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [0, -12] } }] } }} placement="bottom" arrow>
                  <p  style={{  marginTop:"-8px", marginLeft:"-4px", position:"relative"}} className="approver-icon mb-0">
                    <span>{"Start Date".slice(0, 2)?.toUpperCase()}</span>
                  </p>
                </Tooltip>}
                
                {/* <p className="font-noto-sans fs-12 fw-bold mb-0">Start date</p>
                  <p className="fs-16 font-noto-sans fw-600 mt-2 ">
                    {handleDateChange(timeline?.start_date)}
                  </p> */}
              </div>
              }
          
              {timeline?.design_submission_due !== null &&
                <div className="text-center">
                  {/* <Tooltip title={<div>
                      <span>Design Submission Due <br></br> {handleDateChange(timeline?.design_submission_due)}</span>
                    </div>} slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [0, -12] } }] } }} placement="bottom" arrow>
                      <p  className="approver-icon mb-0">
                        <span>{"Design Submission Due".slice(0, 2)?.toUpperCase()}</span>
                      </p>
                    </Tooltip> */}

                  {/* <p className="font-noto-sans fs-12 fw-bold mb-0">
                    Design Submission Due
                  </p>
                  <p className="fs-16 font-noto-sans fw-600 mt-2 ">
                    {handleDateChange(timeline?.design_submission_due)}
                  </p> */}
              </div>
              }
          
              {timeline?.client_feedback_due !== null &&
              <div className="text-center" style={{ position: "relative", left: "18px" }}>
                {/* <Tooltip title={<div>
                      <span>Client Feedback Due <br></br> {handleDateChange(timeline?.client_feedback_due)}</span>
                    </div>} slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [0, -12] } }] } }} placement="bottom" arrow>
                      <p  className="approver-icon mb-0">
                        <span>{"Client Feedback Due".slice(0, 2)?.toUpperCase()}</span>
                      </p>
                    </Tooltip> */}
                  {/* <p className="font-noto-sans  fw-bold fs-12 mb-0">
                    Client Feedback Due
                  </p>
                  <p className="fs-16 font-noto-sans fw-600 mt-2 ">
                    {handleDateChange(timeline?.client_feedback_due)}
                  </p> */}
              </div>
            }
          
              {timeline?.implementation_date !== null &&
              <div className="text-center" style={{ position: "relative", left: "35px" }}>
                {/* <Tooltip title={<div>
                      <span>Implementation Date <br></br> {handleDateChange(timeline?.implementation_date)}</span>
                    </div>} slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [0, -12] } }] } }} placement="bottom" arrow>
                      <p  className="approver-icon mb-0">
                        <span>{"Implementation Date".slice(0, 2)?.toUpperCase()}</span>
                      </p>
                    </Tooltip> */}
                  {/* <p className="font-noto-sans  fw-bold fs-12 mb-0">
                    Implementation Date
                  </p>
                  <p className="fs-16 font-noto-sans fw-600 mt-2 ">
                    {handleDateChange(timeline?.implementation_date)}
                  </p> */}
              </div>
              }
          
              {timeline?.final_delivery_date !== null &&
                <div>
                  {/* <Tooltip title={<div>
                      <span>Final Delivery Date <br></br> {handleDateChange(timeline?.final_delivery_date)}</span>
                    </div>} slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [0, -12] } }] } }} placement="bottom" arrow>
                      <p  className="approver-icon mb-0">
                        <span>{"Final Delivery Date".slice(0, 2)?.toUpperCase()}</span>
                      </p>
                    </Tooltip> */}
                  {/* <p className="font-noto-sans  fw-bold fs-12 mb-0">
                    Final Delivery Date
                  </p>
                <p className="fs-16 font-noto-sans fw-600 mt-2 " style={{ position: "relative", left: "28px" }}>
                  {handleDateChange(timeline?.final_delivery_date)}
                </p> */}
              </div>
              }

          </div>
          }
        </>
      }
      {/* <p className="light-red-bg py-2 mt-2 px-3 rounded font-noto-sans fs-14 fw-500">
        The submitted design(s) require revision. Please make the necessary
        updates and resubmit for approval.
      </p> */}

      {/* <div className="p-lg-4 mt-3 p-md-3 p-3 bg-white bora_24 bg_white_padding"> */}
      {/* <h6 className="mb-0 text_formate pb-lg-4 pb-md-3 pb-2">{t("projectStages")}</h6> */}

      {(timeline === null || timeline?.start_date === null || timeline === undefined) && (
        <div className="text-center">
          <p className='font-noto-sans' style={{ fontSize: "20px", fontWeight: "700", margin: "0px" }}> {t("timeLineText1")} </p>
          <p className='font-noto-sans' style={{ fontSize: "20px", fontWeight: "700", }}> {t("timeLineText2")}</p>
          <p className="font-noto-sans" style={{ border: "1px solid gray", fontWeight: "600", borderRadius: "20px", padding: "8px", cursor: "pointer",display: timeline === null || timeline?.start_date === null || timeline === undefined ? "inline-block" : "none", fontSize: "16px", transition: "background-color 0.3s" }} onMouseEnter={(e) => e.target.style.backgroundColor = "#CBC3E3"}
            onMouseLeave={(e) => e.target.style.backgroundColor = "transparent"} data-bs-toggle="modal" data-bs-target="#timeline">
            {t("setUptimeLine")}
          </p>
        </div>
      )}

      {/* Timeline modal */}
      <div className="modal fade" id="timeline" tabIndex="-1" aria-labelledby="timelineLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content px-3">
            <div className="modal-header border-0">
              <div className='upload_design'>
              {timeline===null || timeline===undefined ||timeline?.start_date===null ? t("setUptimeLine") : t("updateTimeline")}
              </div>
              <div className='cursor_pointer' data-bs-dismiss="modal" >
                <img src={Close} alt='Close' />
              </div>
            </div>
            <div className="modal-body">
              <Timeline timeLineformValue={timeLineformValue} setTimeLineFormValue={setTimeLineFormValue} errors={errors} setErrors={setErrors} updateTimeline={updateProjectTimeline} />
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};
export default ProjectStages;







