import { t } from 'i18next';
import React from 'react';

const InviteUserForm = ({ users, setUsers, role, permissions, setRole, setPermissions }) => {

    const handleRoleSelection = (e) => {
        const { id } = e.target;
        setRole({ admin: id === 'admin', channel_manager: id === 'channel_manager', channel_user: id === 'channel_user' });
        // if (id === 'admin') {
        //     setPermissions({
        //         create_new_projects: true,
        //         view_projects: true,
        //         approve_projects: true,
        //         close_projects: true,
        //         add_comments: true,
        //         addUsers_to_watchList: true,
        //         reAssign_projects: true,
        //         assign_projects_to_user: true,
        //         change_date: true
        //     });
        // } else if (id === 'channel_manager') {
        //     setPermissions({
        //         create_new_projects: true,
        //         view_projects: true,
        //         approve_projects: true,
        //         close_projects: true,
        //         add_comments: true,
        //         addUsers_to_watchList: true,
        //         reAssign_projects: true,
        //         assign_projects_to_user: true,
        //         change_date: true
        //     });
        // } else {
        //     setPermissions({
        //         create_new_projects: true,
        //         view_projects: true,
        //         approve_projects: false,
        //         close_projects: false,
        //         add_comments: true,
        //         addUsers_to_watchList: false,
        //         reAssign_projects: true,
        //         assign_projects_to_user: false,
        //         change_date: false
        //     });
        // }
    };

    const handlePermissions = (e) => {
        const { name, checked } = e.target
        setPermissions({ ...permissions, [name]: checked })
    }

    return (
        <div>
            <div className="modal-body">
                <form>
                    <div className="mb-1">
                        <div htmlFor="recipient-name" className="col-form-label">Users</div>
                        <input type="text" className="form-control" id="recipient-name"
                            value={users}
                            onChange={(e) => setUsers(e.target.value)}
                            placeholder='Email, comma separated'
                            style={{ borderRadius: "8px", height: '48px' }} />
                    </div>
                </form>
                {/* Role  */}
                <div className='role my-3'>
                    <div className='role_title font-noto-sans'>
                        {t('selectUserRole')}
                    </div>
                    {role && role?.map((data) => (
                        <div className="form-check radio_color my-3 cursor_pointer" key={"invite_" + data?.role_name}>
                            <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id={data?.role_name}
                                // {...register('role', { required: true })}
                                value={data.role_id}
                            />
                            <label
                                className="form-check-label role_title_name font-noto-sans"
                                htmlFor={data?.role_name}
                            >
                                {t(data?.role_name)}
                            </label>
                        </div>
                    ))}
                </div>

                {/* Role permission  */}

                <div className='role my-3'>
                    <div className='role_title font-noto-sans'>
                        {t('selectUserPermissions')}
                    </div>
                    <div className="form-check checkbox_color my-3 cursor_pointer">
                        {permissions && permissions?.map((data) => (
                            <div className="form-check checkbox_color my-3 cursor_pointer" key={"invite_" + data?.permission_name}>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id={"invite_permission_" + data?.permission_id}
                                    // {...register('permissions')}
                                    value={data.permission_id}
                                // checked={permissions.new_project}
                                />
                                <label
                                    className="form-check-label role_title_name font-noto-sans cursor_pointer"
                                    htmlFor={"invite_" + data?.permission_id}
                                >
                                    {t(data?.permission_name)}
                                </label>
                            </div>
                        ))}
                        <input className="form-check-input cursor_pointer" type="checkbox" id="invite_new_projects" name='create_new_projects'
                            checked={permissions?.create_new_projects}
                            disabled={role?.admin}
                            onChange={handlePermissions}
                        />
                        <label className="form-check-label role_title_name font-noto-sans cursor_pointer" htmlFor="invite_new_projects">
                            Create New Projects
                        </label>
                    </div>
                    <div className="form-check checkbox_color my-3 cursor_pointer">
                        <input className="form-check-input cursor_pointer" type="checkbox" id="invite_view_projects" name='view_projects'
                            checked={permissions?.view_projects}
                            disabled={role?.admin}
                            onChange={handlePermissions}
                        />
                        <label className="form-check-label role_title_name font-noto-sans cursor_pointer" htmlFor="invite_view_projects">
                            View Projects
                        </label>
                    </div>

                    {!role?.channel_user &&
                        <>
                            <div className="form-check checkbox_color my-3 cursor_pointer">
                                <input className="form-check-input cursor_pointer" type="checkbox" id="invite_approve_projects" name="approve_projects"
                                    checked={permissions?.approve_projects}
                                    disabled={role?.admin}
                                    onChange={handlePermissions}
                                />
                                <label className="form-check-label role_title_name font-noto-sans cursor_pointer" htmlFor="invite_approve_projects">
                                    Approve Projects
                                </label>
                            </div>
                            <div className="form-check checkbox_color my-3 cursor_pointer">
                                <input className="form-check-input cursor_pointer" type="checkbox" id="invite_close_projects" name='close_projects'
                                    checked={permissions?.close_projects}
                                    disabled={role?.admin}
                                    onChange={handlePermissions}
                                />
                                <label className="form-check-label role_title_name font-noto-sans cursor_pointer" htmlFor="invite_close_projects">
                                    Close Projects
                                </label>
                            </div>
                        </>
                    }
                    <div className="form-check checkbox_color my-3 cursor_pointer">
                        <input className="form-check-input cursor_pointer" type="checkbox" id="invite_add_comment" name='add_comments'
                            checked={permissions?.add_comments}
                            disabled={role?.admin}
                            onChange={handlePermissions}
                            value="true"
                        />
                        <label className="form-check-label role_title_name font-noto-sans cursor_pointer" htmlFor="invite_add_comment">
                            Add Comments
                        </label>
                    </div>
                    {!role?.channel_user &&
                        <>
                            <div className="form-check checkbox_color my-3 cursor_pointer">
                                <input className="form-check-input cursor_pointer" type="checkbox" id="invite_add_user_watch" name='addUsers_to_watchList'
                                    checked={permissions?.addUsers_to_watchList}
                                    disabled={role?.admin}
                                    onChange={handlePermissions}
                                />
                                <label className="form-check-label role_title_name font-noto-sans cursor_pointer" htmlFor="invite_add_user_watch">
                                    Add users to Watch List
                                </label>
                            </div>
                        </>
                    }
                    <div className="form-check checkbox_color my-3 cursor_pointer">
                        <input className="form-check-input cursor_pointer" type="checkbox" id="invite_reassign_projects" name='reAssign_projects'
                            checked={permissions?.reAssign_projects}
                            disabled={role?.admin}
                            onChange={handlePermissions}
                        />
                        <label className="form-check-label role_title_name font-noto-sans cursor_pointer" htmlFor="invite_reassign_projects">
                            Reassign Projects
                        </label>
                    </div>

                    {!role?.channel_user &&
                        <>
                            <div className="form-check checkbox_color my-3 cursor_pointer">
                                <input className="form-check-input cursor_pointer" type="checkbox" id="invite_assigm_projects_user" name='assign_projects_to_user'
                                    checked={permissions?.assign_projects_to_user}
                                    disabled={role?.admin}
                                    onChange={handlePermissions}
                                />
                                <label className="form-check-label role_title_name font-noto-sans cursor_pointer" htmlFor="invite_assigm_projects_user">
                                    Assign Projects to users
                                </label>
                            </div>
                            <div className="form-check checkbox_color my-3 cursor_pointer">
                                <input className="form-check-input cursor_pointer" type="checkbox" id="invite_date" name='change_date'
                                    checked={permissions?.change_date}
                                    disabled={role?.admin}
                                    onChange={handlePermissions}
                                />
                                <label className="form-check-label role_title_name font-noto-sans cursor_pointer" htmlFor="invite_date">
                                    Set/Change Target Date
                                </label>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    );
}

export default InviteUserForm;
