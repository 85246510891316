import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    layers: [
        {
            id: 'layer1',
            size: [],
            elements: [],
        },
    ],
};

const findLayer = (state, layerId) => state.layers.find(layer => layer.id === layerId);

const konvaSlice = createSlice({
    name: 'konva',
    initialState,
    reducers: {
        addLayer: (state, action) => {
            const layer = findLayer(state, action.payload.id);
            if (layer && action.payload.size) {
                layer.size.push(...action.payload.size);
            } else {
                state.layers.push({
                    id: action.payload.id,
                    elements: [],
                });
            }
        },
        removeLayer: (state, action) => {
            state.layers = state.layers.filter(layer => layer.id !== action.payload.id);
        },
        addElement: (state, action) => {
            const { layerId, element } = action.payload;
            const layer = findLayer(state, layerId);
            if (layer) {
                layer.elements.push(element);
            } else {
                console.error(`Layer with id ${layerId} not found`);
            }
        },
        updateElement: (state, action) => {
            const { layerId, elementId, newProps } = action.payload;
            const layer = findLayer(state, layerId);
            if (layer) {
                const element = layer.elements.find(el => el.id === elementId);
                if (element) {
                    element.props = {
                        ...element.props,
                        ...newProps,
                    };
                } else {
                    console.error(`Element with id ${elementId} not found in layer ${layerId}`);
                }
            } else {
                console.error(`Layer with id ${layerId} not found`);
            }
        },
        removeElement: (state, action) => {
            const { layerId, elementId } = action.payload;
            const layer = findLayer(state, layerId);
            if (layer) {
                layer.elements = layer.elements.filter(el => el.id !== elementId);
            } else {
                console.error(`Layer with id ${layerId} not found`);
            }
        },
        getListElement: (state, action) => {
            const { layerId, elementId } = action.payload;
            const layer = action.payload.layers.find(layer => layer.id === layerId);
            if (layer) {
                const element = layer.elements.find(el => el.id === elementId);
                if (element) {
                    return { ...element.props };
                } else {
                    console.error(`Element with id ${elementId} not found in layer ${layerId}`);
                }
            } else {
                console.error(`Layer with id ${layerId} not found`);
            }
            return null;
        },
        clearLayers: (state) => {
            state.layers = [{
                id: 'layer1',
                size: [],
                elements: [],
            },];
        }
    },
});

export const {
    addLayer,
    removeLayer,
    addElement,
    updateElement,
    removeElement,
    getListElement,
    clearLayers
} = konvaSlice.actions;

export default konvaSlice.reducer;
