import React from 'react';
import { Rect } from 'react-konva';

const DraggableRect = ({ element, layerId, handleDragEnd, handleGroupClick, handleTransformEnd }) => {
  return (
    <Rect
      {...element.props}
      draggable
      onDragEnd={(e) => handleDragEnd(e, layerId, element.id)}
      onClick={() => handleGroupClick(layerId, element.id)}
      onTransformEnd={(e) => handleTransformEnd(e, layerId, element.id)}
    />
  );
};

export default DraggableRect;