import React, { useEffect, useState } from 'react';
import '../settings.scss';
import { Button, Menu, MenuItem, Skeleton } from '@mui/material';
import { newDelete, newGet, newPut } from '../../../API/axios';
import { showAlert, showConfirmationDialog } from './AlertService';

import ProjectLoader from '../skeletonLoaders/ProjectLoader';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';


const UserSettingTabelCompo = ({ roles, departments, permissionsList }) => {
    const { t } = useTranslation()
    // const [selectedUsers, setSelectedUsers] = useState(new Set());
    // const [isAllSelected, setIsAllSelected] = useState(false);
    const [currentUserId, setCurrentUserId] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [users, setUsers] = useState([]);
    const permissions = permissionsList;
    const languages = ['English', 'Japanese'];
    const open = Boolean(anchorEl);
    const [error, setError] = useState('');
    const { register, handleSubmit, control, reset, formState: { errors }, setValue, getValue } = useForm({
        defaultValues: {
            phone_number: '',
            job_title: '',
            role_id: '',
            department_id: '',
            is_active: false,
            access_expiry_date: '',
            pref_language: 'English',
            user_image_url: '',
        },
    });

    const [loading, setLoading] = useState(false)
    // const selectedRole = watch('role');
    // const selectedPermissions = watch('permissions', []);

    // useEffect(() => {
    //     if (selectedRole === "1" || selectedRole === "2") {
    //         const permissionIds = permissions.map(permission => permission.permission_id + "");
    //         setValue("permissions", permissionIds);
    //     } else {
    //         setValue("permissions", []);
    //     }
    // }, [selectedRole, permissions]);

    useEffect(() => {
        onGetAllUser();
        // onGetRoles();
        // onGetPermissions();
    }, [])

    // const getMasterData = async () => {
    //     const data = await newGet('users/getMasterData').then((response) => {
    //         if (response && response.status_code == 200) {
    //             setRoles(response?.result?.roles?.result)
    //             setPermissions(response?.result?.permissions?.result)
    //             setDepartments(response?.result?.department?.result)
    //         }
    //     })
    // }

    // useEffect(() => {
    //     setIsAllSelected(users.length > 0 && selectedUsers.size === users.length);
    // }, [selectedUsers, users.length]);

    // const handleCheckboxChange = (userId) => {
    //     setSelectedUsers((prev) => {
    //         const newSelected = new Set(prev);
    //         if (newSelected.has(userId)) {
    //             newSelected.delete(userId); // Unselect if already selected
    //         } else {
    //             newSelected.add(userId); // Select the user
    //         }
    //         return newSelected;
    //     });
    // };

    const validateEmails = (value) => {
        if (!value) {
            return 'Emails are required';
        }

        const emails = value.split(',').map((email) => email.trim());

        const invalidEmails = emails.filter(
            (email) => !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)
        );

        if (invalidEmails.length) {
            return `Invalid email(s): ${invalidEmails.join(', ')}`;
        }

        return true;
    };

    // const handleSelectAll = () => {
    //     if (isAllSelected) {
    //         setSelectedUsers(new Set()); // Deselect all
    //     } else {
    //         setSelectedUsers(new Set(users.map(user => user.user_id))); // Select all
    //     }
    // };

    const handleClick = (event, userId) => {
        setAnchorEl(event.currentTarget);
        setCurrentUserId(userId);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    async function onUpadateUser(data) {

        setValue('phone_number', data?.phone_number || '');
        setValue('job_title', data?.job_title || '');
        setValue('role_id', data?.role[0]?.role_id || '');
        setValue('department_id', data?.department[0]?.department_id || '');
        setValue('is_active', data?.is_active || false);
        setValue('access_expiry_date', data?.access_expiry_date ? new Date(data?.access_expiry_date) : null);
        setValue('pref_language', data?.pref_language || 'English');
        setValue('user_image_url', data?.user_image_url || '');

        setCurrentUserId(data.user_id);
        setAnchorEl(null);

    }

    const handleModalClose = (resetValues = true) => {
        reset(); // Always reset the form
        if (resetValues) {
            setCurrentUserId(null);
        }
    };

    const addModalListener = (modalId, resetValues = true) => {
        const modalElement = document.getElementById(modalId);
        if (modalElement) {
            const handleModalHidden = () => handleModalClose(resetValues);

            modalElement.addEventListener("hidden.bs.modal", handleModalHidden);

            return () => {
                modalElement.removeEventListener("hidden.bs.modal", handleModalHidden);
            };
        }
    };

    useEffect(() => {
        const editRoleCleanup = addModalListener("edit_default_role");
        const editProfileCleanup = addModalListener("edit_profile");
        const inviteUsersCleanup = addModalListener("Invite_users", false); // Special handling for invite users
        const assignRoleCleanup = addModalListener("assign_role");

        // Cleanup all event listeners when component unmounts
        return () => {
            if (editRoleCleanup) editRoleCleanup();
            if (editProfileCleanup) editProfileCleanup();
            if (inviteUsersCleanup) inviteUsersCleanup();
            if (assignRoleCleanup) assignRoleCleanup();
        };
    }, []);

    async function onGetAllUser() {
        try {
            setLoading(true)
            const data = await newGet('users');
            setUsers(data.result)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    async function onDeleteRole(e, id) {
        e.preventDefault();
        setAnchorEl(null);
        try {
            const isDelete = await showConfirmationDialog(`${t("projectDeleteMsg")}`,
                "",
                "warning",
                `${t("no")}`,
                `${t("yes")}`)
            if (isDelete) {
                await newDelete(`users/deactivate/${id}`).then((response) => {
                    if (response) {
                        const updatedUsers = users.filter(user => user.user_id !== id);
                        setUsers(updatedUsers);
                    }
                });

            }
        } catch (error) {
        }
    }

    async function onEditRole(e) {
        e.preventDefault();
        try {
            // console.log('defaultRole: ', selectedRole);
            // console.log('selectedPermissions: ', selectedPermissions, formData);

        } catch (error) {
        }
    }

    const formatString = (str) => {
        const words = str.toLowerCase().split('_');
        const formattedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
        return formattedWords.join(' ');
    };

    const setUsersImageURLEmpty = (data) => {
        data.user_image_url = '';
        const updatedUsers = users.map(user =>
            user.user_id === data.user_id ? { ...user, user_image_url: '' } : user
        );
        setUsers(updatedUsers);
    }

    const profileUpdate = async (data) => {
        // e.preventDefault();
        console.log("data:- ", data);

        // await newPut('users/' + currentUserId, {
        //     ...data,
        //     role_id: parseInt(data?.role_id),
        //     department_id: parseInt(data?.department_id),
        //     is_active: !!data.is_active
        // }).then((response) => {
        //     if (response && response.status_code == 200) {
        //         const closeButton = document.querySelector("#edit_profile [data-bs-dismiss='modal']");
        //         if (closeButton) {
        //             closeButton.click();
        //             reset()
        //         }
        //         showAlert("User Updated Sucessfully", "success");
        //         const usersData = users;
        //         const updatedUsers = usersData.map(user =>
        //             user.user_id === currentUserId ? { ...user, ...formData } : user
        //         );
        //         setUsers(updatedUsers);
        //     }
        // });
    };

    return (
        <div style={{ maxHeight: 'calc(100vh - 28vh)', overflowY: 'auto' }}>
            {loading && (
                <>
                    <table className="table table-responsive table-hover">
                        <thead className='sticky_top'>
                            <tr>
                                {Array.from({ length: 7 }).map((_, i) => (
                                    <th key={i} className="text-center align-middle border-0">
                                        <ProjectLoader />
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {Array.from({ length: 10 }).map((_, i) => (
                                <tr key={i} className='border-bottom'>
                                    <td className='text-center align-middle border-0'>
                                        <div className='d-flex w-100 align-items-center gap-3'>
                                            <div>
                                                <Skeleton
                                                    animation="wave"
                                                    style={{ display: "flex", borderRadius: "50%", height: "50px", minWidth: "30px" }}
                                                />
                                            </div>
                                            <ProjectLoader />
                                        </div>
                                    </td>
                                    {Array.from({ length: 6 }).map((_, index) => (
                                        <td key={index} className="text-center align-middle border-0">
                                            <ProjectLoader />
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            )}

            {!loading && users.length > 0 && (
                <table className="table table-hover table-responsive">
                    <thead className='sticky-top bg-white'>
                        <tr>
                            {/* <th className='setting_td' scope="col">
                            <div className="form-check checkbox_color">
                                <input className="form-check-input" type="checkbox" checked={isAllSelected} onChange={handleSelectAll} />
                            </div>
                        </th> */}
                            <th className='setting_th' scope="col">
                                {t('user')}
                            </th>
                            <th className='setting_th' scope="col">
                                {t('email')}
                            </th>
                            <th className='setting_th' scope="col">
                                {t('phoneNumber')}
                            </th>
                            <th className='setting_th' scope="col">
                                {t('jobTitle')}
                            </th>
                            <th className='setting_th' scope="col">
                                {t('role')}
                            </th>
                            <th className='setting_th' scope="col">
                                {t('department')}
                            </th>
                            <th className='setting_th' scope="col">
                                {t('action')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.length > 0 && (users.map((data) => (
                            <tr key={"user" + data?.user_id}>
                                {/* <th className='setting_td' scope="row">
                                <div className="form-check checkbox_color">
                                    <input type="checkbox" className="form-check-input" checked={selectedUsers.has(data?.user_id)}
                                        onChange={() => handleCheckboxChange(data?.user_id)} />
                                </div>
                            </th> */}
                                <td className='setting_td font-noto-sans cursor_pointer'>
                                    <div className='d-flex align-items-center gap-2'>
                                        {data?.user_image_url ? (
                                            <img src={data?.user_image_url} alt="headerProfile" className="me-2" style={{ backgroundColor: "gray", borderRadius: "50%", height: "30px", width: "30px" }} onError={(e) => { setUsersImageURLEmpty(data) }} />
                                        ) : (
                                            <div className="me-2" style={{ backgroundColor: "gray", borderRadius: "50%", height: "30px", width: "30px", display: "flex", alignItems: "center", justifyContent: "center", color: "white" }}>
                                                {data?.username?.charAt(0).toUpperCase()}
                                            </div>
                                        )}
                                        <div>{data?.username}</div>
                                    </div>
                                </td>
                                <td className='setting_td font-noto-sans'>
                                    {data?.email}
                                </td>
                                <td className='setting_td font-noto-sans'>
                                    {data?.phone_number}
                                </td>
                                <td className='setting_td font-noto-sans'>
                                    {data?.job_title}
                                </td>
                                <td className='setting_td font-noto-sans cursor_pointer' data-bs-toggle="modal" data-bs-target="#assign_role" >
                                    {data?.role?.length > 0 ? t(data?.role[0].role_name) : ""}
                                </td>
                                <td className='setting_td font-noto-sans'>
                                    {data?.department?.length > 0 ? t(data?.department[0].department_name) : ""}
                                </td>
                                <td className='setting_td font-noto-sans cursor_pointer cursor_pointer'>
                                    {/* <i className="fa-solid fa-ellipsis-vertical"></i> */}
                                    <div className='comment-options'>
                                        <Button
                                            id="basic-button" style={{ width: '100%', height: "36px" }}
                                            aria-controls={open ? 'basic-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : "false"}
                                            onClick={(e) => { handleClick(e, data?.user_id) }}
                                            className='border-0'
                                        >
                                            <i className="fa-solid fa-ellipsis-vertical"></i>
                                        </Button>
                                        {/* {currentUserId === data?.user_id && ( */}
                                        <Menu
                                            id="basic-menu"
                                            anchorEl={anchorEl}
                                            open={open && currentUserId === data?.user_id}
                                            onClose={handleClose}
                                            MenuListProps={{
                                                'aria-labelledby': 'basic-button',
                                            }}
                                        >
                                            <MenuItem onClick={() => onUpadateUser(data)} data-bs-toggle="modal" data-bs-target="#edit_profile"> {t('edit')}</MenuItem>
                                            <MenuItem onClick={() => onUpadateUser(data)} data-bs-toggle="modal" data-bs-target="#edit_default_role"> {t('roleEdit')}</MenuItem>
                                            <MenuItem onClick={(e) => onDeleteRole(e, data?.user_id)}>{t('delete')}</MenuItem>
                                        </Menu>

                                    </div>
                                </td>
                            </tr>
                        ))
                        )}
                    </tbody>
                </table>
            )}

            {/* 1 modal  Role  */}
            <div
                className="modal fade"
                id="edit_default_role"
                tabIndex="-1"
                aria-labelledby="edit_default_roleLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <form onSubmit={(e) => { onEditRole(e) }}>
                            <div className="modal-body pb-0">
                                <div className="edit-default-role">{t('editDefaultRole')}</div>

                                {/* Role Selection */}
                                <div className="role my-3">
                                    <div className="role_title font-noto-sans pb-2">{t('selectUserRole')}</div>
                                    <div style={{ maxHeight: "28vh", overflowY: "auto" }}>
                                        {roles && roles.map((data) => (
                                            <div className="form-check radio_color my-3" key={"edit_role_" + data?.role_name}>
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    id={"edit_role_" + data.role_id}
                                                    // {...register('role', { required: true })}
                                                    value={data.role_id}
                                                />
                                                <label
                                                    className="form-check-label role_title_name font-noto-sans"
                                                    htmlFor={"edit_role_" + data?.role_id}
                                                >
                                                    {t(data?.role_name)}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                {/* Permissions */}
                                <div className="role my-3">
                                    <div className="role_title font-noto-sans pb-2">{t('selectUserPermissions')}</div>
                                    <div style={{ maxHeight: "28vh", overflowY: "auto" }}>
                                        {permissions && permissions.map((data) => (
                                            <div className="form-check checkbox_color my-3" key={"edit_permission_" + data?.permission_name}>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    name="permissions"
                                                    id={"edit_permission_" + data?.permission_id}
                                                    // {...register('permissions')}
                                                    value={data.permission_id}
                                                />
                                                <label
                                                    className="form-check-label role_title_name font-noto-sans"
                                                    htmlFor={"edit_permission_" + data?.permission_id}
                                                >
                                                    {t(data?.permission_name)}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            {/* Modal Footer */}
                            <div className="d-flex justify-content-end gap-4 p-3 pt-0">
                                <button
                                    type="button"
                                    className="cancel_modal_footer"
                                    data-bs-dismiss="modal"
                                // onClick={onClose}
                                >
                                    {t('cancel')}
                                </button>
                                <button type="submit" className="send_modal_footer">
                                    {t('save')}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {/* 2 modal Edit Default Role */}
            <div className="modal fade" id="Invite_users" tabIndex="-1" aria-labelledby="Invite_usersLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered ">
                    <div className="modal-content">
                        <div className='modal-header border-0'>
                            <div className="edit-default-role">
                                {t('editDefaultRole')}
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="mb-1 position-relative">
                                <div htmlFor="recipient-name" className="col-form-label">Users</div>
                                <input type="text" className={`form-control ${error ? 'is-invalid' : ''}`}
                                    placeholder="Email, comma separated"
                                    // {...register('emails', {
                                    //     required: 'Emails are required',
                                    //     validate: validateEmails,
                                    // })}
                                    style={{ borderRadius: "8px", height: "48px" }} />
                                {errors.emails && <div className="invalid-feedback">{errors.emails.message}</div>}

                                <div className="invite_user_dropdown">
                                    <div className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                        Member
                                    </div>
                                    <div className="dropdown-menu border-secondary">
                                        <div className="dropdown-item border-0" >Action</div>
                                        <div className="dropdown-item border-0" >Another action</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end gap-4 p-3">
                            <button type="button" className="cancel_modal_footer" > {t('cancel')}</button>
                            <button type="button" className="send_modal_footer" onClick={() => { onEditRole() }}>{t('send')}</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* 3 modal assign Role  */}
            <div className="modal fade" id="assign_role" tabIndex="-1" aria-labelledby="assign_roleLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="edit-default-role">
                                {t('assignRole')}
                            </div>
                            {/* Role  */}
                            <div className='role my-3'>
                                <div className='role_title font-noto-sans'>
                                    {t('selectUserRole')}
                                </div>
                                <div style={{ maxHeight: "28vh", overflowY: "auto" }}>
                                    {roles && roles.map((data) => (
                                        <div className="form-check radio_color my-3" key={"assign_role_" + data?.role_name}>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="flexRadioDefault"
                                                id={"assign_role_" + data.role_id}
                                                // {...register('role', { required: true })}
                                                value={data.role_id}
                                            />
                                            <label
                                                className="form-check-label role_title_name font-noto-sans"
                                                htmlFor={"assign_role_" + data?.role_id}
                                            >
                                                {t(data?.role_name)}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {/* Role permission  */}
                            <div className='role my-3'>
                                <div className='role_title font-noto-sans'>
                                    {t('selectUserPermissions')}
                                </div>
                                <div style={{ maxHeight: "28vh", overflowY: "auto" }}>
                                    {permissions && permissions.map((data) => (
                                        <div className="form-check checkbox_color my-3" key={"assign_permission_" + data?.permission_name}>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id={"assign_permission_" + data?.permission_id}
                                                // {...register('permissions')}
                                                value={data.permission_id}
                                            // checked={permissions.new_project}
                                            />
                                            <label
                                                className="form-check-label role_title_name font-noto-sans"
                                                htmlFor={"assign_permission_" + data?.permission_id}
                                            >
                                                {t(data?.permission_name)}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end gap-4 p-3">
                            <button type="button" className="cancel_modal_footer" data-bs-dismiss="modal"> {t('cancel')}</button>
                            <button type="button" className="send_modal_footer"> {t('save')}</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* 4 Model Edit User */}
            <div
                className="modal fade"
                id="edit_profile"
                tabIndex="-1"
                aria-labelledby="edit_profileLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <form className='overflow-auto' onSubmit={handleSubmit(profileUpdate)}>
                            <div className="modal-body pb-0">
                                <div className="edit-default-role">{t('editProfile')}</div>

                                <div className="role my-3">
                                    {/* Job Title */}
                                    <div className="mb-3">
                                        <label htmlFor="job_title" className="form-label">{t('jobTitle')}</label>
                                        <input
                                            type="text"
                                            className={`form-control ${errors.job_title ? 'is-invalid' : ''}`}
                                            id="job_title"
                                            {...register('job_title', { required: 'Job title is required.' })}
                                        />
                                        {errors.job_title && <div className="invalid-feedback">{errors.job_title.message}</div>}
                                    </div>

                                    {/* Phone Number */}
                                    <div className="mb-3">
                                        <label htmlFor="phone_number" className="form-label">  {t('phoneNumber')}</label>
                                        <input
                                            type="text"
                                            className={`form-control ${errors.phone_number ? 'is-invalid' : ''}`}
                                            id="phone_number"
                                            {...register('phone_number', {
                                                required: 'Phone number is required.',
                                                pattern: {
                                                    value: /^\d{12}$/,
                                                    message: 'Phone number must be exactly 12 digits.',
                                                },
                                                onChange: (e) => {
                                                    const sanitizedValue = e.target.value.replace(/\D/g, '');
                                                    setValue('phone_number', sanitizedValue);
                                                },
                                            })}
                                            maxLength="12"
                                            placeholder="Enter 12-digit phone number"
                                            required
                                        />
                                        {errors.phone_number && <div className="invalid-feedback">{errors.phone_number.message}</div>}
                                    </div>

                                    {/* Role ID Dropdown */}
                                    <div className="mb-3">
                                        <label htmlFor="role_id" className="form-label">{t('role')}</label>
                                        <select
                                            className={`form-select ${errors.role_id ? 'is-invalid' : ''}`}
                                            id="role_id"
                                            {...register('role_id', { required: 'Role is required.' })}
                                        >
                                            <option value="">Select Role</option>
                                            {roles && roles.map((role) => (
                                                <option key={role.role_id} value={role.role_id}>
                                                    {t(role?.role_name)}
                                                </option>
                                            ))}
                                        </select>
                                        {errors.role_id && <div className="invalid-feedback">{errors.role_id.message}</div>}
                                    </div>

                                    {/* Department ID Dropdown */}
                                    <div className="mb-3">
                                        <label htmlFor="department_id" className="form-label">{t('department')}</label>
                                        <select
                                            className={`form-select ${errors.department_id ? 'is-invalid' : ''}`}
                                            id="department_id"
                                            {...register('department_id', { required: 'Department is required.' })}
                                        >
                                            <option value="">Select Department</option>
                                            {departments && departments.map((dept) => (
                                                <option key={dept.department_id} value={dept.department_id}>
                                                    {dept.department_name}
                                                </option>
                                            ))}
                                        </select>
                                        {errors.department_id && <div className="invalid-feedback">{errors.department_id.message}</div>}
                                    </div>

                                    {/* Is Active Checkbox */}
                                    <div className="form-check mb-3">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="is_active"
                                            {...register('is_active')}
                                        />
                                        <label className="form-check-label" htmlFor="is_active">
                                            {t('isActive')}
                                        </label>
                                    </div>

                                    {/* Access Expiry Date */}
                                    <div className="mb-3">
                                        <label htmlFor="access_expiry_date" className="form-label">
                                            {t('accessExpiryDate')}
                                        </label>
                                        <div className="position-relative" id="access_expiry_date">
                                            <Controller
                                                control={control}
                                                name="access_expiry_date"
                                                rules={{
                                                    required: 'Access expiry date is required.',
                                                    validate: (value) => {
                                                        const today = new Date();
                                                        today.setHours(0, 0, 0, 0);
                                                        const expiryDate = new Date(value);
                                                        expiryDate.setHours(0, 0, 0, 0);
                                                        return (
                                                            expiryDate >= today || 'Expiry date cannot be in the past.'
                                                        );
                                                    },
                                                }}
                                                render={({ field }) => (
                                                    <DatePicker
                                                        {...field}
                                                        selected={field.value ? new Date(field.value) : null}
                                                        onChange={(date) => field.onChange(date)}
                                                        placeholderText={t('mm/dd/yyyy')}
                                                        className={`input-border-gray w-100 h-50 bg-transparent rounded border-secondary p-2 from-input ${errors.access_expiry_date ? 'is-invalid' : ''
                                                            }`}
                                                        dateFormat="MM/dd/yyyy"
                                                        minDate={new Date()}
                                                    />
                                                )}
                                            />
                                        </div>
                                        {errors.access_expiry_date && (
                                            <div className="invalid-feedback">
                                                {errors.access_expiry_date.message}
                                            </div>
                                        )}
                                    </div>

                                    {/* Preferred Language Dropdown */}
                                    <div className="mb-3">
                                        <label htmlFor="pref_language" className="form-label">{t('preferredLanguage')}</label>
                                        <select
                                            className={`form-select ${errors.pref_language ? 'is-invalid' : ''}`}
                                            id="pref_language"
                                            {...register('pref_language', { required: 'Preferred language is required.' })}
                                        >
                                            <option value="">Select Language</option>
                                            {languages.map((lang) => (
                                                <option key={lang} value={lang}>
                                                    {lang}
                                                </option>
                                            ))}
                                        </select>
                                        {errors.pref_language && <div className="invalid-feedback">{errors.pref_language.message}</div>}
                                    </div>
                                </div>
                            </div>

                            {/* Modal Footer */}
                            <div className="d-flex justify-content-end gap-4 p-3 pt-0">
                                <button
                                    type="button"
                                    className="cancel_modal_footer"
                                    data-bs-dismiss="modal"
                                >
                                    {t('cancel')}
                                </button>
                                <button type="submit" className="send_modal_footer">
                                    {t('save')}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserSettingTabelCompo;
