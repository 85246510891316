import axios from "axios";
import { deleteCookie, getCookie, hasCookie, setCookie } from "cookies-next";
import { createContext, useContext, useEffect, useState } from "react";
import { newGet, newPost, post } from "../API/axios";
import { AUTH_API_ENDPOINTS } from "../constants/api-endpoints";
import { AUTHDOMAIN, LOGIN_CLIENT_ID } from "../constants/envs";
import { AUTH_CONSTANTS } from "../constants/response-keys";
import { getAllSearch } from "../helpers/url";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

const AuthContext = createContext(undefined);


export function useAuthContext() {

  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuthContext must be used within an AuthProvider");
  }
  return context;
}

export const authSessionKey = "_MICO_AUTH_TOKEN_KEY_";
export const userPermissionsKey = "_MICO_USER_PERMISSIONS_";
export const userSiteKey = "_MICO_USER_SITE_KEY";

export function AuthProvider({ children }) {
  const location = useLocation()
  const { i18n } = useTranslation();
  const [initialLoading, setInitialLoading] = useState(false);
  const [areAPIsLoading, setAreAPIsLoading] = useState(false);
  const [authInitialLoading, setAuthInitialLoading] = useState(true);
  const [isUserSettingsLoading, setIsUserSettingsLoading] = useState(true);
  const [userSettings, setUserSettings] = useState();

  const [isDebug, setIsDebug] = useState(false);
  const navigate = useNavigate();

  const getExistingSession = () => {
    const fetchedCookie = getCookie(authSessionKey)?.toString();
    if (!fetchedCookie) return undefined;
    else return { access_token: fetchedCookie };
  };

  const getExistingPermissions = () => {
    let fetchedPermissions;
    if (sessionStorage.getItem(userPermissionsKey)) {
      fetchedPermissions = JSON.parse(
        sessionStorage.getItem(userPermissionsKey)
      );
    }
    if (!fetchedPermissions) return undefined;
    else return fetchedPermissions;
  };

  const getSelectedSite = () => {
    const fetchedSite = JSON.parse(sessionStorage.getItem(userSiteKey));
    if (!fetchedSite) return undefined;
    else return fetchedSite;
  };

  const [user, setUser] = useState(getExistingSession());
  const [permissions, setPermissions] = useState(getExistingPermissions());
  const [selectedSite, setSelectedSite] = useState(getSelectedSite());

  const saveSession = (newUser) => {
    const expires = new Date();
    expires.setSeconds(
      expires.getSeconds() + newUser[AUTH_CONSTANTS.expiresInKey]
    );

    setCookie(authSessionKey, newUser[AUTH_CONSTANTS.tokenKey], {
      expires,
    });
    if (user) setUser(...user, newUser);
    else setUser(newUser);
  };

  const saveSelectedSite = (siteIndex, site) => {
    if (siteIndex === -1) {
      const siteIds = permissions?.access_sites.map((site) => site.site_id);

      let site = { site_id: siteIds, site_name: "All" };
      sessionStorage.setItem(userSiteKey, JSON.stringify(site));
      setSelectedSite(site);
      return;
    }
    sessionStorage.setItem(userSiteKey, JSON.stringify(site));
    setSelectedSite(site);
  };

  const savePermissions = (newPermissions) => {
    const siteIds = newPermissions?.access_sites.map((site) => site.site_id);

    let site = { site_id: siteIds, site_name: "All" };
    sessionStorage.setItem(userPermissionsKey, JSON.stringify(newPermissions));
    const allSites = newPermissions.main_site_id == 0 ? true : false;
    const siteDetails = allSites
      ? site
      : newPermissions.access_sites.find(
        (a) => a.site_id == newPermissions.main_site_id
      );

    i18n.changeLanguage(newPermissions?.language);
    sessionStorage.setItem(
      userSiteKey,
      JSON.stringify(siteDetails ? siteDetails : newPermissions.access_sites[0])
    );

    if (permissions) {
      const siteDetails = allSites
        ? site
        : permissions.access_sites.find(
          (a) => a.site_id == permissions.main_site_id
        );

      setPermissions(...permissions, ...newPermissions);
      setSelectedSite(siteDetails ? siteDetails : permissions.access_sites[0]);
    } else {
      const siteDetails = allSites
        ? site
        : newPermissions.access_sites.find(
          (a) => a.site_id == newPermissions.main_site_id
        );

      setPermissions(newPermissions);
      setSelectedSite(
        siteDetails ? siteDetails : newPermissions.access_sites[0]
      );
    }
  };

  const removePermissions = () => {
    sessionStorage.removeItem(userPermissionsKey);
    setPermissions(undefined);
  };

  const removeSession = () => {
    deleteCookie(authSessionKey);
    removePermissions();
    setUser(undefined);
  };

  const validateToken = async () => {
    return new Promise((resolve, reject) => {
      try {
        const token = getCookie(authSessionKey);
        if (!token) {
          removeSession();
          return resolve(true);
        }
        newGet(AUTH_API_ENDPOINTS.validateToken, {
          access_token: user?.access_token,
        })
          .then((res) => {
            if (res?.status_code != 200) {
              removeSession();
            }
            resolve(true);
          })
          .catch((error) => {
            console.log("Error Validating Token", error);
            removeSession();
            reject(error);
          });
      } catch (error) {
        console.log("Error Validating Token", error);
        removeSession();
        reject(error);
      }
    });
  };

  const getUserPermissions = async () => {
    setInitialLoading(true);
    await post(AUTH_API_ENDPOINTS.getPermissions, {}).then((res) => {
      if (res?.status_code === 200) {
        savePermissions(res);
        i18n.changeLanguage(res?.language);
        localStorage.setItem("selectedLanguage", res?.language || "en");
      }
      const params = getAllSearch();
      if (params["state"]) {
        const stateParts = params["state"].split(",");
        const stateObj = {};
        stateParts.forEach((part) => {
          const [key, value] = part.split(":");
          stateObj[key] = value;
        });
        navigate(`image-details?id=${stateObj.id}`);
      } else if (params["code"]) {
        const newUrl = window.location.href.split("?")[0];
        window.history.replaceState({}, document.title, newUrl);
      }
      setAuthInitialLoading(false);
      setInitialLoading(false);
    });
  };

  const verifyCodeAndGetTokens = async () => {
    setAuthInitialLoading(true);
    const params = getAllSearch();
    const clientID = LOGIN_CLIENT_ID;
    const clientSecret = "";
    const redirectUri = window.location.origin + "/dashboard";
    const data = `grant_type=authorization_code&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&code=${params["code"]
      }&client_id=${clientID}&client_secret=${clientSecret}`;

    axios
      .post(`https://${AUTHDOMAIN}/${AUTH_API_ENDPOINTS.auth2Token}`, data)
      .then(async (res) => {
        if (res.data[AUTH_CONSTANTS.tokenKey]) {
          setTimeout(() => {
            saveSession(res.data);
          }, 300);
        }

        // if (!permissions) {
        //   setTimeout(() => {
        //     getUserPermissions()
        //   }, 100)
        // }
      })
      .catch((error) => {
        setAuthInitialLoading(false);
        console.error("error from authPost", error);
      });
  };

  const getUserSettings = async () => {
    setIsUserSettingsLoading(true);
    try {
      const data = await newGet(`project/userSettings`);
      setUserSettings(data?.result[0]);
      // localStorage.setItem('user_Settings', JSON.stringify(data?.result[0]))
      setIsUserSettingsLoading(false);

    } catch (error) {
      console.log("error: in setting", error);
      setIsUserSettingsLoading(false);
    }
  }

  const hasPermission = (permissionKey) => {
    return userSettings?.permissions?.hasOwnProperty(permissionKey);
  }


  useEffect(() => {
    if (location.pathname == "/search" || "/dashboard") {
      if (user && !permissions) getUserPermissions();
      if (!user) verifyCodeAndGetTokens();
    } else {
      setAuthInitialLoading(false);
    }
  }, [user, location.pathname]);

  useEffect(() => {
    // getUserSettings()
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        initialLoading,
        permissions,
        isAuthenticated: hasCookie(authSessionKey),
        saveSession,
        removeSession,
        validateToken,
        selectedSite,
        saveSelectedSite,
        verifyCodeAndGetTokens,
        authInitialLoading,
        areAPIsLoading,
        setAreAPIsLoading,
        getUserSettings,
        isUserSettingsLoading,
        userSettings,
        hasPermission,
        isDebug,
        setIsDebug
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
