import React, { useState } from 'react';
// import person from '../../assets/newicons/person_add.svg'
import Search1 from '../../assets/newicons/search1.svg'
import noImage from '../../assets/newimages/no_image.jpg'

const RefpopUp = () => {
    const [recentImages, setRecentImages] = useState([
        { id: 1, imageUrl: noImage },
        { id: 2, imageUrl: noImage },
        { id: 3, imageUrl: noImage },
        { id: 4, imageUrl: noImage },
        { id: 5, imageUrl: noImage },
        { id: 6, imageUrl: noImage },
        { id: 7, imageUrl: noImage },
        { id: 8, imageUrl: noImage },
        { id: 8, imageUrl: noImage },
        { id: 10, imageUrl: noImage },
        { id: 11, imageUrl: noImage },
        { id: 12, imageUrl: noImage },
    ])

    return (
        <div className='container'>
            <div className='d-flex justify-content-center flex-column align-items-start py-5'>
                <h4 className=''>Settings(CRUD user)</h4>
            </div>

            <div className='d-flex gap-4'>
                <div>
                    <p className='fw-bold mb-2'>Search</p>
                    <hr className='fw-bold m-0 text-black' style={{ height: '3px', opacity: "1" }} />
                </div>
                <div>
                    <p className=''>Upload</p>
                </div>
            </div>
            <div className='position-relative w-90 mt-4 mb-5'>
                <div className='rounded-pill'>
                    <input className="form-control me-2 rounded-pill border-0 ps-4" type="search" placeholder="Search past vision" aria-label="Search" style={{ height: '56px' }} />
                </div>
                <div className='position-absolute' style={{ top: '8px', right: '10px' }}>
                    <img src={Search1} className='' alt='search'></img>
                </div>
            </div>
            <div>
                <p className='fw-bold mb-2'>Recent Searches</p>
                <div className='bg-secondary rounded-pill px-2' style={{ width: "fit-content" }}>Search-tag-1</div>
            </div>
            <div>
                <p className='fw-bold mb-2'>Recent View</p>
            </div>

            <div className='d-flex flex-wrap justify-content-start gap-2'>
                {recentImages && recentImages.length > 0 ? (
                    recentImages.map((image, i) => (
                        <div key={i}>
                            <img src={image.imageUrl} className='rounded-3' alt='no-img' />
                        </div>
                    )
                    )
                ) : (
                    <p>No recent images found</p>
                )}
            </div>
        </div>
    );
}

export default RefpopUp;
