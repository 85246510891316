import React, { useRef, useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { Group, Image } from 'react-konva';

const DraggableImage = ({
  element,
  layerId,
  handleDragEnd,
  handleGroupClick,
  handleTransformEnd,
  stageDimensions,
  handleDragStart
}) => {
  const groupRef = useRef();
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (element.props.src) {
      const img = new window.Image();
      img.src = element.props.src;
      img.onload = () => {
        setImage(img);
      };
      img.onerror = (err) => {
        console.error('Failed to load image:', err);
      };
    }
  }, [element.props.src]);

  const dragBoundFunc = (pos) => {
    const group = groupRef.current;
    if (!group) return pos;

    const stage = group.getStage();

    // Define your boundary here
    const boundary = {
      x: 0, // Starting x position of the boundary
      y: 0, // Starting y position of the boundary
      width: stageDimensions.width, // Width of the boundary
      height: stageDimensions.height, // Height of the boundary
    };

    // Get the group's transformed bounding box relative to the stage
    const box = group.getClientRect({ relativeTo: stage });

    const elementWidth = box.width;
    const elementHeight = box.height;

    // Calculate the offset between the group's position and its bounding box position
    const offsetX = box.x - group.x();
    const offsetY = box.y - group.y();

    // Calculate the new bounding box position based on the proposed pos
    const newBoxX = pos.x + offsetX;
    const newBoxY = pos.y + offsetY;

    // Calculate the maximum allowed positions
    const maxX = boundary.x + boundary.width - elementWidth;
    const maxY = boundary.y + boundary.height - elementHeight;

    // Constrain the new bounding box position within the boundary
    const constrainedBoxX = Math.max(boundary.x, Math.min(newBoxX, maxX));
    const constrainedBoxY = Math.max(boundary.y, Math.min(newBoxY, maxY));

    // Adjust back to the group's position
    const finalX = constrainedBoxX - offsetX;
    const finalY = constrainedBoxY - offsetY;

    return {
      x: finalX,
      y: finalY,
    };
  };

  return (

    <Group
      id={element.id}
      ref={groupRef}
      // draggable
      x={element.props.x }
      y={element.props.y}
      onDragStart={(e) => handleDragStart(e, layerId, element.id)}
      onDragEnd={(e) => handleDragEnd(e, layerId, element.id)}
      // onClick={() => handleGroupClick(layerId, element.id)}
      onTouchStart={() => handleGroupClick(layerId, element.id)}
      onTouchEnd={(e) => handleDragStart(e, layerId, element.id)}
      onTap={() => handleGroupClick(layerId, element.id)}
      dragBoundFunc={dragBoundFunc}
    >
      <Image
        cornerRadius={element.props.cornerRadius}
        image={image} // Use the loaded image
        width={element.props.width} // Ensure width is set
        height={element.props.height} // Ensure height is set
        onTransformEnd={(e) => handleTransformEnd(e, layerId, element.id)}
      />
    </Group>

  );
};

export default DraggableImage;