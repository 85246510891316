import React, { useState } from "react";
import CommentAndTask from "../component/CommentAndTask";
import closeIcon from "../../../assets/newicons/close.svg";
import frameImage from "../../../assets/newicons/Frame_image.png";
import Add from "../../../assets/newicons/add.svg";
import "../Annotation.scss";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ReferenceComp from "./ReferenceComp";
import { useTranslation } from "react-i18next";

const ReferenceGrid = ({ selectedImages }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { variant } = location.state || false;
  const [selectedVersion, setSelectedVersion] = useState(123);
  const [showCommentSection, setShowCommentSection] = useState(true);

  const [first, setFirst] = useState(0);

  const handleVersionChange = (event) => {
    setSelectedVersion(event.target.value);
  };
  const backToReference = () => {
    navigate("/reference");
  };

  return (
    <div>
      <div className="">
        <div className="p-3 border-bottom header-border-radius d-flex align-items-center justify-content-between px-4 flex-wrap">
          <div className="d-flex gap-4 align-items-center fw-bold font-noto-sans">
            <span className="font-noto-sans fs-14 text-black fw-500 py-3">
              {t("reference")}
            </span>

          </div>
          <p className="font-noto-sans fw-bold fs-14 text-nowrap mb-0 py-3">
            {t("total")}: {selectedImages?.length || 1}
          </p>

          <div className="d-flex align-items-center gap-3 py-3">
            <img
              src={closeIcon}
              alt="no_image"
              width={24}
              onClick={backToReference}
            />
          </div>
        </div>
        <div className="d-flex justify-content-between flex-column flex-md-row">
          <div className=" heigth-calc-56 overflow-auto w-100">
            <div className="d-flex flex-wrap gap-3 reference-grid">
              <div className="reference-grid-image">
                {selectedImages && selectedImages?.map((image, i) => (
                  <img
                    key={i}
                    src={frameImage}
                    alt="frameImage"
                    className="img-fluid p-1 h-100 w-100"
                  />
                ))}
              </div>
              <div
                className="reference-grid-image-add  w-100 border-dashed d-flex justify-content-center align-items-center"
                data-bs-toggle="modal"
                data-bs-target="#addreference"
                type="button"
              >
                <div className="text-center" >
                  <img src={Add} className="Add" alt="logo" />
                  <div className="add_reference_text mt-1">
                    {t("addNewReference")}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ReferenceComp />
          <button className="sidebar-open-btn" onClick={() => setShowCommentSection(true)}>
            <span></span>
          </button>
          <div className={`position-relative max-w-420px w-100 ${showCommentSection ? "d-block" : "d-none"}`}>
            <div className=" w-100 bg-color-gray border-start heigth-calc-56 overflow-auto">
              <button className="sidebar-close-btn" onClick={() => setShowCommentSection(false)}>
                <span></span>
              </button>
              <div className={`${showCommentSection ? "d-block" : "d-none"}`}>
                <CommentAndTask />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferenceGrid;
