import React, { useRef, useState, useEffect, useContext, useCallback, useTransition } from 'react';
import { Excalidraw, Footer, MIME_TYPES } from '@excalidraw/excalidraw';
import '@excalidraw/excalidraw/index.css';
import { imageGet, newDelete, newPost } from "../../../API/axios";
import initiData from "../initiData";
import './AnnotatingImage.scss';
import gridView from '../../../assets/newicons/grid_view.svg';
import addIcon from '../../../assets/newicons/add.svg';
// import './index.css'
import viewArray from '../../../assets/newicons/view_array.svg';
import minimize from '../../../assets/newicons/minimize.svg';
import maximize from '../../../assets/newicons/maximize.svg';

import ImageContext from './ImageContext';
import close from "../../../assets/newicons/close.svg";
import { showConfirmationDialog } from './AlertService';
import { useReferenceContext } from '../../../context/useReferenceContext';
import { Sidebar, useDevice } from '@excalidraw/excalidraw';
import CommentAndTask from './CommentAndTask';
import CreateProjectFormLoader from '../skeletonLoaders/CreateProjectFormLoader';
import { useTranslation } from 'react-i18next';

const AnnotatingImage = ({ excalidrawData, src, setSelectedImage, isGridView, setIsGridView, referenceList, setReferenceList, referenceComments, setReferenceComments, isInternal, miroLink, selectedImages, createdProjectId, excaliDrawChanges }) => {


    const [excalidrawAPI, setExcalidrawAPI] = useState(null);
    const [loading, setLoading] = useState(false);
    const [imageHeight, setImageHeight] = useState();
    const [image, setImage] = useState();
    const [loadedImages, setLoadedImages] = useState([]);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    const [localReferenceList, setLocalReferenceList] = useState();
    const { resetPopup, setResetPopup, images, } = useContext(ImageContext);
    const { referencesList, setReferencesList, excalidrawLoading, setExcalidrawLoading, board_id } = useReferenceContext();
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [elements, setElements] = useState([]);
    const [prevElements, setPrevElements] = useState([]);
    const [state, setState] = useState(null);
    const [prevState, setPrevState] = useState(null);
    const [isChanged, setIsChanged] = useState(false);
    const { t, i18n } = useTranslation();




    const [docked, setDocked] = useState(false);
    const device = useDevice();

    const modalOpen = () => {
        setResetPopup('');
        setResetPopup('reset');
    }

    const loaderStyle = {
        border: '8px solid #f3f3f3',
        borderTop: '8px solid #3498db',
        borderRadius: '50%',
        width: '60px',
        height: '60px',
        animation: 'spin 1s linear infinite',
        margin: 'auto',
    };

    useEffect(() => {
        if (!excalidrawData) {
            initialStatePromiseRef.current.promise.resolve(initiData);
            // setExcalidrawLoading(false);
            return;
        }

        const updateData = async () => {
            try {
                const files = excalidrawData.files || {};
                await Promise.all(
                    Object.entries(files).map(async ([key, file]) => {
                        if (!file.dataURL.startsWith('data:')) {
                            const response = await imageGet('download_image', { image_url: file.dataURL });
                            const base64String = await blobToBase64(response);
                            excalidrawAPI.addFiles([{ ...file, dataURL: base64String }]);
                            return [key, { ...file, dataURL: base64String }];

                        }
                        return [key, file];
                    })
                );


                const updatedData = {
                    ...excalidrawData,
                    appState: {
                        ...(excalidrawData.appState || {})
                    },
                };

                initialStatePromiseRef.current.promise.resolve(updatedData);
                excalidrawAPI.updateScene(updatedData);
                setExcalidrawLoading(false);
                setTimeout(() => {
                    excalidrawAPI.scrollToContent();

                }, 100);

            } catch (error) {
                setExcalidrawLoading(false);
                console.error('Error updating Excalidraw data:', error);
            }
        };
        if (excalidrawData && excalidrawAPI) {
            updateData();
        }
    }, [excalidrawData, excalidrawAPI]);

    // console.log('excalidrawData:', excalidrawData);
    // console.log('referenList:', referenceList);

    useEffect(() => {
        setExcalidrawLoading(true);
    }, [])

    useEffect(() => {
        document.excalidrawAPI = excalidrawAPI;

        // if (src && src.length > 0) {
        //     setLoading(true);
        //     Promise.all(src.map(src => convertBase64(src))).then(() => {
        //         setLoading(false);
        //     });
        // }
    }, [excalidrawAPI, src]);

    useEffect(() => {
        const combinedImages = [...(selectedImages || []), ...(images || [])];
        if (combinedImages.length > 0) {
            const data = addSelectedImagesToReferenceList(referenceList, combinedImages);
            const uniqueData = data.filter((item, index, self) =>
                index === self.findIndex((t) => t.reference_image_url === item.reference_image_url)
            );
            setReferenceList(uniqueData);
        }
    }, [selectedImages, images]);

    useEffect(() => {
        const convertImages = async () => {
            for (const image of images) {
                if (image.objectURL) {
                    const fileId = Date.now(); // Unique ID for each image

                    try {
                        let response;
                        if (!image.objectURL.includes('blob:')) {
                            try {
                                // Fetch the image from the server
                                response = await imageGet("download_image", { image_url: image.objectURL });
                                console.log('API Call Success:', response);
                            } catch (error) {
                                console.error("Error fetching remote image:", error);
                                continue; // Skip to the next image if there's an error
                            }
                        } else {
                            // Handle localhost or blob-based image
                            response = new Blob([image], { type: image.type });
                        }

                        // Convert Blob to Base64
                        const base64Image = await blobToBase64(response);

                        // Process and load the image into Excalidraw if it was successfully converted
                        if (base64Image) {
                            setLoadedImages(prev => [...prev, base64Image]);

                            if (excalidrawAPI) {
                                const currentElements = JSON.parse(JSON.stringify(excalidrawAPI.getSceneElements()));
                                const existingImages = currentElements.filter(el => el.type === 'image');
                                console.log('currentElements', currentElements)

                                // Check if the image already exists
                                const imageExists = existingImages.some(img => img.dataURL === base64Image);
                                if (!imageExists) {
                                    const lastImage = existingImages.pop();
                                    let newX = 200, newY = 100;

                                    if (lastImage) {
                                        newX = lastImage.x + lastImage.width + 10;
                                        newY = lastImage.y;
                                    }

                                    const img = new Image();
                                    img.src = base64Image;
                                    img.onload = () => {
                                        const { width, height } = aspectRatio(img.width, img.height);

                                        // Create a new image element in Excalidraw
                                        const newImageElement = {
                                            fileId,
                                            id: fileId,
                                            image_id: fileId,
                                            type: "image",
                                            x: newX,
                                            y: newY,
                                            width: width,
                                            height: height,
                                            angle: 0,
                                            strokeColor: "transparent",
                                            backgroundColor: "transparent",
                                            fillStyle: "hachure",
                                            strokeWidth: 1,
                                            strokeStyle: "solid",
                                            roughness: 1,
                                            opacity: 100,
                                            groupIds: [],
                                            strokeSharpness: "round",
                                            version: 143,
                                            versionNonce: 2028982666,
                                            isDeleted: false,
                                            boundElements: null,
                                            link: null,
                                            status: "pending",
                                            scale: [1, 1],
                                        };

                                        // Add the image element to Excalidraw
                                        const updatedElements = [...currentElements, newImageElement];
                                        excalidrawAPI.addFiles([{
                                            id: fileId,
                                            dataURL: base64Image,
                                            mimeType: MIME_TYPES.jpg,
                                            created: Date.now(),
                                            lastRetrieved: Date.now()
                                        }]);

                                        excalidrawAPI.updateScene({ elements: updatedElements });
                                        // setTimeout(() => {
                                        //     excalidrawAPI.scrollToContent();

                                        // }, 100);
                                        excalidrawAPI.scrollToContent();

                                        // Update reference list with the new image
                                        setNewRederenceList(base64Image, image, fileId);
                                    };
                                }
                            }
                        }
                    } catch (error) {
                        console.error('Error fetching or converting blob:', error);
                    }
                }
            }
        };

        convertImages();
    }, [images]);

    const setNewRederenceList = (base64Image, file, fileId) => {
        let urlParams = new URLSearchParams(window.location.search)
        const projectIdFromUrl = parseInt(urlParams.get('project_id'));
        let PrevRef = JSON.parse(JSON.stringify(referenceList || []));
        const newImageObj = {
            is_active: 0,
            project_id: projectIdFromUrl,
            // reference_id: null,
            board_id : board_id,
            fileId: fileId,
            reference_image_url: base64Image,
            file: file
        };
        let newReferenceList = [newImageObj, ...PrevRef];
        setReferenceList((prevRefList) => [newImageObj, ...prevRefList]);
    }

    const addSelectedImagesToReferenceList = (referenceList = [], selectedImages = []) => {
        let updatedReferenceList = [...referenceList];

        selectedImages.forEach(async (image) => {
            if (image?.objectURL) {

                const response = await fetch(image.objectURL);
                const blob = await response.blob();

                const reader = new FileReader();

                return new Promise((resolve, reject) => {
                    reader.onloadend = () => {
                        const base64String = reader.result.split(',')[1];
                        const base64Image = `data:image/png;base64,${base64String}`;
                        const newImageObj = {
                            comments: [],
                            is_active: null,
                            project_id: null,
                            // reference_id: null,
                            board_id : board_id,
                            reference_image_url: base64Image,
                        };
                        updatedReferenceList = [newImageObj, ...updatedReferenceList];

                        resolve(base64Image);

                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                });

            } else {
                const newImageObj = {
                    comments: [],
                    is_active: null,
                    project_id: null,
                    // reference_id: null,
                    board_id : board_id,
                    image_id: image?.image_id,
                    reference_image_url: image.image_url,
                    flag: true
                };
                updatedReferenceList = [newImageObj, ...updatedReferenceList];
                // setReferenceList(updatedReferenceList)
            }
        });


        const imagesWithFlagTrue = updatedReferenceList.filter(item => item.flag);

        if (imagesWithFlagTrue.length > 0) {
            Promise.all(imagesWithFlagTrue.map(item => convertBase64(item.reference_image_url, item)))
                .then(() => {
                    setLoading(false);
                })
                .catch(error => {
                    console.error('Error converting images:', error);
                    setLoading(false);
                });
        }

        return updatedReferenceList;
    };



    const resolvablePromise = () => {
        let resolve;
        let reject;
        const promise = new Promise((_resolve, _reject) => {
            resolve = _resolve;
            reject = _reject;
        });
        promise.resolve = resolve;
        promise.reject = reject;
        return promise;
    };

    const initialStatePromiseRef = useRef({ promise: resolvablePromise() });
    if (!initialStatePromiseRef?.current?.promise) {
        initialStatePromiseRef.current.promise = resolvablePromise();
    }

    const blobToBase64 = (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result);
                const base64Image = reader.result;
                const img = new Image();
                img.src = base64Image;
                img.onload = () => {
                    setImageHeight(img.height)
                };
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    };

    const handleImageSelect = async (imageSrc, index, refData) => {
        setSelectedImage(imageSrc);
        setImage(imageSrc);
        setSelectedImageIndex(index);
        setReferenceComments(refData);
        setIsModalOpen(true);
    };


    const handleModifyButtonClick = (src) => {

        const refData = referenceComments
        const base64Data = src.startsWith("data:image/")
        if (!base64Data) {
            convertBase64(src, refData);
        } else {
            setLoadedImages(prev => [...prev, src]);

            if (excalidrawAPI) {
                const currentElements = JSON.parse(JSON.stringify(excalidrawAPI.getSceneElements()));
                const images = currentElements.filter(el => el.type === 'image');

                // Check if the image already exists
                const imageExists = images.some(img => img.dataURL === src);

                if (!imageExists) {
                    const lastImage = images.pop();
                    let newX, newY;

                    if (lastImage) {
                        newX = (lastImage.x + 10) + lastImage.width;
                        newY = lastImage.y;
                    } else {
                        newX = 200;
                        newY = 100;
                    }

                    const newImage = {
                        fileId: Date.now(),
                        id: Date.now(),
                        type: "image",

                        x: newX,
                        y: newY,
                        width: 231.30325348751828,
                        height: 231.64340533088227,
                        angle: 0,
                        strokeColor: "transparent",
                        backgroundColor: "transparent",
                        fillStyle: "hachure",
                        strokeWidth: 1,
                        strokeStyle: "solid",
                        roughness: 1,
                        opacity: 100,
                        groupIds: [],
                        strokeSharpness: "round",
                        seed: 707269846,
                        version: 143,
                        versionNonce: 2028982666,
                        isDeleted: false,
                        boundElements: null,
                        updated: 1644914782403,
                        link: null,
                        status: "pending",
                        scale: [1, 1],
                        dataURL: src,
                    };

                    const updatedElements = [...currentElements, newImage];
                    excalidrawAPI.addFiles([{
                        id: Date.now(),
                        dataURL: src,
                        mimeType: MIME_TYPES.jpg,
                        created: Date.now(),
                        lastRetrieved: Date.now()
                    }]);

                    excalidrawAPI?.updateScene({ elements: updatedElements });
                    excalidrawAPI.scrollToContent();

                    // setNewRederenceList(src);
                }
            }
        }
        setIsModalOpen(false);
    }

    const aspectRatio = (width, height) => {


        const maxWidth = 300; // Maximum width for the image
        const maxHeight = 300; // Maximum height for the image

        // Calculate the aspect ratio
        const aspectRatio = width / height;

        // Adjust width and height to fit within the maximum dimensions
        if (width > maxWidth || height > maxHeight) {
            if (aspectRatio > 1) {
                width = maxWidth;
                height = maxWidth / aspectRatio;
            } else {
                height = maxHeight;
                width = maxHeight * aspectRatio;
            }
        }

        return { width, height };

    }

    const convertToBase64 = async (src, image) => {
        try {
            const response = await imageGet("download_image", { image_url: src });
            if (!response) return;
            const base64String = await blobToBase64(response);
            return base64String
        } catch (error) {
            console.error('Error converting image:', error);
        }
    };


    const convertBase64 = async (src, image) => {
        try {
            const response = await imageGet("download_image", { image_url: src });
            if (!response) return;

            const base64String = await blobToBase64(response);
            const img = new Image();
            img.src = base64String;

            img.onload = () => {
                let { width, height } = aspectRatio(img.width, img.height);


                if (base64String) {
                    setLoadedImages(prev => [...prev, base64String]);

                    if (excalidrawAPI) {
                        const currentElements = excalidrawAPI.getSceneElements();
                        const images = currentElements.filter(el => el.type === 'image');

                        // Check if the image already exists
                        const imageExists = images.some(img => img.dataURL === base64String);

                        if (!imageExists) {
                            const lastImage = images[images.length - 1];
                            const newX = lastImage ? lastImage.x + lastImage.width + 10 : 200;
                            const newY = lastImage ? lastImage.y : 100;

                            const newImage = {
                                fileId: image?.image_id || image?.reference_id || image?.board_id,
                                id: Date.now(),
                                type: "image",
                                x: newX,
                                y: newY,
                                ref_type: image?.image_id ? 'image' : 'reference',
                                image_id: image?.image_id || image?.reference_id ||  image?.board_id,
                                dataURL: image?.reference_image_url || base64String,
                                width: width,
                                height: height,
                                angle: 0,
                                strokeColor: "transparent",
                                backgroundColor: "transparent",
                                fillStyle: "hachure",
                                strokeWidth: 1,
                                strokeStyle: "solid",
                                roughness: 1,
                                opacity: 100,
                                groupIds: [],
                                strokeSharpness: "round",
                                seed: 707269846,
                                version: 143,
                                versionNonce: 2028982666,
                                isDeleted: false,
                                boundElements: null,
                                updated: 1644914782403,
                                link: null,
                                status: "pending",
                                scale: [1, 1],
                            };

                            excalidrawAPI.addFiles([{
                                id: image?.image_id || image?.reference_id ||  image?.board_id,
                                dataURL: src,
                                mimeType: MIME_TYPES.jpg,
                                created: Date.now(),
                                lastRetrieved: Date.now()
                            }]);

                            excalidrawAPI.updateScene({ elements: [...currentElements, newImage] });
                            excalidrawAPI.scrollToContent();
                        }
                    }
                }
            };
        } catch (error) {
            console.error('Error converting image:', error);
        }
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    const removeRef = async (data) => {
        const confirmation = await showConfirmationDialog(
            `${t("projectDeleteMsg")}`,
            "",
            "warning",
            `${t("no")}`,
            `${t("yes")}`
        );
        try {
            if (confirmation) {
                setLoading(true);
                if (data.reference_id) {
                    const deleteRef = await newDelete(`reference/delete?reference_id=${data.reference_id}&project_id=${createdProjectId}`, data).then((res) => {
                        const updatedReferenceList = referenceList.filter(item => item.reference_image_url !== data.reference_image_url);
                        setReferenceList(updatedReferenceList);
                    });
                } else {
                    const updatedReferenceList = referenceList.filter(item => item.reference_image_url !== data.reference_image_url);
                    setReferenceList(updatedReferenceList);
                }
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }


    }

    // Helper function for deep comparison
    const deepEqual = (obj1, obj2) => {
        if (typeof obj1 === "object" && typeof obj2 === "object" && obj1 !== null && obj2 !== null) {
            const keys1 = Object.keys(obj1);
            const keys2 = Object.keys(obj2);

            if (keys1.length !== keys2.length) return false;

            for (let key of keys1) {
                if (!deepEqual(obj1[key], obj2[key])) return false;
            }
            return true;
        } else {
            return obj1 === obj2;
        }
    };
    
    // Compare coordinates and detect changes
    const detectCoordinateChanges = (newElements) => {
       
        let hasChanges = false; 
        newElements.forEach((newElement) => {
            const oldElement = elements.find((el) => el.id === newElement.id);
            console.log("OldElements", oldElement)
            if (!oldElement) {
                console.log(`New element added: ${newElement.id}`);
                return; // Skip further checks for new elements
              }

            // if (oldElement) {
            //     // Compare old and new coordinates
            //     if (oldElement.x !== newElement.x || oldElement.y !== newElement.y) {
            //       console.log(`Element ${newElement.id} moved:`);
            //       console.log(`Old coordinates: x=${oldElement.x}, y=${oldElement.y}`);
            //       console.log(`New coordinates: x=${newElement.x}, y=${newElement.y}`);
            //       hasChanges = true;
            //     }
            //   }
        });

        if (hasChanges || newElements.length !== elements.length) {
            setElements([...newElements]); // Update only if there are changes
          }
    };

    const handleChangeExaclidraw = useCallback((newElements, newState) => {
        // console.log('newElements:', newElements.length);
        // console.log('excalidrawData:', excalidrawData?.elements?.length);
        if (newElements?.length > excalidrawData?.elements?.length) {
            excaliDrawChanges(true);
        }
        else {
            excaliDrawChanges(false);
        }

        // console.log('state:', newState);


        // }/
        // setElements(elements); // Save elements to state
        // setState(state);       // Optionally save state if needed
        // excaliDrawChanges(isChanged)
        // setIsChanged(true);
        const elementsChanged = !deepEqual(prevElements, newElements);
        // const stateChanged = !deepEqual(prevState, newState);  
        if (elementsChanged) {
            setIsChanged(true);   // Mark as changed if either elements or state changed
            // console.log("Excalidraw content changed.");  
            // excaliDrawChanges(newElements)  
            // Update previous elements and state
            setPrevElements(newElements);
            // setPrevState(newState);
        } else {
            setIsChanged(false);  // No changes detected
            // excaliDrawChanges(isChanged)
        }

        setElements(newElements);
        //   setState(newState); 
    }, [prevElements]);



    const toggleFullscreen = () => {
        const excalidrawContainer = document.getElementById('excalidraw-container');
        if (!isFullscreen) {
            if (excalidrawContainer.requestFullscreen) {
                excalidrawContainer.requestFullscreen();
            } else if (excalidrawContainer.webkitRequestFullscreen) {
                excalidrawContainer.webkitRequestFullscreen(); // Safari
            } else if (excalidrawContainer.msRequestFullscreen) {
                excalidrawContainer.msRequestFullscreen(); // IE11
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen(); // Safari
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen(); // IE11
            }
        }
        setIsFullscreen(!isFullscreen);
    };

    return (
        <div className='d-flex anotation'>
            {isModalOpen && (
                <div className="modal-overlay" onClick={handleModalClose}>
                    <div
                        className="modal-content1"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <span className="close-button" onClick={handleModalClose}>
                            &times;
                        </span>
                        <img
                            src={image}
                            alt="Selected"
                            className="modal-image"
                        />
                        <button
                            className="modify-button"
                            onClick={() => handleModifyButtonClick(image)}
                        >
                            Insert
                        </button>
                    </div>
                </div>
            )}

            {/* Excalidraw and Loader */}

            <div
                id='excalidraw-container'
                style={{
                    height: 'calc(100vh - 100px)',
                    width: isMenuOpen ? 'calc(100% - 250px)' : '100%',
                    marginLeft: isMenuOpen ? '250px' : '0',
                    transition: 'width 0.3s, margin-left 0.3s',
                    display: !isInternal || excalidrawLoading ? 'none' : 'block',
                }}
            >
                <Excalidraw
                    onChange={handleChangeExaclidraw}
                    // onChange={(newElements, appState) => {
                    //     detectCoordinateChanges(newElements);
                    //     // setElements([...newElements]);
                    // }}
                    excalidrawAPI={(api) => setExcalidrawAPI(api)}
                    initialData={initialStatePromiseRef.current.promise}
                    UIOptions={{
                        canvasActions: {
                            loadScene: false,
                            saveAsImage: false,
                            saveScene: false,
                            saveAsScene: false,
                            export: false,
                            clearCanvas: false,
                            saveToActiveFile: false,
                        },
                    }}
                    renderTopRightUI={() => {

                        return (
                            <>
                                {device?.editor?.isMobile ? (
                                    <button
                                        style={{
                                            border: "none",
                                            width: "max-content",
                                            fontWeight: "bold",
                                        }}
                                        className='exciladraw-fullscreen-btn'
                                    >
                                        <Sidebar.Trigger
                                            name="custom"
                                            tab="one"
                                        >
                                            <img src={viewArray} alt='viewArray' width={20} height={16} className='align-baseline' style={{ marginTop: '3px' }} />
                                        </Sidebar.Trigger>
                                    </button>

                                ) :

                                    <button
                                        style={{
                                            border: "none",
                                            width: "max-content",
                                            fontWeight: "bold",
                                        }}
                                        className='exciladraw-fullscreen-btn'
                                        onClick={toggleFullscreen}
                                    >
                                        {isFullscreen ? <img src={minimize}></img> : <img src={maximize}></img>}

                                    </button>
                                }
                            </>
                        );
                    }}
                >
                    <Sidebar name="custom" docked={docked} onDock={setDocked}>
                        <Sidebar.Header />
                        {/* <Sidebar.Tabs style={{ padding: "0.5rem" }}>
                            <Sidebar.Tab tab="one">Tab one!</Sidebar.Tab>
                            <Sidebar.Tab tab="two">Tab two!</Sidebar.Tab>
                            <Sidebar.TabTriggers>
                                <Sidebar.TabTrigger tab="one">One</Sidebar.TabTrigger>
                                <Sidebar.TabTrigger tab="two">Two</Sidebar.TabTrigger>
                            </Sidebar.TabTriggers>
                        </Sidebar.Tabs> */}
                        {/* <CommentAndTask
                            key={'comments'}
                            createdProjectId={createdProjectId}
                            ProjectId={createdProjectId}
                        /> */}
                    </Sidebar>
                    <div >
                        <Footer>
                            {/* <div className='d-flex flex-wrap'> */}
                            {/* <div className='d-flex align-items-center gap-3 annotations-row'>
                                <div className='d-flex align-items-center justify-content-center'>
                                    <button
                                        className={`${isGridView ? "bg-btn-zoom" : ""} border-0 bg-white px-2 rounded-3 me-1 pb-2`}
                                        style={{ height: "24px" }}
                                        onClick={() => setIsGridView(true)}
                                    >
                                        <img src={gridView} alt='gridView' className='align-baseline' width={10} height={10} />
                                    </button>
                                    <button
                                        className={`${isGridView ? "" : "bg-btn-zoom"} border-0 bg-white px-1 rounded-3  pb-3`}
                                        style={{ height: "24px" }}
                                        onClick={() => setIsGridView(false)}
                                    >
                                        <img src={viewArray} alt='viewArray' width={16} height={16} className='align-baseline' style={{ marginTop: '3px' }} />
                                    </button>
                                    
                                </div>
                            </div> */}
                            <div className="reference-list d-flex align-items-center">
                                {/* <div className="reference-item mx-2">
                                    <img
                                        src={addIcon}
                                        alt='addIcon'
                                        data-bs-toggle="modal"
                                        data-bs-target="#addreference"
                                        type="button"
                                        width={50}
                                        onClick={modalOpen}
                                        title='Add Reference'
                                        style={{
                                            border: '2px dotted #7b7b7b',
                                            cursor: 'pointer'
                                        }}
                                    />
                                </div> */}
                                {/* {referenceList && referenceList.length > 0 && referenceList.map((ref, index) => (
                                    <div key={index} className="reference-item mx-2">
                                        <img
                                            src={ref.reference_image_url}
                                            alt={`reference-${index}`}
                                            width={50}
                                            style={{
                                                border: selectedImageIndex === index ? '2px solid black' : '',
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => handleImageSelect(ref.reference_image_url, index, ref)}
                                        />
                                        <div className='reference-close' onClick={() => removeRef(ref)}>
                                            <img src={close} alt="close" />
                                        </div>
                                    </div>
                                ))} */}
                            </div>
                        </Footer>
                    </div>
                </Excalidraw>

                {/* {loading && <div style={loaderStyle} />} */}
            </div>
            <div style={{ display: excalidrawLoading ? 'block' : 'none' }}>
                <CreateProjectFormLoader height='calc(100vh - 100px)' width='60vw'></CreateProjectFormLoader>
            </div>
            <div style={{
                height: 'calc(100vh - 100px)',
                width: isMenuOpen ? 'calc(100% - 250px)' : '100%',
                marginLeft: isMenuOpen ? '250px' : '0',
                transition: 'width 0.3s, margin-left 0.3s',
                display: isInternal ? 'none' : 'block',
            }}>
                <iframe className="responsive-iframe" src={miroLink} title="Miro" width="100%" height="100%" frameBorder="0" scrolling="no" allowFullScreen />
            </div>
        </div>
    );
};

export default AnnotatingImage;
